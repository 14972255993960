import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { openRequestAccount } from "../actions.js";
import { connect } from "react-redux";
import MainHeroLayout from "./MainHeroLayout.js";
import mainBackground from "../images/mainBackground.mp4";
import RequestAccessEntry from "./RequestAccessEntry.js";
import Media from "react-media";

const styles = (theme) => ({
  background: {
    position: "fixed",
    backgroundColor: "#0E0F1F",
    backgroundPosition: "center",
  },
  layoutBody: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(100vh - 160px)",
    minHeight: 400,
    maxHeight: 900,
  },
  layoutBodyMobile: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(100vh - 160px)",
    minHeight: 400,
    maxHeight: 900,
  },
  title: {
    marginBottom: theme.spacing.unit * 2,
    [theme.breakpoints.down("sm")]: {
      fontSize: 48,
      fontWeight: 700,
      marginBottom: 0,
    },
  },
  subtitle1: {
    marginBottom: theme.spacing.unit * 2,
    [theme.breakpoints.down("sm")]: {
      fontSize: 17,
    },
  },
  button: {},
  appBar: {},
  img: {
    maxHeight: "80%",
    maxWidth: "80%",
  },
  imgMobile: {
    maxHeight: "100%",
    maxWidth: "100%",
  },
});

class MainHero extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
    };
  }

  updateEmailValue = (evt) => {
    this.setState({
      email: evt.target.value,
    });
  };

  requestAccountClick = () => {
    this.props.openRequestAccount(this.state.email);
  };

  render() {
    const { classes } = this.props;

    return (
      <Media query="(max-width: 599px)">
        {(matches) => (
          <MainHeroLayout backgroundClassName={classes.background}>
            {/* <img style={{ display: 'none' }} src={mainBackground} alt="" /> */}
            <div
              className={
                matches ? classes.layoutBodyMobile : classes.layoutBody
              }
            >
              <Grid container spacing={2} className={classes.grid}>
                <Grid item xs={12} md={12}>
                  <Grid
                    container
                    spacing={2}
                    alignContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={12} md={12}>
                      <Typography
                        align="center"
                        variant="h1"
                        className={classes.title}
                      >
                        Build your <br /> digital visualization. <br />
                      </Typography>
                    </Grid>
                    <Grid item xs={0} md={3}></Grid>
                    <Grid item xs={12} md={6}>
                      <Typography
                        align="center"
                        variant="subtitle1"
                        className={classes.subtitle1}
                      >
                        We make it easy for you to build digital and interactive
                        visualization solutions. With our simple API you create
                        any digital signage scenario fast.
                      </Typography>
                    </Grid>
                    <Grid item xs={0} md={3}></Grid>
                    <Grid item xs={0} md={2}></Grid>
                    <Grid xs={12} md={12}>
                      <RequestAccessEntry />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <div
                style={{
                  position: "absolute",
                  left: 0,
                  zIndex: -996,
                  height: "100%",
                  width: "100%",
                  backgroundImage:
                    "linear-gradient(to right, rgba(14,15,31,1) 0%, rgba(14,15,31,.85) 10%, rgba(14,15,31,.7) 20%, rgba(14,15,31,0) 40%)",
                }}
              />
              <video
                style={{
                  position: "absolute",
                  left: 0,
                  zIndex: -997,
                  height: "100%",
                  width: "100%",
                  opacity: 0.2,
                  objectFit: "cover",
                }}
                autoPlay
                loop
                muted
              >
                <source src={mainBackground} type="video/mp4" />
              </video>
            </div>
          </MainHeroLayout>
        )}
      </Media>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  openRequestAccount: (email) => dispatch(openRequestAccount(email)),
});

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(MainHero)
);
