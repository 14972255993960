import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import EasyvisColorPicker from '../../../../common/EasyvisColorPicker';


const styles = theme => ({
  root: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    formControl: {
      margin: theme.spacing.unit,
      minWidth: 220
    },
});


class BlinkApp extends Component {

  // the state represents the config of the widget
  state = {
    bps: 1,
    blinkDuration: 0.5
  }

  componentDidMount() {
    // report initial state
    this.setStateCallback();
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value }, this.setStateCallback);
  }

  handleColorChange = (color) => {
    this.setState({ color: color }, this.setStateCallback)
  }

  setStateCallback = () => {
    this.props.onConfigChange(this.state)
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <form className={classes.root} autoComplete="off">
          <FormControl className={classes.formControl}>
            <TextField
              id="bps"
              name="bps"
              label="Blinks per second"
              value={this.state.bps}
              onChange={this.handleChange}
              fullWidth
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              id="blinkDuration"
              name="blinkDuration"
              label="Duration of a blink (s)"
              value={this.state.blinkDuration}
              onChange={this.handleChange}
              fullWidth
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <EasyvisColorPicker
              value={this.state.color}
              onChange={this.handleColorChange}
              convert='hex'
              id="color"
              name="color"
              label="Color"
              fullWidth
              defaultValue='#FFFFFF'
            />
          </FormControl>
        </form>
      </div>
    )
  }
}

export default withStyles(styles)(BlinkApp);
