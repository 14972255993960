import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  margin: {
    margin: theme.spacing.unit * 7,
  },
  marginBottom: {
    marginBottom: theme.spacing.unit * 12,
  },
  width: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up('md')]: {
      width: theme.main.widthDesktop,
      marginLeft: 'auto',
      marginRight: 'auto',
    }
  },
  fullHeight: {
    height: '100%',
  },
});

function MainLayoutBody(props) {
  const {
    children,
    classes,
    className,
    component: Component,
    fullHeight,
    fullWidth,
    margin,
    marginBottom,
    style,
    width,
    ...other
  } = props;

  return (
    <Component
      className={classNames(
        classes.root,
        classes.width,
        {
          [classes.fullHeight]: fullHeight,
          [classes.margin]: margin,
          [classes.marginBottom]: marginBottom,
        },
        className,
      )}
      style={style}
      {...other}
    >
      {children}
    </Component>
  );
}

MainLayoutBody.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  fullHeight: PropTypes.bool,
  fullWidth: PropTypes.bool,
  margin: PropTypes.bool,
  marginBottom: PropTypes.bool,
  style: PropTypes.object,
  width: PropTypes.oneOf(['small', 'medium', 'large', 'xlarge', 'full']),
};

MainLayoutBody.defaultProps = {
  component: 'div',
  fullHeight: false,
  fullWidth: false,
  margin: false,
  marginBottom: false,
  width: 'medium',
};

export default withStyles(styles)(MainLayoutBody);
