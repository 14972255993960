import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { playPlayer } from '../../actions';
import { getPlaylistsAPI } from '../../../media/actions';
import PlaylistList from './playlist/PlaylistList';

const styles = theme => ({
});

/**
 * Playlist App for playing and managing playlists
 */
class PlaylistApp extends Component {

	componentDidMount() {
   		this.props.reloadPlaylists();
	}

	play = (playlistItems) => {
		const player = this.props.selectedPlayer;
		const playerConfig = this.props.playerConfig;

		// Filter out playlist item entries which are not compatible with api
		// A deep copy of the elements is required
		var items = JSON.parse(JSON.stringify(playlistItems));
		items.forEach(function (item, index) {
			console.log(item);
			for (let i of Object.keys(item)) {
				if (i === "id" || i === "name")
				  delete item[i];
			}
		  });
		const config = {
			"items": items
		};
		this.props.playPlayer(player, "playlist", config, playerConfig);
	} 

	render () {
		const items = this.props.playlists
		return (
			<PlaylistList
				playlists={items}
				playEnabled={true}
				playCallback={this.play}
			/>
    );
	}
}

const mapStateToProps = state => ({
	selectedPlayer: state.players.selectedPlayer,
	playerConfig: state.players.playerConfig,
  	playlists: state.media.playlists
})

const mapDispatchToProps = dispatch => ({
  reloadPlaylists: () => dispatch(getPlaylistsAPI()),
  playPlayer: (player, type, config, playConfig) => dispatch(playPlayer(player, type, config, playConfig))
})

export default withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps
)(PlaylistApp))
