import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { postMediaLocalAPI } from "../actions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import { Field, reduxForm } from "redux-form";
import { required } from "redux-form-validators";
import { renderField, renderSelectField } from "../../../common/Utilities";

const styles = (theme) => ({
  formTop: {
    marginTop: theme.spacing(4),
  },
});

class MediaAdd extends Component {
  ALLOWED_VIDEO_FORMATS = [".mp4", ".mov", ".avi", ".flv"];
  ALLOWED_IMAGE_FORMATS = [".jpeg", ".jpg", ".gif", ".png"];

  constructor() {
    super();
    this.state = {
      openInfoDialog: false,
    };
  }

  verifyName = (value) => {
    return value && !/^[0-9a-zA-Z_-]{1,20}$/i.test(value)
      ? "Invalid media name"
      : undefined;
  };

  verifyUrl = (value, allValues) => {
    const url = value;
    const type = allValues.type;
    var check = false;
    if (type === "VIDEO") {
      check = this.ALLOWED_VIDEO_FORMATS.map((f) => url.includes(f)).includes(
        true
      );
    } else if (type === "IMAGE") {
      check = this.ALLOWED_IMAGE_FORMATS.map((f) => url.includes(f)).includes(
        true
      );
    } else {
      check = true;
    }
    return check ? undefined : "Invalid Url. Check supported formats.";
  };

  handleInfoDialogClose = () => {
    this.setState({ openInfoDialog: false });
  };

  submit = (values) => {
    this.props.addMedia(
      this.props.player,
      values.name,
      values.url,
      values.type
    );
    this.props.onClickLeaveUpdateMode();
    this.setState({ openInfoDialog: true });
  };

  render() {
    const { classes, handleSubmit, submitting, invalid, pristine } = this.props;
    return (
      <div>
        <form onSubmit={handleSubmit(this.submit)}>
          <Dialog
            open={this.props.open}
            onClose={this.props.onClickLeaveUpdateMode}
            aria-labelledby="form-dialog-title"
            maxWidth="sm"
          >
            <DialogTitle id="form-dialog-title">
              Add media to player {this.props.player}
            </DialogTitle>
            <DialogContent>
              <DialogContentText variant="caption">
                Provide a link to your media which you want to add to your
                player. Note that you need to provide a direct link to your
                media (supported media formats are:{" "}
                {this.ALLOWED_IMAGE_FORMATS.join(",")} and{" "}
                {this.ALLOWED_VIDEO_FORMATS.join(", ")}).
                <br /> <br />
                Example Links: <br />
                https://live.staticflickr.com/7364/27653319031_d64f8beeb8_c_d.jpg{" "}
                <br />
                https://dl.dropboxusercontent.com/s/1gxdgxvj49zajft/admins%20blog.png{" "}
                <br />
                https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/1080/Big_Buck_Bunny_1080_10s_1MB.mp4{" "}
                <br />
              </DialogContentText>
              <FormControl fullWidth className={classes.formTop}>
                <Field
                  name="type"
                  component={renderSelectField}
                  label="Type"
                  className={classes.selectField}
                >
                  <MenuItem key="VIDEO" value="VIDEO">
                    Video
                  </MenuItem>
                  <MenuItem key="IMAGE" value="IMAGE">
                    Image
                  </MenuItem>
                </Field>
              </FormControl>
              <FormControl fullWidth className={classes.formTop}>
                <Field
                  className={classes.textField}
                  name="name"
                  component={renderField}
                  label="Name"
                  type="text"
                  validate={[required(), this.verifyName]}
                />
              </FormControl>
              <FormControl fullWidth className={classes.formTop}>
                <Field
                  className={classes.textField}
                  name="url"
                  component={renderField}
                  label="Url"
                  type="text"
                  validate={[required(), this.verifyUrl]}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                onClick={this.props.onClickLeaveUpdateMode}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={invalid || submitting || pristine}
                onClick={handleSubmit(this.submit)}
                type="submit"
                autoFocus
              >
                Add
              </Button>
            </DialogActions>
          </Dialog>
        </form>
        <Dialog
          open={this.state.openInfoDialog}
          onClose={this.handleInfoDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Media upload in progress
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Your media will be processed and added to your player. After
              successful upload to the player you will see it in the list of
              local media.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleInfoDialogClose}
              color="primary"
              autoFocus
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  addMedia: (player, name, uri, type) =>
    dispatch(postMediaLocalAPI(player, name, uri, type)),
});

MediaAdd = reduxForm({
  form: "mediaAdd",
})(MediaAdd);

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(MediaAdd)
);
