import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { openRequestAccount } from "../actions.js";
import { connect } from "react-redux";

const styles = (theme) => ({
  container: {
    display: "flex",
    margin: "auto auto",
    width: 500,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      flexDirection: "column",
    },
  },
  textField: {
    marginRight: 4,
    marginLeft: 8,
    flexGrow: 1,
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 8,
      flexGrow: 0,
    },
  },
  button: {
    flexShrink: 0,
    margin: 8,
  },
  notchedOutline: {
    borderWidth: "1px",
    borderColor: "white !important",
  },
});

class RequestAccessEntry extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
    };
  }

  updateEmailValue = (evt) => {
    this.setState({
      email: evt.target.value,
    });
  };

  requestAccountClick = () => {
    this.props.openRequestAccount(this.state.email);
  };

  render() {
    const { classes } = this.props;

    return (
      <Grid
        container
        className={classes.container}
        direction="row"
        alignItems="center"
      >
        <TextField
          id="outlined-password-input"
          label="Enter your email"
          className={classes.textField}
          type="email"
          name="email"
          autoComplete="email"
          variant="outlined"
          value={this.state.email}
          onChange={this.updateEmailValue}
          InputProps={{
            classes: {
              notchedOutline: classes.notchedOutline,
            },
          }}
        />

        <Button
          color="primary"
          variant="contained"
          size="large"
          className={classes.button}
          onClick={this.requestAccountClick}
        >
          Request access
        </Button>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  openRequestAccount: (email) => dispatch(openRequestAccount(email)),
});

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(RequestAccessEntry)
);
