import React from "react";
import { ForgotPassword } from "aws-amplify-react";
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import ErrorSnack from '../../notifications/components/ErrorSnack';

const styles = theme => ({
  formControl: {
    margin: theme.spacing.unit,
  },
  button: {
    margin: theme.spacing.unit,
    align: 'center'
  },
});

class ForgotPasswordCavi extends ForgotPassword {

  state = {
    openError: false,
    error: ''
  }

  handleClickCloseError = () => {
    this.setState({openError: false});
  }

  error(err) {
    this.setState({openError: true, error: err.message});
  }

  sendView() {
    const { classes } = this.props;
    return (
        <div>
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel htmlFor="username">Email</InputLabel>
            <Input id="username" key="username" name="username" onChange={this.handleInputChange} />
          </FormControl>
        </div>
    );
  }

  submitView() {
    const { classes } = this.props;
    return (
        <div>
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel htmlFor="code">Code</InputLabel>
            <Input id="code" key="code" name="code" onChange={this.handleInputChange} />
          </FormControl>
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel htmlFor="password">Password</InputLabel>
            <Input id="password" key="password" name="password" type="password" onChange={this.handleInputChange} />
          </FormControl>
        </div>
    );
  }

  showComponent(theme) {
    const { classes, authState, authData={} } = this.props;
    const { openError, error } = this.state;
    const open = (authState === 'forgotPassword')
    return (
      <Dialog
          open={open}
          onClose={() => this.changeState('')} // just return to initial screen
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Reset Password</DialogTitle>
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                { this.state.delivery || authData.username ? this.submitView() : this.sendView() }
              </Grid>
              <Grid item xs={12}>
                { openError ?
                  <Grid item xs={12}>
                    <ErrorSnack open={openError} message={error} handleClose={this.handleClickCloseError} />
                  </Grid>
                : null }
              </Grid>
              <Grid item xs={12}>
                { this.state.delivery || authData.username ?
                      <Button variant="contained" color="primary" className={classes.button} onClick={this.submit}>
                        Submit
                      </Button>
                    :
                      <Button variant="contained" color="primary" className={classes.button} onClick={this.send}>
                        Send Code
                      </Button>
                }
              </Grid>
              <Grid item xs={12}>
                { this.state.delivery || authData.username ?
                  <Typography>
                    <Link onClick={this.send}>Resend Code</Link>
                  </Typography>
                  :
                  <Typography>
                    <Link onClick={() => this.changeState('signIn')}>
                      Back to Sign In
                    </Link>
                  </Typography>
                }
              </Grid>
            </Grid>
          </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(styles)(ForgotPasswordCavi);
