import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import Typography from '@material-ui/core/Typography';

import { playPlayer } from '../actions'

import sunriseImage from '../ambient-images/sunrise.jpg';
import sunsetImage from '../ambient-images/sunset.jpg';
import wavesImage from '../ambient-images/waves.jpg';
import forestImage from '../ambient-images/forest.jpg';
import poppiesImage from '../ambient-images/poppies.jpg';


const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  title: {
  },
});


const tileData = [
   {
     img: sunriseImage,
     title: 'Sunrise',
     url: 'rtmp://s1ncsmuzekoro8.cloudfront.net/cfx/st/sunrise.mp4'
   },
   {
     img: sunsetImage,
     title: 'Sunset',
     url: 'rtmp://s1ncsmuzekoro8.cloudfront.net/cfx/st/sunset.mp4'
   },
   {
     img: wavesImage,
     title: 'Water',
     url: 'rtmp://s1ncsmuzekoro8.cloudfront.net/cfx/st/waves.mp4'
   },
   {
     img: forestImage,
     title: 'Forest',
     url: 'rtmp://s1ncsmuzekoro8.cloudfront.net/cfx/st/forest.mp4'
   },
   {
     img: poppiesImage,
     title: 'Poppy Field',
     url: 'rtmp://s1ncsmuzekoro8.cloudfront.net/cfx/st/poppies.mp4'
   },
];



class Ambient extends Component {

  state = {
  };

  startVideoPlayback = (url) => {
    const player = this.props.selectedPlayer;
    const config = { "uri" : url};
    this.props.playPlayer(player, "stream", config);
  }

	render () {
		const { classes } = this.props;
		return (
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">
              Ambient Videos
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <GridList cellHeight={120} className={classes.gridList} cols={1}>
              {tileData.map(tile => (
                <GridListTile key={tile.img} cols={tile.cols || 1}>
                  <img src={tile.img} alt={tile.title} onClick={() => this.startVideoPlayback(tile.url)}/>
                  <GridListTileBar
                    title={tile.title}
                    classes={{
                      title: classes.title,
                    }}
                  />
                </GridListTile>
              ))}
            </GridList>
          </Grid>
        </Grid>
      </div>
    );
	}
}

const mapStateToProps = state => ({
    selectedPlayer: state.kitchen.selectedPlayer,
})

const mapDispatchToProps = dispatch => ({
    playPlayer: (player, type, config) => dispatch(playPlayer(player, type, config)),
})

export default withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps
)(Ambient))
