import {
  OPEN_REQUEST_ACCOUNT,
  CLOSE_REQUEST_ACCOUNT
} from './actionTypes';


const initialRequestAccount = {
  requestAccountOpen: false,
  details: {},
  preferences: {}
}

export const requestAccount = (state = initialRequestAccount, action) => {
  switch (action.type) {
    case OPEN_REQUEST_ACCOUNT:
      return Object.assign({}, state,
        {
          requestAccountOpen: true,
          details: {
            email: action.email
          }
        })
    case CLOSE_REQUEST_ACCOUNT:
      return Object.assign({}, state, {requestAccountOpen: false})
    default:
      return state
  }
}
