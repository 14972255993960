import React, { Component } from 'react';
import { connect } from 'react-redux'
import { loadPlayers } from '../actions'
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { closePlayerSelector, setPlayer } from '../actions'


const styles = theme => ({
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 300,
  },
});

class PlayerSelector extends Component {

	constructor (props) {
		super(props);
    this.state = {player: ''};
	}

  handlePlayerChange = (player) => {
    this.props.setPlayer(player)
    this.setState({player: player});
    this.props.closePlayerSelector();
  }

	componentDidMount() {
    this.props.reloadPlayers();
	}

	render () {
		const { playerSelectorOpened, closePlayerSelector, selectedPlayer } = this.props;
    console.log(playerSelectorOpened);
    return (
      <Dialog open={playerSelectorOpened} onClose={closePlayerSelector} aria-labelledby="player-selector-dialog">
        <DialogTitle id="simple-dialog-title">Select player</DialogTitle>
        <List>
          {this.props.players.map(player => (
            <ListItem
              button
              onClick={() => this.handlePlayerChange(player.playerName)}
              key={player.playerName}
              selected={(player.playerName === selectedPlayer) ? true : false}
            >
              <ListItemText primary={player.playerName} />
            </ListItem>
          ))}
        </List>
      </Dialog>
    );
	}
}

const mapStateToProps = state => ({
  players: state.kitchen.items,
  playerSelectorOpened: state.kitchen.playerSelectorOpened,
  selectedPlayer: state.kitchen.selectedPlayer
})

const mapDispatchToProps = dispatch => ({
  reloadPlayers: () => dispatch(loadPlayers()),
  setPlayer: (player) => dispatch(setPlayer(player)),
  closePlayerSelector: () => dispatch(closePlayerSelector())
})

export default withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayerSelector))
