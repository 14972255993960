import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "./AppBar.js";
import MainHero from "./MainHero.js";
import MainSetup from "./MainSetup.js";
import MainDeveloper from "./MainDeveloper.js";
import MainEnterprises from "./MainEnterprises.js";
import MainInputOutput from "./MainInputOutput";
import MainContact from "./MainContact";
import MainInteractive from "./MainInteractive";
import MainFooter from "./MainFooter";
import MainPricing from "./MainPricing";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import { theme } from "../../AppThemeMain.js";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import RequestAccess from "./RequestAccess";
import CssBaseline from "@material-ui/core/CssBaseline";

const styles = {};

class Main extends Component {
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <AppBar {...this.props} />
        <MainHero {...this.props} />
        <MainInputOutput />
        <MainDeveloper />
        <MainInteractive />
        <MainSetup />
        <MainPricing />
        <MainEnterprises />
        <RequestAccess />
        <MainContact />
        <MainFooter />
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(Main);
