import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardActionArea from '@material-ui/core/CardActionArea';
import FilledInput from '@material-ui/core/FilledInput';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import PlayIcon from '../../common/icons/PlayIcon';
import { playPlayer } from '../actions'


const styles = theme => ({
  cardPlay: {
    width: 80,
    marginLeft: "auto",
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  card: {
    display: 'flex',
  },
  playIcon: {
    marginRight: 10
  }
});


class Time extends Component {

  state = {
    value: 0,
    timerTime: 60,
    eggTime: 300,
  };

  timerTimeChange = (event) => {
    this.setState({
      timerTime: event.target.value
    })
  }

  eggTimerChange = (event) => {
    this.setState({
      eggTime: event.target.value
    })
  }

  handlePlayerChange = (player) => {
    this.props.setPlayer(player);
  }

  digitalClockConfig = {
    type: 'digital'
  }

  startDigitalClock = () => {
    this.props.playPlayer(this.props.selectedPlayer, 'clock', this.digitalClockConfig);
  }

  timerConfig = {
    startingSide: 'top',
    progressDirection: 'shrink',
    progressStart: 1.0,
    color: 'Green'
  }

  startTimer = () => {
    var config = this.timerConfig;
    config.time = this.state.timerTime;
    this.props.playPlayer(this.props.selectedPlayer, 'progress', config);
  }

  eggTimerConfig = {
    startingSide: 'bottom',
    progressDirection: 'grow',
    progressStart: 0.0,
    color: 'Red'
  }

  startEggTimer = () => {
    var config = this.eggTimerConfig;
    config.time = this.state.eggTime;
    this.props.playPlayer(this.props.selectedPlayer, 'progress', config);
  }

	render () {
		const { classes } = this.props;
		return (
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">
              Time
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Card className={classes.card}>
              <CardContent className={classes.details}>
                <Typography component="h5" variant="h5">
                  Clock
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                  Start your digital clock
                </Typography>
              </CardContent>
              <CardActionArea onClick={this.startDigitalClock} className={classes.cardPlay}>
                <PlayIcon size="small" />
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card className={classes.card}>
              <CardContent className={classes.details}>
                <Typography component="h5" variant="h5">
                  Timer
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                  Set your timer (in minutes)
                </Typography>
                <FormControl>
                  <Select
                    value={this.state.timerTime}
                    onChange={this.timerTimeChange}
                    input={<FilledInput name="timer-time" id="timer-time" />}
                  >
                    <MenuItem value={30}>0.5</MenuItem>
                    <MenuItem value={60}>1</MenuItem>
                    <MenuItem value={120}>2</MenuItem>
                    <MenuItem value={180}>3</MenuItem>
                    <MenuItem value={240}>4</MenuItem>
                    <MenuItem value={300}>5</MenuItem>
                    <MenuItem value={600}>10</MenuItem>
                    <MenuItem value={900}>15</MenuItem>
                    <MenuItem value={1200}>20</MenuItem>
                  </Select>
                </FormControl>
              </CardContent>
              <CardActionArea onClick={this.startTimer} className={classes.cardPlay}>
                <PlayIcon size="small"/>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card className={classes.card}>
              <CardContent className={classes.details}>
                <Typography component="h5" variant="h5">
                  Egg Timer
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                  How do you like your egg?
                </Typography>
                <FormControl>
                  <Select
                    value={this.state.eggTime}
                    onChange={this.eggTimerChange}
                    input={<FilledInput name="timer-time" id="timer-time" />}
                  >
                    <MenuItem value={240}>Soft</MenuItem>
                    <MenuItem value={300}>Medium</MenuItem>
                    <MenuItem value={420}>Hard</MenuItem>
                  </Select>
                </FormControl>
              </CardContent>
              <CardActionArea onClick={this.startEggTimer} className={classes.cardPlay}>
                <PlayIcon size="small"/>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
	}
}

const mapStateToProps = state => ({
    selectedPlayer: state.kitchen.selectedPlayer,
})

const mapDispatchToProps = dispatch => ({
    playPlayer: (player, type, config) => dispatch(playPlayer(player, type, config)),
})

export default withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps
)(Time))
