import React, { Component } from 'react';
import MediaCommon from './MediaCommon.js';
import MediaLocal from './MediaLocal.js'
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { Route } from 'react-router-dom';


const styles = theme => ({

});

class MediaOverview extends Component {

    constructor() {
        super();
        this.state = {
            mode: "public",
        };
    }

    componentDidMount() {
        if (this.props.location.pathname.includes("local")) {
            this.setState({mode: "local"});
        } else {
            this.setState({mode: "public"});
        }
    }

    handleChange = (mode) => {
        this.setState({mode: mode});
        this.props.history.push("/console/media/" + mode);
    }
    
    render () {
        const mode = this.state.mode
        return (
            <Grid
            container
            spacing={3}
            alignContent="center"
            alignItems="center"
            >
            <Grid item xs={12} align="center" alignContent="center">
                <ButtonGroup color="secondary">
                <Button variant={mode === "public" ? "contained" : "outlined"} onClick={() => this.handleChange("public")}>Public</Button>
                <Button variant={mode === "local" ? "contained" : "outlined"} onClick={() => this.handleChange("local")}>Local</Button>
                </ButtonGroup>
            </Grid>
            <Grid item xs={12} align="center" alignContent="center">
                <Route exact path="/console/media" render={(props) => <MediaCommon />} />
                <Route exact path="/console/media/public" render={(props) => <MediaCommon />} />
                <Route exact path="/console/media/local" render={(props) => <MediaLocal />} />
            </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(withRouter(MediaOverview))
