import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const styles = (theme) => ({});

class WebsiteApp extends Component {
  // the state represents the config of the widget
  state = {
    uri: "",
  };

  componentDidMount() {
    // report initial state
    this.setStateCallback();
  }

  handleChange = (event) => {
    this.setState(
      { [event.target.name]: event.target.value },
      this.setStateCallback
    );
  };

  setStateCallback = () => {
    this.props.onConfigChange(this.state);
  };

  render() {
    return (
      <TextField
        id="uri"
        name="uri"
        label="URI"
        value={this.state.uri}
        onChange={this.handleChange}
        margin="normal"
        fullWidth
      />
    );
  }
}

export default withStyles(styles)(WebsiteApp);
