import {
  ADD_DEVICE,
  ENTER_DEVICE_UPDATE_MODE,
  LEAVE_DEVICE_UPDATE_MODE,
  GET_DEVICE_REQUEST,
  GET_DEVICE_FAILURE,
  GET_DEVICE_SUCCESS,
  UPDATE_DEVICE_REQUEST,
  UPDATE_DEVICE_FAILURE,
  UPDATE_DEVICE_SUCCESS,
  REGISTER_DEVICE_REQUEST,
  REGISTER_DEVICE_FAILURE,
  REGISTER_DEVICE_SUCCESS,
  INVALIDATE_DEVICES,
  DELETE_DEVICE_REQUEST,
  DELETE_DEVICE_FAILURE,
  DELETE_DEVICE_SUCCESS,
  SET_SELECTED_DEVICE,
  SET_DEVICE_IN_CONFIGURATION,
  DELETE_OUTPUT_IN_DEVICE_CONFIGURATION,
  UPDATE_OUTPUT_IN_DEVICE_CONFIGURATION,
  ADD_OUTPUT_IN_DEVICE_CONFIGURATION,
  SET_OUTPUT_IN_CONFIGURATION,
} from "./actionTypes";

const initialStateDevices = {
  loading: false, // marks that the devices are currently loading
  invalid: true, // shows that at least one device item is invalid (reload is necessary)
  register: false,
  registerSuccess: false,
  items: [],
  selectedDevice: null,
  deviceInConfiguration: null,
  outputInConfiguration: null,
};

export default (state = initialStateDevices, action) => {
  switch (action.type) {
    case ADD_DEVICE:
      return Object.assign({}, state, {
        items: [
          ...state.items,
          {
            id: action.device.id,
            inWork: false,
            deviceOriginal: action.device,
          },
        ],
      });
    case ENTER_DEVICE_UPDATE_MODE:
      return Object.assign({}, state, {
        items: state.items.map((device) => {
          if (device.id === action.device.id) {
            return Object.assign({}, device, {
              inWork: true,
            });
          }
          return device;
        }),
      });
    case LEAVE_DEVICE_UPDATE_MODE:
      return Object.assign({}, state, {
        items: state.items.map((device) => {
          if (device.id === action.device.id) {
            return Object.assign({}, device, {
              inWork: false,
            });
          }
          return device;
        }),
      });
    case INVALIDATE_DEVICES:
      return Object.assign({}, state, {
        invalid: true,
      });
    case GET_DEVICE_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_DEVICE_FAILURE:
      return Object.assign({}, state, {
        loading: false,
      });
    case GET_DEVICE_SUCCESS:
      var devices = action.response.data;
      // enhance devices with UI state
      return Object.assign({}, state, {
        items: devices.map((device) =>
          Object.assign(
            {},
            {},
            {
              id: device.id,
              deviceOriginal: enhanceDeviceIfNecessary(device),
              inWork: false,
            }
          )
        ),
        loading: false,
        invalid: false,
      });
    case UPDATE_DEVICE_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      });
    case UPDATE_DEVICE_FAILURE:
      return Object.assign({}, state, {
        loading: false,
      });
    case UPDATE_DEVICE_SUCCESS:
      return Object.assign({}, state, {
        items: state.items.map((device) => {
          if (device.id === action.deviceId) {
            return Object.assign({}, device, {
              inWork: false,
            });
          }
          return device;
        }),
        loading: false,
        invalid: true,
      });
    case DELETE_DEVICE_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      });
    case DELETE_DEVICE_FAILURE:
      return Object.assign({}, state, {
        loading: false,
      });
    case DELETE_DEVICE_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        invalid: true,
      });
    case REGISTER_DEVICE_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        registerSuccess: false,
      });
    case REGISTER_DEVICE_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        registerSuccess: false,
      });
    case REGISTER_DEVICE_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        invalid: true,
        register: false,
        registerSuccess: true,
      });
    case SET_SELECTED_DEVICE:
      const device = state.items.filter((d) => d.id === action.deviceId);
      return Object.assign({}, state, {
        selectedDevice: device[0],
      });
    case SET_DEVICE_IN_CONFIGURATION:
      const deviceInConfiguration = state.items.filter(
        (d) => d.id === action.deviceId
      );
      if (deviceInConfiguration.length > 0) {
        return Object.assign({}, state, {
          deviceInConfiguration: deviceInConfiguration[0].deviceOriginal,
        });
      }
      return state;
    case DELETE_OUTPUT_IN_DEVICE_CONFIGURATION:
      var outputsNew = state.deviceInConfiguration.configuration.outputs.filter(
        (output) => {
          if (output.id !== action.outputId) {
            return output;
          }
          return null;
        }
      );
      var newConfiguration = Object.assign(
        {},
        state.deviceInConfiguration.configuration,
        {
          outputs: outputsNew,
        }
      );
      return Object.assign({}, state, {
        deviceInConfiguration: Object.assign({}, state.deviceInConfiguration, {
          configuration: newConfiguration,
        }),
      });
    case UPDATE_OUTPUT_IN_DEVICE_CONFIGURATION:
      outputsNew = state.deviceInConfiguration.configuration.outputs.map(
        (output) => {
          if (output.id === action.originalOutputId) {
            return action.output;
          }
          return output;
        }
      );
      newConfiguration = Object.assign(
        {},
        state.deviceInConfiguration.configuration,
        {
          outputs: outputsNew,
        }
      );
      return Object.assign({}, state, {
        deviceInConfiguration: Object.assign({}, state.deviceInConfiguration, {
          configuration: newConfiguration,
        }),
      });
    case ADD_OUTPUT_IN_DEVICE_CONFIGURATION:
      console.log(action.output);
      outputsNew = [
        ...state.deviceInConfiguration.configuration.outputs,
        action.output,
      ];
      newConfiguration = Object.assign(
        {},
        state.deviceInConfiguration.configuration,
        {
          outputs: outputsNew,
        }
      );
      return Object.assign({}, state, {
        deviceInConfiguration: Object.assign({}, state.deviceInConfiguration, {
          configuration: newConfiguration,
        }),
      });
    case SET_OUTPUT_IN_CONFIGURATION:
      return Object.assign({}, state, {
        outputInConfiguration: action.output,
      });
    default:
      return state;
  }
};

// Enhances the device object with additional information if not provided by server
function enhanceDeviceIfNecessary(device) {
  if (device.configuration === undefined) {
    device.configuration = {};
  }
  if (device.configuration.generalOutputConfiguration === undefined) {
    device.configuration.generalOutputConfiguration = {};
  }
  if (device.configuration.outputs === undefined) {
    device.configuration.outputs = [];
  }
  return device;
}
