import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  updateDevice,
  loadDevices,
  setDeviceInConfiguration,
  deleteOutputInDeviceConfiguration,
  updateOutputInDeviceConfiguration,
  addOutputInDeviceConfiguration,
  setOutputInConfiguration,
} from "../actions";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import DeleteIcon from "@material-ui/icons/Delete";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import EditIcon from "@material-ui/icons/Edit";
import HelpIcon from "@material-ui/icons/Help";
import AddBoxRoundedIcon from "@material-ui/icons/AddBoxRounded";
import { Field, reduxForm } from "redux-form";
import { required } from "redux-form-validators";
import {
  renderField,
  ipValidator,
  labelalidator,
} from "../../../common/Utilities";
import DeviceConfigurationOutput from "./DeviceConfigurationOutput.js";
import { withRouter } from "react-router-dom";
import Divider from "@material-ui/core/Divider";

const styles = (theme) => ({
  saveButton: {
    color: theme.palette.primary.light,
    marginLeft: "auto",
    float: "right",
  },
  addOutputButton: {
    color: theme.palette.primary.light,
    marginLeft: "auto",
    float: "right",
  },
  helpButton: {
    color: theme.palette.primary.light,
    marginLeft: "auto",
    float: "right",
  },
  headline: {
    marginTop: theme.spacing(8),
  },
});

class DeviceConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = { editOutputOpen: false, editOutputMode: "EDIT" };
  }
  componentDidMount() {
    this.deviceId = this.props.match.params.id;
    if (this.props.devices.length === 0) {
      this.props.reloadDevices();
    } else {
      this.props.setDeviceInConfiguration(this.deviceId);
    }
  }

  submit = (values) => {
    this.props.updateDevice(
      this.deviceId,
      values.label,
      values.configuration.generalOutputConfiguration,
      this.props.deviceInConfiguration.configuration.outputs
    );
    this.props.history.goBack();
  };

  handleEditOutput = (output) => {
    this.props.setOutputInConfiguration(output);
    this.setState({
      editOutputOpen: true,
      editOutputMode: "EDIT",
    });
  };

  handleNewOutput = () => {
    var defaultOutput = {
      id: "",
      type: "HDMI",
    };
    this.props.setOutputInConfiguration(defaultOutput);
    this.setState({
      editOutputOpen: true,
      editOutputMode: "CREATE",
    });
  };

  leaveOutputConfig = () => {
    this.setState({
      editOutputOpen: false,
    });
  };

  saveOutputEditConfig = (originalOutputId, output) => {
    this.props.updateOutputInDeviceConfiguration(originalOutputId, output);
    this.setState({
      editOutputOpen: false,
    });
  };

  saveOutputNewConfig = (output) => {
    this.props.addOutputInDeviceConfiguration(output);
    this.setState({
      editOutputOpen: false,
    });
  };

  handleHelpClick = (event) => {
    window.open("http://developer.easyvis.io/docs/visualization-devices/");
  };

  createOutputItem = (output) => {
    return (
      <div key={output.id}>
        <ListItem key={output.id}>
          <ListItemText
            id={output.id}
            primary={output.id}
            secondary={output.type}
          />
          <ListItemSecondaryAction>
            <IconButton
              aria-label="delete"
              onClick={() => {
                this.props.deleteOutputInDeviceConfiguration(output.id);
              }}
            >
              <DeleteIcon />
            </IconButton>
            <IconButton
              aria-label="edit"
              onClick={() => {
                this.handleEditOutput(output);
              }}
            >
              <EditIcon size="small" />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
      </div>
    );
  };

  render() {
    const { classes, handleSubmit } = this.props;

    // Set device in configuration (necessary for a reload or when page is called directly)
    if (
      this.props.devices.length > 0 &&
      this.props.deviceInConfiguration === null
    ) {
      this.props.setDeviceInConfiguration(this.deviceId);
    }

    return (
      <div>
        {this.props.initialValues ? (
          <form onSubmit={handleSubmit(this.submit)}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <IconButton
                  onClick={() => this.props.history.goBack()}
                  className={classes.reload}
                >
                  <ArrowBackIosIcon />
                </IconButton>
                <Button
                  onClick={handleSubmit(this.submit)}
                  className={classes.saveButton}
                  variant="outlined"
                  color="primary"
                >
                  Save
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h3">Configure Device</Typography>
                <Typography variant="body1">
                  {this.props.initialValues.id}
                </Typography>
                <IconButton
                  onClick={() => this.handleHelpClick()}
                  className={classes.helpButton}
                >
                  <HelpIcon fontSize="large" />
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" className={classes.headline}>
                  General
                </Typography>
                <Typography variant="body1">
                  General configuration settings for the device
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Field
                  className={classes.textField}
                  name="label"
                  component={renderField}
                  label="Device Name"
                  type="text"
                  validate={[required(), labelalidator]}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <div>
                  <Typography variant="h5" className={classes.headline}>
                    Outputs
                  </Typography>
                  <Typography variant="body1">
                    List of supported outputs for the device
                  </Typography>
                </div>
                <IconButton
                  onClick={() => {
                    this.handleNewOutput();
                  }}
                  className={classes.addOutputButton}
                >
                  <AddBoxRoundedIcon fontSize="large" />
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                <List dense>
                  {this.props.deviceInConfiguration.configuration.outputs
                    ? this.props.deviceInConfiguration.configuration.outputs.map(
                        (item) => this.createOutputItem(item)
                      )
                    : null}
                </List>
              </Grid>
              <Grid item xs={12}>
                <DeviceConfigurationOutput
                  open={this.state.editOutputOpen}
                  mode={this.state.editOutputMode}
                  output={this.state.output}
                  leaveOutputConfig={this.leaveOutputConfig}
                  saveOutputEditConfig={this.saveOutputEditConfig}
                  saveOutputNewConfig={this.saveOutputNewConfig}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" className={classes.headline}>
                  General Output Configuration
                </Typography>
                <Typography variant="body1">
                  Configuration applied to all outputs in the device.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Field
                  className={classes.textField}
                  name="configuration.generalOutputConfiguration.ip"
                  component={renderField}
                  label="IP"
                  type="text"
                  validate={[ipValidator]}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  className={classes.textField}
                  name="configuration.generalOutputConfiguration.netmask"
                  label="Netmask"
                  component={renderField}
                  type="text"
                  validate={[ipValidator]}
                />
              </Grid>
            </Grid>
          </form>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  devices: state.devices.items,
  invalid: state.devices.invalid,
  register: state.devices.register,
  initialValues: state.devices.deviceInConfiguration,
  deviceInConfiguration: state.devices.deviceInConfiguration,
});

const mapDispatchToProps = (dispatch) => ({
  updateDevice: (id, label, generalOutputConfiguration, outputs) =>
    dispatch(updateDevice(id, label, generalOutputConfiguration, outputs)),
  reloadDevices: () => dispatch(loadDevices()),
  deleteOutputInDeviceConfiguration: (outputId) =>
    dispatch(deleteOutputInDeviceConfiguration(outputId)),
  updateOutputInDeviceConfiguration: (originalOutputId, output) =>
    dispatch(updateOutputInDeviceConfiguration(originalOutputId, output)),
  addOutputInDeviceConfiguration: (output) =>
    dispatch(addOutputInDeviceConfiguration(output)),
  setDeviceInConfiguration: (deviceId) =>
    dispatch(setDeviceInConfiguration(deviceId)),
  setOutputInConfiguration: (output) =>
    dispatch(setOutputInConfiguration(output)),
});

DeviceConfiguration = reduxForm({
  form: "device",
  enableReinitialize: true,
})(DeviceConfiguration);

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(DeviceConfiguration))
);
