import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { getMediaLocalAPI, deleteMediaLocalAPI } from "../actions";
import MediaList from "./MediaList";
import PlayerSelector from "../../player/components/PlayerSelector";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MediaAdd from "./MediaAdd";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { addNotification } from "../../../common/notifications/actions";
import Box from "@material-ui/core/Box";
import Media from "react-media";

const styles = (theme) => ({
  box: {
    margin: "0px",
    padding: "0px",
  },
});

class MediaLocal extends Component {
  constructor() {
    super();
    this.state = {
      addMediaOpen: false,
      selectedPlayer: null,
    };
  }

  onPlayerChange = (player) => {
    this.props.loadMedia(player);
    this.setState({ selectedPlayer: player });
  };

  refresh = () => {
    if (this.state.selectedPlayer !== null) {
      this.props.loadMedia(this.state.selectedPlayer);
    }
  };

  onAddButtonClick = () => {
    if (this.state.selectedPlayer !== null) {
      this.setState({ addMediaOpen: true });
    } else {
      this.props.notify("Select device first", "info");
    }
  };

  closeMediaOpen = () => {
    this.setState({ addMediaOpen: false });
  };

  deleteCallback = (mediaItem) => {
    this.props.deleteMedia(this.state.selectedPlayer, mediaItem.id);
  };

  render() {
    const { classes } = this.props;
    const items = this.props.media;
    return (
      <Grid
        container
        spacing={3}
        alignContent="center"
        alignItems="center"
        align="center"
        justify="center"
      >
        <Grid item xs={12} align="center" alignContent="center">
          <Typography variant="h4" align="left">
            Local player media
          </Typography>
          <Typography variant="body2" align="left">
            The media stored on your local player
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box
            display="flex"
            p={1}
            justifyContent="center"
            alignContent="center"
            alignItems="center"
            className={classes.box}
          >
            <Box p={1} flexGrow={1} align="left">
              <PlayerSelector onPlayerChange={this.onPlayerChange} noLoop />
            </Box>
            <Box p={1}>
              <Media query="(max-width: 599px)">
                {(matches) =>
                  matches ? (
                    <IconButton
                      variant="outlined"
                      color="primary"
                      aria-label="Add"
                      onClick={() => this.onAddButtonClick()}
                    >
                      <CloudUploadIcon fontSize="large" />
                    </IconButton>
                  ) : (
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => this.onAddButtonClick()}
                      startIcon={<CloudUploadIcon />}
                    >
                      Add media
                    </Button>
                  )
                }
              </Media>
            </Box>
          </Box>
          <MediaAdd
            player={this.state.selectedPlayer}
            open={this.state.addMediaOpen}
            onClickLeaveUpdateMode={this.closeMediaOpen}
          />
        </Grid>
        <Grid item xs={12} align="center" alignContent="center">
          <MediaList
            media={items}
            deleteEnabled={true}
            deleteCallback={this.deleteCallback}
            refresh
            refreshCallback={this.refresh}
          />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  media: state.media.mediaLocal,
  selectedPlayer: state.players.selectedPlayer,
});

const mapDispatchToProps = (dispatch) => ({
  loadMedia: (device) => dispatch(getMediaLocalAPI(device)),
  deleteMedia: (device, mediaName) =>
    dispatch(deleteMediaLocalAPI(device, mediaName)),
  notify: (message, type) => dispatch(addNotification(message, type)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(MediaLocal)
);
