import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Stop from '@material-ui/icons/Stop';
import Fab from '@material-ui/core/Fab';

const styles = theme => ({
  stopIcon: {
    color: "white",
    backgroundColor: theme.palette.secondary.light,
    margin: theme.spacing.unit,
  }
});

class StopIcon extends Component {

	render () {
    const { classes, size, onClick } = this.props;
		return (
      <Fab className={classes.stopIcon} size={size} onClick={onClick}>
        <Stop />
      </Fab>
    );
	}
}



export default withStyles(styles)(StopIcon)
