import {
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
} from './actionTypes';


const defaultNotificationState = {
    notifications: [],
};

export const notifications = (state = defaultNotificationState, action) => {
  const { error, warning, info, success, message, variant } = action
  if (error) {
    return {
        ...state,
        notifications: [
            ...state.notifications,
            {
                key: new Date().getTime() + Math.random(),
                message: error,
                variant: 'error'
            },
        ],
    };
  }
  if (warning) {
    return {
        ...state,
        notifications: [
            ...state.notifications,
            {
                key: new Date().getTime() + Math.random(),
                message: warning,
                variant: 'warning'
            },
        ],
    };
  }
  if (info) {
    return {
        ...state,
        notifications: [
            ...state.notifications,
            {
                key: new Date().getTime() + Math.random(),
                message: info,
                variant: 'info'
            },
        ],
    };
  }
  if (success) {
    return {
        ...state,
        notifications: [
            ...state.notifications,
            {
                key: new Date().getTime() + Math.random(),
                message: success,
                variant: 'success'
            },
        ],
    };
  }
  switch (action.type) {
    case ADD_NOTIFICATION:
      return {
          ...state,
          notifications: [
              ...state.notifications,
              {
                  key: new Date().getTime() + Math.random(),
                  message: message,
                  variant: variant
              },
          ],
      };
    case REMOVE_NOTIFICATION:
      return {
          ...state,
          notifications: state.notifications.filter(
              notification => notification.key !== action.key,
          ),
      };
    default:
      return state;
  }
}
