import React from "react";
import { RequireNewPassword } from "aws-amplify-react";
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import ErrorSnack from '../../notifications/components/ErrorSnack';

const styles = theme => ({
  formControl: {
    margin: theme.spacing.unit,
  },
  button: {
    margin: theme.spacing.unit,
    align: 'center'
  },
});

class RequireNewPasswordCavi extends RequireNewPassword {

  state = {
    openError: false,
    error: ''
  }

  handleClickCloseError = () => {
    this.setState({openError: false});
  }

  error(err) {
    this.setState({openError: true, error: err.message});
  }

  onFormSubmit = e => {
    e.preventDefault();
    this.change();
  }

  showComponent(theme) {
    const { classes, authState } = this.props;
    const { openError, error } = this.state;
    const open = (authState === 'requireNewPassword')
    return (
      <Dialog
          open={open}
          onClose={() => this.changeState('')} // just return to initial screen
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Change Password</DialogTitle>
          <DialogContent>
            <form onSubmit={this.onFormSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormControl className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="password">New Password</InputLabel>
                    <Input id="password" key="password" name="password" type="password" onChange={this.handleInputChange} />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  { openError ?
                    <Grid item xs={12}>
                      <ErrorSnack open={openError} message={error} handleClose={this.handleClickCloseError} />
                    </Grid>
                  : null }
                </Grid>
                <Grid item xs={12}>
                  <Button variant="contained" color="primary" className={classes.button} type="submit">
                    Change
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    <Link onClick={() => this.changeState('signIn')}>
                      Back to Sign In
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(styles)(RequireNewPasswordCavi);
