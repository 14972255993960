import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Field, reduxForm } from "redux-form";
import { required, numericality } from "redux-form-validators";
import { renderField, renderSelectField } from "../../../common/Utilities";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import GridOn from "@material-ui/icons/GridOn";
import Tv from "@material-ui/icons/Tv";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import MenuItem from "@material-ui/core/MenuItem";
import { SubmissionError } from "redux-form";

const styles = (theme) => ({
  outputId: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  selectField: {
    width: 120,
  },
});

class DeviceConfigurationOutput extends Component {
  constructor(props) {
    super(props);
    this.outputMap = {
      HDMI: 0,
      ARTNET: 1,
      SPI_SM16716: 2,
      SPI_WS2812: 3,
      SPI_LPIX: 4,
    };
  }

  submit = (values) => {
    this.validate(values);
    if (this.props.mode === "EDIT") {
      this.props.saveOutputEditConfig(this.props.initialValues.id, values);
    } else if (this.props.mode === "CREATE") {
      this.props.saveOutputNewConfig(values);
    } else {
      console.log("No mode selected. Cannot save output");
    }
  };

  validate = (values) => {
    // Check that all outputs are of the same type
    var o;
    for (o of this.props.allOutputs) {
      if (o.type !== values.type) {
        throw new SubmissionError({
          _error: "All outputs need to be of the same type.",
        });
      }
    }
    // Check that there is just one SPI or Artnet output
    if (
      ["HDMI", "SPI_SM16716", "SPI_WS2812"].includes(values.type) &&
      this.props.allOutputs.length > 0
    ) {
      throw new SubmissionError({
        _error: "Just one output of this type is allowed.",
      });
    }
    // Check that output id is unique
    for (o of this.props.allOutputs) {
      if (this.props.mode === "CREATE" && o.id === values.id) {
        throw new SubmissionError({
          _error: "Output id already exists but needs to be unique",
        });
      }
    }
  };

  handleConfigChange = (event, value) => {
    let outputValue = Object.keys(this.outputMap).find(
      (key) => this.outputMap[key] === value
    );
    this.props.change("type", outputValue);
  };

  render() {
    const { classes, handleSubmit, outputType, error } = this.props;
    let outputTypeValue = this.outputMap[outputType];
    let outputIdDisabled = true ? this.props.mode === "EDIT" : false;
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.leaveOutputConfig}
        maxWidth="sm"
      >
        <form onSubmit={handleSubmit(this.submit)}>
          <DialogTitle id="form-dialog-title">Configure Output</DialogTitle>
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <BottomNavigation
                  value={outputTypeValue}
                  onChange={this.handleConfigChange}
                  showLabels
                >
                  <BottomNavigationAction label="HDMI" icon={<Tv />} />
                  <BottomNavigationAction label="Artnet" icon={<GridOn />} />
                  <BottomNavigationAction
                    label="SPI SM16716"
                    icon={<GridOn />}
                  />
                  <BottomNavigationAction
                    label="SPI WS2812"
                    icon={<GridOn />}
                  />
                  <BottomNavigationAction label="SPI LPIX" icon={<GridOn />} />
                </BottomNavigation>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Field
                className={classes.outputId}
                name="id"
                label="Output Id"
                component={renderField}
                type="text"
                validate={[required()]}
                props={{
                  disabled: outputIdDisabled,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <DeviceOutputConfig type={outputType} />
            </Grid>
            {error && (
              <Grid item xs={12}>
                <Typography variant="body1" color="secondary">
                  {error}
                </Typography>
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSubmit(this.submit)} color="primary">
              OK
            </Button>
            <Button onClick={this.props.leaveOutputConfig} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

DeviceConfigurationOutput = reduxForm({
  form: "deviceOutput",
  enableReinitialize: true,
})(DeviceConfigurationOutput);

function mapStateToProps(state) {
  const selector = formValueSelector("deviceOutput");
  return {
    initialValues: state.devices.outputInConfiguration,
    outputType: selector(state, "type"),
    allOutputs: state.devices.deviceInConfiguration.configuration.outputs,
  };
}

const mapDispatchToProps = (dispatch) => ({});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(DeviceConfigurationOutput)
);

/**
 * Form for the output configuration
 */
class DeviceOutputConfigComponent extends Component {
  render() {
    const { classes, type } = this.props;
    return (
      <div>
        {type === "HDMI" ? null : (
          <Grid container spacing={3} className={classes.root}>
            <Grid item xs={12} sm={6}>
              <Field
                className={classes.textField}
                name="config.width"
                label="Width"
                component={renderField}
                type="text"
                validate={[numericality({ ">": 0 }, { int: true }), required()]}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                className={classes.textField}
                name="config.height"
                label="Height"
                component={renderField}
                type="text"
                validate={[numericality({ ">": 0 }, { int: true }), required()]}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="config.pattern"
                component={renderSelectField}
                label="Pattern"
                className={classes.selectField}
              >
                <MenuItem value="ZIGZAG">ZigZag</MenuItem>
                <MenuItem value="ZIGZAG_INVERTED">ZigZag Inverted</MenuItem>
                <MenuItem value="LINES">Lines</MenuItem>
                <MenuItem value="LINES_INVERTED">Lines Inverted</MenuItem>
              </Field>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="config.orientation"
                component={renderSelectField}
                label="Orientation"
                className={classes.selectField}
              >
                <MenuItem value="0">Default</MenuItem>
                <MenuItem value="90">90° right rotation</MenuItem>
                <MenuItem value="180">180° right rotation</MenuItem>
                <MenuItem value="270">270° right rotation</MenuItem>
              </Field>
            </Grid>
            {type === "ARTNET" || type === "SPI_LPIX" ? (
              <Grid item xs={12} sm={6}>
                <Field
                  name="config.startX"
                  label="Start Position X"
                  component={renderField}
                  type="text"
                  validate={[
                    numericality({ ">=": 0.0, "<=": 1.0 }),
                    required(),
                  ]}
                ></Field>
              </Grid>
            ) : null}
            {type === "ARTNET" || type === "SPI_LPIX" ? (
              <Grid item xs={12} sm={6}>
                <Field
                  name="config.startY"
                  label="Start Position Y"
                  component={renderField}
                  type="text"
                  validate={[
                    numericality({ ">=": 0.0, "<=": 1.0 }),
                    required(),
                  ]}
                ></Field>
              </Grid>
            ) : null}
            {type === "ARTNET" ? (
              <Grid item xs={12} sm={6}>
                <Field
                  name="config.universe"
                  label="Universe"
                  component={renderField}
                  type="text"
                  validate={[
                    numericality({ ">=": 0 }, { int: true }),
                    required(),
                  ]}
                ></Field>
              </Grid>
            ) : null}
            {type === "SPI_LPIX" ? (
              <Grid item xs={12} sm={6}>
                <Field
                  name="config.universe"
                  label="Screen ID"
                  component={renderField}
                  type="text"
                  validate={[
                    numericality({ ">=": 0 }, { int: true }),
                    required(),
                  ]}
                ></Field>
              </Grid>
            ) : null}
            {type === "SPI_LPIX" ? (
              <Grid item xs={12} sm={6}>
                <Field
                  name="config.spiBus"
                  label="SPI Bus"
                  component={renderField}
                  type="text"
                  validate={[numericality({ ">=": 0 }, { int: true })]}
                ></Field>
              </Grid>
            ) : null}
            {type === "SPI_LPIX" ? (
              <Grid item xs={12} sm={6}>
                <Field
                  name="config.spiDevice"
                  label="SPI Device"
                  component={renderField}
                  type="text"
                  validate={[numericality({ ">=": 0 }, { int: true })]}
                ></Field>
              </Grid>
            ) : null}
            {type === "ARTNET" ? (
              <Grid item xs={12} sm={6}>
                <Field
                  name="config.ledType"
                  label="Type of LED"
                  component={renderSelectField}
                  className={classes.selectField}
                >
                  <MenuItem value="RGB">RGB</MenuItem>
                  <MenuItem value="RGBW">RGBW</MenuItem>
                </Field>
              </Grid>
            ) : null}
            {type === "ARTNET" ? (
              <Grid item xs={12} sm={6}>
                <Field
                  name="config.ledKelvin"
                  label="Color temperature"
                  component={renderField}
                  type="text"
                  validate={[
                    numericality({ ">=": 1000, "<=": 10000, allowBlank: true }),
                  ]}
                ></Field>
              </Grid>
            ) : null}
          </Grid>
        )}
      </div>
    );
  }
}

const DeviceOutputConfig = withStyles(styles)(DeviceOutputConfigComponent);
