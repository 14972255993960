import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Dialog from '@material-ui/core/Dialog';


const styles = theme => ({
});

/**
 * Loading dialog with a loader. Note to pass the visible property
 */
class Loading extends Component {

  render () {
    return (
      <Dialog
        open={this.props.visible}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
        }}
      >
        <Loader
           type="Grid"
           color="#3d5afe"
           height={100}
           width={100}
           visible={this.props.visible}
        />
      </Dialog>
    )
  }
}

export default withStyles(styles)(Loading);
