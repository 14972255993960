import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import EasyvisColorPicker from '../../../../common/EasyvisColorPicker';


const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 220,
  },
});


class ClockApp extends Component {

  // the state represents the config of the widget
  state = {
    type: 'digital',
  }

  componentDidMount() {
    // report initial state
    this.setStateCallback();
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value }, this.setStateCallback);
  }

  handleColorChange = (color) => {
    this.setState({ color: color }, this.setStateCallback)
  }

  setStateCallback = () => {
    this.props.onConfigChange(this.state)
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="type">Type</InputLabel>
          <Select
            value={this.state.type}
            onChange={this.handleChange}
            inputProps={{
              name: 'type',
              id: 'type',
            }}
          >
            <MenuItem value={"digital"}>Digital</MenuItem>
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <EasyvisColorPicker
            value={this.state.color}
            onChange={this.handleColorChange}
            convert='hex'
            id="color"
            name="color"
            label="Color"
            fullWidth
            defaultValue='#FFFFFF'
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="size">Size</InputLabel>
          <Select
            value={this.state.size}
            onChange={this.handleChange}
            inputProps={{
              name: 'size',
              id: 'size',
            }}
          >
            <MenuItem value={"small"}>Small</MenuItem>
            <MenuItem value={"medium"}>Medium</MenuItem>
            <MenuItem value={"large"}>Large</MenuItem>
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            id="timezone"
            name="timezone"
            label="Timezone"
            value={this.state.timezone}
            onChange={this.handleChange}
            fullWidth
          />
        </FormControl>
      </div>
    )
  }
}

export default withStyles(styles)(ClockApp);
