import React from "react";
import { withStyles } from "@material-ui/core/styles";
import LayoutBody from "./MainLayoutBody";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import setupDownload from "../images/setup_download.png";
import setupLogin from "../images/setup_login.png";
import setupPlay from "../images/setup_play.png";

const styles = (theme) => ({
  root: {
    display: "flex",
    overflow: "hidden",
    backgroundColor: theme.main.backgroundLight,
    backgroundImage: "linear-gradient(270deg, #101123 0%, #1C1D31 100%)",
  },
  layoutBodyLeft: {
    alignItems: "left",
    marginTop: theme.spacing.unit * 20,
    marginBottom: theme.spacing.unit * 20,
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing.unit * 8,
      marginBottom: theme.spacing.unit * 8,
    },
  },
  grid: {
    flexGrow: 1,
  },
  title: {
    marginBottom: theme.spacing.unit * 2,
  },
  subtitle: {
    marginBottom: theme.spacing.unit * 4,
  },
  subtext: {
    marginBottom: theme.spacing.unit * 2,
  },
  link: {
    color: theme.palette.text.primary,
    width: "100%",
  },
  linkContainer: {
    marginTop: theme.spacing.unit * 6,
  },
  preTitle: {
    color: theme.palette.primary.main,
  },
  mobileContainerFix: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: 24,
    },
  },
  img: {
    width: 64,
    height: 64,
    align: "center",
  },
  bodySpacing: {
    paddingTop: "4px !important",
  },
});

class MainSetup extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <section className={classes.root}>
        <LayoutBody className={classes.layoutBodyLeft}>
          <Grid container spacing={3} alignContent="center" alignItems="center">
            <Grid item xs={12}>
              <Typography
                variant="subtitle2"
                align="left"
                className={classes.preTitle}
              >
                Simple Device setup
              </Typography>
              <Typography variant="h2" align="left" className={classes.title}>
                Get started in minutes
              </Typography>
              <Typography
                variant="body1"
                align="left"
                className={classes.subtitle}
              >
                Visualization solutions do not have to be expensive and complex
                to setup. Just install our Android App, login and get started
                immediatelly. No additional setup necessary.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                spacing={3}
                alignContent="center"
                alignItems="top"
              >
                <Grid
                  item
                  xs={12}
                  md={4}
                  className={classes.mobileContainerFix}
                >
                  <Grid
                    container
                    spacing={3}
                    alignContent="center"
                    alignItems="center"
                    className={classes.mobileContainerFix}
                  >
                    <Grid item xs={12}>
                      <img
                        src={setupDownload}
                        alt="Setup - Download"
                        className={classes.img}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h4" align="left">
                        Install App
                      </Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.bodySpacing}>
                      <Typography variant="body1" align="left">
                        Install our Android App on your favorite Android device.
                        We support Android TVs, tablets and even FireTV sticks.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Grid
                    container
                    spacing={3}
                    alignContent="center"
                    alignItems="center"
                    className={classes.mobileContainerFix}
                  >
                    <Grid item xs={12}>
                      <img
                        src={setupLogin}
                        alt="Setup - Login"
                        className={classes.img}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h4" align="left">
                        Login
                      </Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.bodySpacing}>
                      <Typography variant="body1" align="left">
                        Start the app and log in with your easyvis.io
                        credentials. Thats's it. Your device is ready for
                        playback.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Grid
                    container
                    spacing={3}
                    alignContent="center"
                    alignItems="center"
                    className={classes.mobileContainerFix}
                  >
                    <Grid item xs={12}>
                      <img
                        src={setupPlay}
                        alt="Setup - Play"
                        className={classes.img}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h4" align="left">
                        Play
                      </Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.bodySpacing}>
                      <Typography variant="body1" align="left">
                        Login to our platform, select your digital content and
                        press play. Or control your visualization devices via
                        our API.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Typography align="center" className={classes.linkContainer}>
                <Link
                  href="http://developer.easyvis.io/docs/how-to-get-started/"
                  className={classes.link}
                  underline="always"
                >
                  Check the setup instructions
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </LayoutBody>
      </section>
    );
  }
}

export default withStyles(styles)(MainSetup);
