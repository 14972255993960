import React, { Component } from "react";
import ClockApp from "./ClockApp";
import StreamApp from "./StreamApp";
import ImageApp from "./ImageApp";
import TextApp from "./TextApp";
import ProgressApp from "./ProgressApp";
import BlinkApp from "./BlinkApp";
import PulseApp from "./PulseApp";
import TwinkleApp from "./TwinkleApp";
import ColorApp from "./ColorApp";
import DimmerApp from "./DimmerApp";
import ManualAPITester from "./ManualAPITester";
import TestStreams from "./TestStreams";
import ShapesApp from "./ShapesApp";
import WebsiteApp from "./WebsiteApp";
import PlaylistApp from "./PlaylistApp";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import PlayIcon from "../../../../common/icons/PlayIcon";
import { connect } from "react-redux";
import { playPlayer, stopPlayer, setPlayer } from "../../actions";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { getAppInfo } from "../AppList.js";
import StopIcon from "../../../../common/icons/StopIcon";
import PlayerSelector from "../PlayerSelector";
import Media from "react-media";

const styles = (theme) => ({
  header: {
    display: "flex",
  },
  info: {
    color: theme.palette.primary.light,
    marginLeft: "auto",
  },
  config: {
    color: theme.palette.primary.light,
  },
  paper: {
    padding: "20px",
  },
  mainComponent: {
    marginTop: theme.spacing(3),
  },
  stopContainerBottomDesktop: {
    margin: 0,
    top: "auto",
    right: 20,
    bottom: 60,
    left: "auto",
    position: "fixed",
  },
  stopContainerBottomMobile: {
    margin: 0,
    top: "auto",
    right: 20,
    bottom: 20,
    left: "auto",
    position: "fixed",
  },
});

class App extends Component {
  appsConfig = {};

  constructor() {
    super();
    this.appsConfig = {
      clock: {
        configComponent: <ClockApp onConfigChange={this.onConfigChange} />,
      },
      stream: {
        configComponent: <StreamApp onConfigChange={this.onConfigChange} />,
      },
      image: {
        configComponent: <ImageApp onConfigChange={this.onConfigChange} />,
      },
      text: {
        configComponent: <TextApp onConfigChange={this.onConfigChange} />,
      },
      progress: {
        configComponent: <ProgressApp onConfigChange={this.onConfigChange} />,
      },
      blink: {
        configComponent: <BlinkApp onConfigChange={this.onConfigChange} />,
      },
      pulse: {
        configComponent: <PulseApp onConfigChange={this.onConfigChange} />,
      },
      twinkle: {
        configComponent: <TwinkleApp onConfigChange={this.onConfigChange} />,
      },
      color: {
        configComponent: <ColorApp onConfigChange={this.onConfigChange} />,
      },
      dimmer: {
        configComponent: <DimmerApp onConfigChange={this.onConfigChange} />,
      },
      website: {
        configComponent: <WebsiteApp onConfigChange={this.onConfigChange} />,
      },
      manual: {
        configComponent: (
          <ManualAPITester onConfigChange={this.onConfigChange} />
        ),
      },
      shapes: {
        configComponent: <ShapesApp onConfigChange={this.onConfigChange} />,
      },
      teststreams: {
        configComponent: <TestStreams />,
        ownPlayButton: true,
      },
      playlists: {
        configComponent: <PlaylistApp />,
        ownPlayButton: true,
      },
    };
  }

  handlePlayerChange = (player) => {
    this.props.setPlayer(player);
  };

  stopPlayer = () => {
    const player = this.props.selectedPlayer;
    this.props.stopPlayer(player);
  };

  onConfigChange = (config) => {
    this.setState({ config: config });
  };

  play = () => {
    let type = this.props.type;
    let config = this.state.config;
    if (this.props.type === "manual") {
      type = this.state.config.type;
      config = JSON.parse(this.state.config.config);
    }
    this.props.playPlayer(
      this.props.selectedPlayer,
      type,
      config,
      this.props.playerConfig
    );
  };

  openInfoLink = () => {
    const appInfo = getAppInfo(this.props.type);
    window.open(appInfo.infoLink);
  };

  render() {
    const { classes, type } = this.props;
    const appInfo = getAppInfo(this.props.type);
    const playButton = this.appsConfig[type].ownPlayButton ? (
      ""
    ) : (
      <Grid item xs={12}>
        <PlayIcon aria-label="Play" onClick={this.play} size="medium" />
      </Grid>
    );
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PlayerSelector onPlayerChange={this.handlePlayerChange} />
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Grid container spacing={3}>
              <Grid item xs={12} className={classes.header}>
                <Typography variant="h4">{appInfo.name}</Typography>
                <IconButton
                  onClick={this.openInfoLink}
                  className={classes.info}
                >
                  <InfoIcon />
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2">
                  {appInfo.description}
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.mainComponent}>
                {this.appsConfig[type].configComponent}
              </Grid>
              {playButton}
            </Grid>
          </Paper>
        </Grid>
        <Media query="(max-width: 599px)">
          {(matches) =>
            matches ? (
              <div className={classes.stopContainerBottomDesktop}>
                <StopIcon size="medium" onClick={this.stopPlayer} />
              </div>
            ) : (
              <div className={classes.stopContainerBottomMobile}>
                <StopIcon size="medium" onClick={this.stopPlayer} />
              </div>
            )
          }
        </Media>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedPlayer: state.players.selectedPlayer,
  playerConfig: state.players.playerConfig,
});

const mapDispatchToProps = (dispatch) => ({
  playPlayer: (player, type, config, playConfig) =>
    dispatch(playPlayer(player, type, config, playConfig)),
  setPlayer: (player) => dispatch(setPlayer(player)),
  stopPlayer: (player) => dispatch(stopPlayer(player)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(App)
);
