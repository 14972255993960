import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 220,
  },
});

class DimmerApp extends Component {
  // the state represents the config of the widget
  state = {
    values: [0, 0, 0],
  };

  componentDidMount() {
    // report initial state
    this.setStateCallback();
  }

  handleValueChange = (event) => {
    var valuesTmp = [event.target.value, 0, 0];
    this.setState({ values: valuesTmp }, this.setStateCallback);
  };

  setStateCallback = () => {
    this.props.onConfigChange(this.state);
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <form className={classes.root} autoComplete="off">
          <FormControl className={classes.formControl}>
            <TextField
              id="value1"
              name="value1"
              label="Value (0-255)"
              value={this.state.values[0]}
              onChange={this.handleValueChange}
              fullWidth
            />
          </FormControl>
        </form>
      </div>
    );
  }
}

export default withStyles(styles)(DimmerApp);
