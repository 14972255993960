import * as t from './actionTypes';
import { CALL_API } from '../../common/middleware/api'

const getUserInformationAPI = () => ({
  [CALL_API]: {
    types: [ t.GET_USER_REQUEST, t.GET_USER_SUCCESS, t.GET_USER_FAILURE ],
    apiName: 'UserAPI',
		path: '/user',
    method: 'get',
		myInit: {
			headers: {},
			response: true
		}
  }
})

// Fetches a all players
export const getUserInformation = () => (dispatch, getState) => {
  return dispatch(getUserInformationAPI())
}
