import * as t from "./actionTypes";
import { CALL_API, apiCall } from "../../common/middleware/api";

const registerDeviceAPI = (deviceId, deviceLabel) => ({
  [CALL_API]: {
    types: [
      t.REGISTER_DEVICE_REQUEST,
      t.REGISTER_DEVICE_SUCCESS,
      t.REGISTER_DEVICE_FAILURE,
    ],
    apiName: "PlayerAPI",
    path: "/devices/",
    method: "post",
    myInit: {
      headers: {},
      body: {
        id: deviceId,
        label: deviceLabel,
      },
      response: true,
    },
  },
});

// Register a new device
export const registerDevice = (deviceId, deviceLabel) => (
  dispatch,
  getState
) => {
  return dispatch(registerDeviceAPI(deviceId, deviceLabel));
};

export function addDevice(device) {
  return { type: t.ADD_DEVICE, device };
}

const updateDeviceAPI = (id, label, generalOutputConfiguration, outputs) => {
  let apiName = "PlayerAPI";
  let path = "/devices/" + id;
  let myInit = {
    headers: {},
    body: {
      id: id,
      label: label,
      configuration: {
        generalOutputConfiguration: generalOutputConfiguration,
        outputs: outputs,
      },
    },
    response: true,
  };
  return apiCall("put", apiName, path, myInit);
};

// Updates a certain device with a new configuration
export const updateDevice = (
  id,
  label,
  generalOutputConfiguration,
  outputs
) => (dispatch, getState) => {
  dispatch({ type: t.UPDATE_DEVICE_REQUEST });
  return (
    updateDeviceAPI(id, label, generalOutputConfiguration, outputs).then(
      (response) => {
        console.log("Update successful, fetching devices again");
        dispatch({ response, type: t.UPDATE_DEVICE_SUCCESS, deviceId: id });
        dispatch(getDevice());
      }
    ),
    (error) => {
      console.log("Error happened during update");
      dispatch({ error, type: t.UPDATE_DEVICE_FAILURE });
    }
  );
};

export function enterDeviceUpdateMode(device) {
  return { type: t.ENTER_DEVICE_UPDATE_MODE, device };
}

export function leaveDeviceUpdateMode(device) {
  return { type: t.LEAVE_DEVICE_UPDATE_MODE, device };
}

export function invalidateDevices() {
  return { type: t.INVALIDATE_DEVICES };
}

const getDevice = () => ({
  [CALL_API]: {
    types: [t.GET_DEVICE_REQUEST, t.GET_DEVICE_SUCCESS, t.GET_DEVICE_FAILURE],
    apiName: "PlayerAPI",
    path: "/devices",
    method: "get",
    myInit: {
      headers: {},
      response: true,
    },
    nextAPICall: loadDevices,
  },
});

// Fetches a all devices
export const loadDevices = () => (dispatch, getState) => {
  return dispatch(getDevice());
};

const deleteDeviceAPI = (id) => ({
  [CALL_API]: {
    types: [
      t.DELETE_DEVICE_REQUEST,
      t.DELETE_DEVICE_SUCCESS,
      t.DELETE_DEVICE_FAILURE,
    ],
    apiName: "PlayerAPI",
    path: "/devices/" + id,
    method: "delete",
    myInit: {
      headers: {},
      body: {},
      response: true,
    },
    context: {
      deviceId: id,
    },
  },
});

// Updates a certain device with a new configuration
export const deleteDevice = (id) => (dispatch, getState) => {
  return dispatch(deleteDeviceAPI(id));
};

export function setSelectedDevice(deviceId) {
  return { type: t.SET_SELECTED_DEVICE, deviceId };
}

// Sets a certain device in configuration
export function setDeviceInConfiguration(deviceId) {
  return { type: t.SET_DEVICE_IN_CONFIGURATION, deviceId };
}

// Deletes an output from the device configuration
export function deleteOutputInDeviceConfiguration(outputId) {
  return { type: t.DELETE_OUTPUT_IN_DEVICE_CONFIGURATION, outputId };
}

// Updates an output from the device configuration
export function updateOutputInDeviceConfiguration(originalOutputId, output) {
  return {
    type: t.UPDATE_OUTPUT_IN_DEVICE_CONFIGURATION,
    originalOutputId,
    output,
  };
}

// Add a new output to the device configuration
export function addOutputInDeviceConfiguration(output) {
  return {
    type: t.ADD_OUTPUT_IN_DEVICE_CONFIGURATION,
    output,
  };
}

// Sets a certain output in configuration
export function setOutputInConfiguration(output) {
  return { type: t.SET_OUTPUT_IN_CONFIGURATION, output };
}
