import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import easyvisLogoLong from "../images/logoLongDark.svg";
import { theme } from "../../AppThemeMain.js";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import CssBaseline from "@material-ui/core/CssBaseline";
import ReactMarkdown from "react-markdown";
import terms from "../terms/terms.md";

const styles = (theme) => ({
  root: {
    margin: theme.spacing.unit * 7,
    arginBottom: theme.spacing.unit * 12,
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    height: "100%",
    width: "auto",
  },
  grid: {
    width: "100%",
  },
  text: {
    margin: theme.spacing.unit * 3,
  },
});

class TermsOfUse extends Component {
  constructor() {
    super();
    this.state = { terms: null };
  }

  componentWillMount() {
    fetch(terms)
      .then((response) => response.text())
      .then((text) => {
        this.setState({ terms: text });
      });
  }

  render() {
    const { classes } = this.props;
    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <div className={classes.root}>
          <Grid
            container
            spacing={3}
            alignContent="center"
            alignItems="center"
            className={classes.grid}
          >
            <Grid item xs={12} align="left">
              <img src={easyvisLogoLong} alt="easyvis logo" height="40" />
            </Grid>
            <Grid item xs={12} align="cenrter">
              <Typography align="center" variant="h4">
                Terms of Use
              </Typography>
            </Grid>
            <Grid item xs={12} align="cenrter">
              <Typography className={classes.text}>
                Last Updated: 22.03.2021
              </Typography>
            </Grid>
            <Grid item xs={12} align="cenrter">
              <Typography className={classes.text}>
                Please read these terms of service ("Terms" or "Terms of
                Service") before using the easyvis.io service and website
                ("easyvis.io") provided by smartdings GmbH ("smartdings", "we",
                "us", or "our"). Your access to and use of the easyvis.io
                website and service ("Service") is conditioned upon your
                acceptance of and compliance with these Terms. These Terms apply
                to all visitors, users and others who wish to access or use the
                easyvis.io Service. You agree to be bound by these Terms by
                using or accessing the easyvis.io Service. You may not use the
                easyvis.io Service, if you do not agree with the Terms in whole
                or any part. Your usage of the Service assumes at you fully
                understand and agree to the Terms.
              </Typography>
              <Typography variant="h4" className={classes.text}>
                Use of the Service
              </Typography>
              <Typography className={classes.text}>
                You are required to open an account (“User Account”) by
                registering with easyvis.io in order to access and use the
                Service. By creating an User Account on easyvis.io, you
                guarantee that you are older than 18, and that all information
                provided by you is true, accurate, complete, and current. You
                agree to update such information to ensure that it is current at
                all times. Smartdings may terminate your account on the Service,
                if the information provided by you is inaccurate, incomplete, or
                obsolete. Smartdings grants you, upon proper registration and
                opening of an User Account (and subject to the terms and
                conditions of these Terms), a personal, non-transferable right
                and license to use the Service. You can use our Service until
                either you or we elect to terminate the Service in accordance
                with these Terms. You agree to accept responsibility for
                maintaining the confidentiality of your User Account and
                password. This includes but is not limited to the restriction of
                access to your computer and/or User Account. You are responsible
                for any and all activities/actions that occur under your User
                Account and/or password (whether your password is for our
                Service or a third-party service). If you become aware of any
                breach of security or unauthorized use of your User Account, you
                must inform us immediately. You agree to accept the
                responsibility for and retain ownership of any data,
                information, or material originated or transmitted by you
                through the Service (“User Data”). This includes User Data from
                your accounts with third party services which passes through the
                Service. You are solely responsible for the accuracy, quality,
                content and legality of User Data and the methods by which you
                acquired this Data. You represent and warrant that you have all
                rights necessary to transmit User Data through the Service and
                that your use of the Services is compliant with all applicable
                law. You agree not to, directly or indirectly: - reverse
                engineer, decompile or otherwise attempt to discover the source
                code or underlying structure/ideas of the Service; - modify or
                create derivative works based on the Service; - use or access
                the Service to build, support or assist a third party in
                building or supporting, products or services competitive to
                easyvis.io; - remove any proprietary notices or labels from the
                Service; - use the Service outside of the scope of the rights
                expressly granted herein. You agree to use the Service only for
                your own internal operations, and not to transfer, distribute,
                sell, republish, resell, lease, sublease, license, sub-license
                or assign the Service. The easyvis.io device software is
                intended for use on the Raspberry Pi® only. Raspberry Pi® is a
                registered trademark of the Raspberry Pi® Foundation. We are not
                affiliated with Raspberry Pi® in any fashion.
              </Typography>
              <Typography variant="h4" className={classes.text}>
                Termination
              </Typography>
              <Typography className={classes.text}>
                We may terminate or suspend your account and access to the
                Service immediately, without prior notice or liability, under
                our sole discretion, for any reason whatsoever and without
                limitation, including but not limited to a breach of these
                Terms. If you wish to terminate your account, you can contact us
                (info@easyvis.io). If your User Account is terminated, we may
                permanently delete your User Account and any or all User Data
                associated with it. All provisions of the Terms which by their
                nature should survive termination shall survive termination,
                including, without limitation, ownership provisions, warranty
                disclaimers, indemnity and limitations of liability.
              </Typography>
              <Typography variant="h4" className={classes.text}>
                Warranty Disclaimer
              </Typography>
              <Typography className={classes.text}>
                The Service is provided on an "AS IS" and "AS AVAILABLE" basis.
                The use of the Service is at your own risk. The Service is
                provided without warranties of any kind, whether express or
                implied, including, but not limited to, implied warranties of
                merchantability, fitness for a particular purpose,
                non-infringement or course of performance. smartdings expressly
                disclaim any warranty that - the Service will function secure,
                uninterrupted or available at any particular time or location; -
                any errors or defects will be corrected; - the results of using
                the Service will not cause any loss or damage to your data; -
                the Service meets your requirements. - the Service is free of
                viruses or other harmful components; - your sensitive data will
                remain secure at all times
              </Typography>
              <Typography variant="h4" className={classes.text}>
                Limitation of Liability
              </Typography>
              <Typography className={classes.text}>
                In no event shall smartdings, nor its directors, employees,
                suppliers, partners, or affiliates, be liable for any
                incidental, special, indirect, punitive or consequential
                damages, including without limitation, loss of data, goodwill,
                use, profits, or other intangible losses, resulting from - your
                access to, or use of the Service; - your inability to access the
                Service; - any content obtained from the Service; - any content
                or conduct of any third party on the Service; - the setup,
                installation, usage or maintenance of your visualisation device
                (e.g. LED wall or TV); - the setup, installation, usage or
                maintenance of your Raspberry Pi® with the easyvis.io software;
                - unauthorized access, any loss or damage to data, use or
                alteration of your transmissions or content.
              </Typography>
              <Typography variant="h4" className={classes.text}>
                Privacy Policy
              </Typography>
              <Typography className={classes.text}>
                You acknowledge that you have read, understand and agree to
                easyvis.io privacy policy located at https://easyvis.io/privacy,
                which is hereby incorporated into and made a part of these Terms
                of Service.
              </Typography>
              <Typography variant="h4" className={classes.text}>
                Indemnification
              </Typography>
              <Typography className={classes.text}>
                You agree to defend, indemnify and hold harmless smartdings and
                its licensee and licensors, and their employees, contractors,
                agents, officers and directors, from and against any and all
                claims, damages, obligations, losses, liabilities, costs or
                debt, and expenses (including but not limited to attorney’s
                fees), resulting from or arising out of your use and access of
                the Service, by you or any person using your account and
                password, or a breach of these Terms.
              </Typography>
              <Typography variant="h4" className={classes.text}>
                Links to Other Web Sites
              </Typography>
              <Typography className={classes.text}>
                Our Service may contain links to third-party web sites or
                services that are not owned or controlled by smartdings. We have
                no control over, and assume no responsibility for the content,
                privacy policies, or practices of any third-party web sites or
                services. We do not warrant the offerings of any of these
                entities/individuals or their websites. You acknowledge and
                agree that smartdings shall not be responsible or liable,
                directly or indirectly, for any damage or loss caused or alleged
                to be caused by or in connection with use of or reliance on any
                such content, goods or services available on or through any such
                third-party web sites or services.
              </Typography>
              <Typography variant="h4" className={classes.text}>
                Reservation of Rights
              </Typography>
              <Typography className={classes.text}>
                Subject to the limited rights expressly granted hereunder, we
                reserve all of our rights, title and interest in and to the
                Services and any information obtained by us from publicly
                available sources or third-parties and made available to you
                through the Services, including all of our related intellectual
                property rights. No rights are granted to you hereunder other
                than as expressly set forth herein.
              </Typography>
              <Typography variant="h4" className={classes.text}>
                Governing Laws
              </Typography>
              <Typography className={classes.text}>
                These Terms shall be governed by and construed in accordance
                with the laws of the state Baden-Württemberg, Germany regardless
                of the laws that might otherwise govern under applicable
                principles of conflicts of laws. Our failure to enforce any
                right or provision of these Terms will not be considered a
                waiver of those rights. If any provision of these Terms is held
                to be invalid or unenforceable by a court, the remaining
                provisions of these Terms will remain in effect. These Terms
                constitute the entire agreement between us regarding our Service
                and supersede and replace any prior agreements we might have had
                between us regarding the Service.
              </Typography>
              <Typography variant="h4" className={classes.text}>
                Changes
              </Typography>
              <Typography className={classes.text}>
                We expressly reserves the right to modify the Terms of Service
                at any time in its sole discretion by including such alteration
                and/or modification in these Terms of Service, along with a
                notice of the effective date of such modified Terms of Service
              </Typography>
              <Typography variant="h4" className={classes.text}>
                Contact us
              </Typography>
              <Typography className={classes.text}>
                If you have any questions about these Terms, please contact us
                at info@easyvis.io
              </Typography>
            </Grid>
          </Grid>
        </div>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(TermsOfUse);
