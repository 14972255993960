import React from "react";
import { withStyles } from "@material-ui/core/styles";
import LayoutBody from "./MainLayoutBody";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import codeSnippet from "../images/api_example.png";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";

const styles = (theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    backgroundColor: theme.main.backgroundLight,
    backgroundImage: "linear-gradient(90deg, #101123 0%, #1C1D31 100%)",
  },
  layoutBodyLeft: {
    marginTop: theme.spacing.unit * 20,
    marginBottom: theme.spacing.unit * 20,
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing.unit * 8,
      marginBottom: theme.spacing.unit * 8,
    },
  },
  grid: {
    flexGrow: 1,
  },
  title: {
    marginBottom: theme.spacing.unit * 2,
    textShadow: "1px 2px 10px rgba(0,0,0,.2)",
  },
  subtitle: {
    marginBottom: theme.spacing.unit * 4,
    textShadow: "1px 1px 4px rgba(0,0,0,.6)",
  },
  subtext: {
    marginBottom: theme.spacing.unit * 2,
    maxWidth: 350,
  },
  link: {
    color: theme.palette.text.primary,
    width: "100%",
  },
  preTitle: {
    color: theme.palette.thirdly.main,
  },
  img: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  card: {
    backgroundColor: theme.main.backgroundLight,
  },
  cardActions: {
    align: "center",
    justifyContent: "center",
  },
});

class MainInputOutput extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <section className={classes.root}>
        <LayoutBody className={classes.layoutBodyLeft}>
          <Grid container spacing={3} alignContent="center" alignItems="center">
            <Grid item xs={12} md={12}>
              <Typography
                variant="subtitle2"
                align="left"
                className={classes.preTitle}
              >
                Visualization-as-a-service
              </Typography>
              <Typography variant="h2" align="left" className={classes.title}>
                Built it yourself or let us do the job
              </Typography>
              <Typography
                variant="body1"
                align="left"
                className={classes.subtitle}
              >
                You can easily build your own creative solution by using our
                open API and just control it from wherever you like. If you are
                not crazy about programming, let us do the job for you. Our
                experienced development team will support you.
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} align="center">
              <div>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={6}>
                    <Card className={classes.card}>
                      <CardHeader
                        title="Developer"
                        titleTypographyProps={{ align: "center" }}
                        subheaderTypographyProps={{ align: "center" }}
                        className={classes.cardHeader}
                      />
                      <CardContent>
                        <Typography variant="body1" align="center">
                          With our free to use API you can connect other digital
                          services and devices to create completely new
                          visualization solutions. With our Zapier integration
                          you can easily connect thousand of other services.
                        </Typography>
                        <img
                          src={codeSnippet}
                          alt="Code snippet on how to integrate easyvis.io"
                          className={classes.img}
                        />
                      </CardContent>
                      <CardActions className={classes.cardActions}>
                        <Typography align="center">
                          <Link
                            href="http://developer.easyvis.io/docs/api-reference/"
                            className={classes.link}
                            underline="always"
                          >
                            Explore our API documentation
                          </Link>
                        </Typography>
                      </CardActions>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Card className={classes.card}>
                      <CardHeader
                        title="Business"
                        titleTypographyProps={{ align: "center" }}
                        subheaderTypographyProps={{ align: "center" }}
                        className={classes.cardHeader}
                      />
                      <CardContent>
                        <Typography variant="body1" align="center">
                          We support you in the process of building your digital
                          visualization solution. With our experienced
                          development team we can quickly build up your
                          visualization solution.
                        </Typography>
                      </CardContent>
                      <CardActions className={classes.cardActions}>
                        <Typography align="center">
                          <Link
                            href="https://www.smartdings.com"
                            className={classes.link}
                            underline="always"
                            align="center"
                          >
                            Explore our development services
                          </Link>
                        </Typography>
                      </CardActions>
                    </Card>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </LayoutBody>
      </section>
    );
  }
}

export default withStyles(styles)(MainInputOutput);
