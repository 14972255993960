import { Component } from 'react';
import { withSnackbar } from 'notistack';
import {connect} from "react-redux";
import { removeNotification } from '../actions'


/*
  There are multiple ways to trigger a notification in CAVI.
  1. Directly via this.props.enqueueSnackbar (in this case this Notifier is skipped)
  2. Via a redux action: addNotification(message, variant)
  3. By adding an error, info, success or warning field to a a redux action (compare API calls)
  Allowed variants: error, success, info, warning
*/
class Notifier extends Component {
  displayed = [];

  storeDisplayed = (id) => {
      this.displayed = [...this.displayed, id];
  };

  componentDidUpdate() {
      const { notifications } = this.props;

      notifications.notifications.forEach((notification) => {
          // Do nothing if snackbar is already displayed
          if (this.displayed.includes(notification.key)) return;
          // Display snackbar using notistack
          this.props.enqueueSnackbar(notification.message, {variant: notification.variant});
          // Keep track of snackbars that we've displayed
          this.storeDisplayed(notification.key);
          // Dispatch action to remove snackbar from redux store
          this.props.removeNotification(notification.key);
      });
    }

    render() {
        return null;
    }
}

const mapStateToProps = state => ({
  notifications: state.notifications
})

const mapDispatchToProps = dispatch => ({
  removeNotification: (key) => dispatch(removeNotification(key)),

})

export default withSnackbar(connect(
  mapStateToProps,
  mapDispatchToProps
)(Notifier));
