import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import DoneTwoToneIcon from '@material-ui/icons/DoneTwoTone';
import Typography from '@material-ui/core/Typography';
import RadioButtonUncheckedTwoToneIcon from '@material-ui/icons/AccessTime';


const styles = theme => ({
  icon: {
    float: "left",
    marginRight: theme.spacing.unit * 2
  }
});


class MainCheck extends Component {

	render () {
		const { classes, text, color, comingSoon } = this.props;
    const icon = comingSoon ? <RadioButtonUncheckedTwoToneIcon color={color} className={classes.icon} fontSize="24"/>
      : <DoneTwoToneIcon color={color} className={classes.icon}/>;
		return (
      <div>
        {icon}
        <Typography variant="body2" align="left">
          {text}
        </Typography>
      </div>
    );
	}
}

export default withStyles(styles)(MainCheck);
