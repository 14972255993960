import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import devices from './console/device/reducer.js'
import authentication from './common/auth/reducer.js'
import { players } from './console/player/reducer.js'
import { media } from './console/media/reducer.js'
import { user } from './console/user/reducer.js'
import { kitchen } from './example-kitchen/reducer.js'
import { notifications } from './common/notifications/reducer.js'
import { requestAccount } from './main/reducer.js'
import { watches } from './example-shelves/reducer.js'


const caviApp = combineReducers({
  devices,
  players,
  media,
  kitchen,
  notifications,
  authentication,
  requestAccount,
  user,
  watches,
  form: formReducer
})

export default caviApp
