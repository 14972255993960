import React, { Component } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import { playPlayer } from '../actions'


import tikkaMasalaImage from '../recipes-images/tikka-masala.jpg';
import sushiImage from '../recipes-images/sushi.jpg';


const styles = theme => ({
  cardPlay: {
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  card: {
    margin: theme.spacing.unit
  },
  playIcon: {
  },
  media: {
    height: 140,
  },
});

const recipesData = [
   {
     title: 'Chicken Tikka Masala',
     description: 'Homemade authentic chicken tikka masala dish',
     image: tikkaMasalaImage,
     incredients: [
       '1/4 cup plain yogurt',
       '2 teaspoons garam masala',
       '2 teaspoons paprika',
       '1/2 teaspoon freshly ground black pepper',
       '...'
     ],
     steps: [
       '1 - Combine yogurt, 2 teaspoons garam masala and paprika',
       '2 - Preheat oven to about 450 degrees F',
       '3 - Place the chicken on the prepared baking sheet',
       '...'
     ]
   },
   {
     title: 'Salmon Sushi Roll',
     description: 'Basic but delicious salmon sushi',
     image: sushiImage,
     incredients: [
       '2 cups Japanese sushi rice',
       '6 tablespoons rice wine vinegar',
       '6 sheets nori (dry seaweed)',
       '1 avocado - peeled, pitted and sliced',
       '8 ounces smoked salmon, cut into long strips',
     ],
     steps: [
       '1 - Soak rice for 4 hours. Drain rice and cook in a rice cooker with 2 cups of water',
       '2 - After rice is cooked, mix 6 tablespoons rice vinegar to the hot rice',
       '3 - ...',
       '...'
     ]
   },
];


class Recipes extends Component {

  state = {
    expanded: [],
    recipePlayState: []
  };


  handleExpandClick = (id) => {
    const { expanded } = this.state;
    if (typeof expanded[id] === 'undefined') {
      expanded[id] = true;
    } else {
      expanded[id] = !expanded[id];
    }
    this.setState(expanded);
  }

  startRecipe = (id) => {
    const { recipePlayState } = this.state;
    recipePlayState[id] = 0;
    this.setState(recipePlayState);
    this.playRecipeStep(id);
  }

  nextRecipeStep = (id) => {
    const { recipePlayState } = this.state;
    if (typeof recipePlayState[id] === 'undefined') {
      recipePlayState[id] = 0;
    } else {
      if (recipePlayState[id] < recipesData[id].steps.length-1) {
        recipePlayState[id] = recipePlayState[id] + 1;
      }
    }
    this.setState(recipePlayState);
    this.playRecipeStep(id);
  }

  previousRecipeStep = (id) => {
    const { recipePlayState } = this.state;
    if (typeof recipePlayState[id] === 'undefined') {
      recipePlayState[id] = 0;
    } else {
      if (recipePlayState[id] > 0) {
        recipePlayState[id] = recipePlayState[id] - 1;
      }
    }
    this.setState(recipePlayState);
    this.playRecipeStep(id);
  }

  playRecipeStep = (recipeId) => {
    const player = this.props.selectedPlayer;
    const config = {
      input: recipesData[recipeId].steps[this.state.recipePlayState[recipeId]],
      type: 'scroll',
      horizontalAlignment: 'right',
      verticalAlignment: 'middle',
      speed: 'fast'
    };
    this.props.playPlayer(player, "text", config);
  }

	render () {
		const { classes } = this.props;
    const { expanded } = this.state;
		return (
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">
              Recipes
            </Typography>
          </Grid>
          <Grid item xs={12}>

            {recipesData.map((recipe, index) => (
              <Card className={classes.card} key={index}>
                <CardHeader
                  title={recipe.title}
                />
                <CardMedia
                  className={classes.media}
                  image={recipe.image}
                />
                <CardContent>
                  <Typography variant="body2" color="textSecondary" component="p">
                    {recipe.description}
                  </Typography>
                </CardContent>
                <CardActions>
                  <IconButton onClick={() => this.previousRecipeStep(index)}>
                    <SkipPreviousIcon color="primary" className={classes.playIcon}/>
                  </IconButton>
                  <IconButton onClick={() => this.startRecipe(index)}>
                    <PlayArrowIcon color="primary" className={classes.playIcon}/>
                  </IconButton>
                  <IconButton onClick={() => this.nextRecipeStep(index)}>
                    <SkipNextIcon color="primary" className={classes.playIcon} />
                  </IconButton>
                  <IconButton
                    className={clsx(classes.expand, {
                      [classes.expandOpen]: expanded[index],
                    })}
                    onClick={() => this.handleExpandClick(index)}
                    aria-expanded={expanded}
                    aria-label="Show more"
                  >
                    <ExpandMoreIcon />
                  </IconButton>
                </CardActions>
                <Collapse in={expanded[index]} timeout="auto" unmountOnExit>
                  <CardContent>
                    <Typography paragraph>Incredients:</Typography>
                    {recipe.incredients.map((incr, index) => (
                      <Typography key={index}>
                        {incr}
                      </Typography>
                    ))}
                  </CardContent>
                  <CardContent>
                    <Typography paragraph>Steps:</Typography>
                    {recipe.steps.map((incr, index) => (
                      <Typography key={index}>
                        {incr}
                      </Typography>
                    ))}
                  </CardContent>
                </Collapse>
              </Card>
            ))}
          </Grid>
        </Grid>
      </div>
    );
	}
}

const mapStateToProps = state => ({
    selectedPlayer: state.kitchen.selectedPlayer,
})

const mapDispatchToProps = dispatch => ({
    playPlayer: (player, type, config) => dispatch(playPlayer(player, type, config)),
})

export default withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps
)(Recipes))
