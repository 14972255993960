import React from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import LayoutBody from "./MainLayoutBody";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import easyvisLogoLong from "../images/logoLongDark.svg";
import easyvisLogoShort from "../images/logoShortDark.svg";
import Media from "react-media";

const styles = (theme) => ({
  root: {
    width: "auto",
    overflow: "hidden",
    backgroundColor: theme.main.backgroundLight,
    borderTopStyle: "solid",
    borderWidth: "1px",
    borderColor: "#454C5B",
  },
  layoutBody: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    display: "flex",
  },
  img: {
    marginTop: theme.spacing.unit * 1,
  },
  link: {
    marginRight: theme.spacing.unit * 3,
    color: theme.palette.text.primary,
  },
  lastLink: {
    color: theme.palette.text.primary,
  },
  preTitle: {
    marginBottom: theme.spacing.unit * 2,
    color: theme.palette.thirdly.main,
  },
});

class MainFooter extends React.Component {
  handleTermsClick = (event) => {
    this.props.history.push("/terms");
  };

  handlePrivacyClick = (event) => {
    this.props.history.push("/privacy");
  };

  handleAboutClick = (event) => {
    this.props.history.push("/about");
  };

  render() {
    const { classes } = this.props;
    return (
      <section className={classes.root}>
        <LayoutBody className={classes.layoutBody}>
          <Grid container alignItems="center">
            <Grid item md={4} xs={2}>
              <Media query="(max-width: 599px)">
                {(matches) =>
                  matches ? (
                    <img
                      src={easyvisLogoShort}
                      alt="easyvis logo"
                      height="30"
                      className={classes.img}
                    />
                  ) : (
                    <img
                      src={easyvisLogoLong}
                      alt="easyvis logo"
                      height="30"
                      className={classes.img}
                    />
                  )
                }
              </Media>
            </Grid>
            <Grid item md={8} xs={10} align="right">
              <Link
                onClick={this.handleAboutClick}
                variant="body1"
                className={classes.link}
              >
                About us
              </Link>
              <Link
                onClick={this.handleTermsClick}
                variant="body1"
                className={classes.link}
              >
                Terms
              </Link>
              <Link
                onClick={this.handlePrivacyClick}
                variant="body1"
                className={classes.lastLink}
              >
                Privacy
              </Link>
            </Grid>
          </Grid>
        </LayoutBody>
      </section>
    );
  }
}

export default withStyles(styles)(withRouter(MainFooter));
