import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { closeRequestAccount } from "../actions.js";
import { connect } from "react-redux";
import { renderField, email } from "../../common/Utilities";
import { Field, reduxForm } from "redux-form";
import { required } from "redux-form-validators";
import {
  ENDPOINT_PROD_USER,
  ENDPOINT_DEV_USER,
} from "../../common/middleware/api";
import Loading from "../../common/Loading.js";
import { addNotification } from "../../common/notifications/actions";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  textField: {
    marginBottom: theme.spacing.unit * 2,
    width: "100%",
  },
  dialog: {
    backgroundColor: theme.main.backgroundLight,
  },
  notchedOutline: {
    borderWidth: "1px",
    borderColor: "white !important",
  },
});

class RequestAccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  submit = (values) => {
    console.log(values);
    const userInfos = {
      email: values.email,
      answers: {
        foundEasyvis: values.findingEasyvis,
        usageEasyvis: values.usingEasyvis,
      },
    };
    this.userAction(userInfos);
  };

  userAction = async (userInfos) => {
    const url =
      process.env.REACT_APP_BUILD_ENV === "production"
        ? ENDPOINT_PROD_USER.endpoint
        : ENDPOINT_DEV_USER.endpoint;
    const apiKey =
      process.env.REACT_APP_BUILD_ENV === "production"
        ? "gKdq0iJMuhajvz3zwXnCU74ibGyQlSZy9xuVmbYl"
        : "tGFquJclFnDJ471aWLG41SzxisgtwExFVCbtnwf0";
    this.setState({ loading: true });
    const response = await fetch(url + "/user/requestaccess", {
      method: "POST",
      body: JSON.stringify(userInfos),
      headers: {
        "Content-Type": "application/json",
        "x-api-key": apiKey,
      },
    });
    if (response.ok) {
      this.setState({ loading: false });
      this.props.closeRequestAccount();
      this.props.addNotification(
        "Successful created a new user access request",
        "info"
      );
    } else {
      this.setState({ loading: false });
      this.props.addNotification(
        "Something went wrong. Please, try again.",
        "error"
      );
    }
  };

  render() {
    const { classes, handleSubmit, pristine, submitting } = this.props;
    return (
      <div id="request_access">
        <Dialog
          open={this.props.open}
          onClose={this.props.closeRequestAccount}
          aria-labelledby="RequestAccess"
          PaperProps={{
            classes: {
              root: classes.dialog,
            },
          }}
        >
          <form onSubmit={handleSubmit(this.submit)}>
            <DialogTitle id="RequestAccess">Request Access</DialogTitle>
            <DialogContent>
              <Typography className={classes.textField}>
                We are currently in a beta test phase. Request access to be one
                of the first to test our product.
              </Typography>
              <div>
                <Field
                  name="email"
                  component={renderField}
                  type="text"
                  label="Your Email"
                  validate={[required(), email]}
                  className={classes.textField}
                  variant="outlined"
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline,
                    },
                  }}
                />
              </div>
              <div>
                <Field
                  name="findingEasyvis"
                  component={renderField}
                  type="text"
                  label="How did you come across easyvis?"
                  validate={[required()]}
                  className={classes.textField}
                  variant="outlined"
                  multiline
                  rows="2"
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline,
                    },
                  }}
                />
              </div>
              <div>
                <Field
                  name="usingEasyvis"
                  component={renderField}
                  type="text"
                  label="What are you planning on using easyvis for?"
                  validate={[required()]}
                  className={classes.textField}
                  variant="outlined"
                  multiline
                  rows="2"
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline,
                    },
                  }}
                />
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.props.closeRequestAccount} color="primary">
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={pristine || submitting}
              >
                Request Access
              </Button>
            </DialogActions>
          </form>
        </Dialog>
        <Loading visible={this.state.loading} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  open: state.requestAccount.requestAccountOpen,
  initialValues: state.requestAccount.details,
});

const mapDispatchToProps = (dispatch) => ({
  closeRequestAccount: () => dispatch(closeRequestAccount()),
  addNotification: (text, category) =>
    dispatch(addNotification(text, category)),
});

RequestAccess = reduxForm({
  form: "RequestAccessForm",
  enableReinitialize: true,
})(RequestAccess);

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(RequestAccess)
);
