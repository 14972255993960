import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import Fab from '@material-ui/core/Fab';

const styles = theme => ({
  playIcon: {
    color: "white",
    backgroundColor: theme.palette.thirdly.main,
    margin: theme.spacing.unit,
  },
});

class PlayIcon extends Component {

	render () {
    const { classes, onClick, size} = this.props;
		return (
      <Fab size={size} className={classes.playIcon} onClick={onClick}>
        <PlayArrowIcon />
      </Fab>
    );
	}
}



export default withStyles(styles)(PlayIcon)
