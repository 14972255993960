import {
  GET_USER_REQUEST,
  GET_USER_FAILURE,
  GET_USER_SUCCESS,
} from './actionTypes';


const initialStateUser = {
  loading: false, // marks that the players are currently loading
  information: {},
}

export const user = (state = initialStateUser, action) => {
  switch (action.type) {
    case GET_USER_REQUEST:
      return Object.assign({}, state, {loading: true})
    case GET_USER_FAILURE:
      return Object.assign({}, state, {loading: false})
    case GET_USER_SUCCESS:
      return Object.assign({}, state, {
        information: action.response.data,
        loading: false
        })
    default:
      return state
  }
}
