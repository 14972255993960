import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Time from './components/Time.js';
import Ambient from './components/Ambient.js';
import Recipes from './components/Recipes.js';
import Messages from './components/Messages.js';
import { Route } from 'react-router-dom';
import Fab from '@material-ui/core/Fab';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ProfileOptions from '../common/auth/components/ProfileOptions';
import PlayerSelector from './components/PlayerSelector';
import { withRouter } from 'react-router-dom';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import MoodIcon from '@material-ui/icons/Mood';
import SendIcon from '@material-ui/icons/Send';
import StopIcon from '@material-ui/icons/Stop';
import SettingsIcon from '@material-ui/icons/Settings';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { theme } from '../AppTheme.js';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import Media from "react-media";
import {connect} from "react-redux";
import { openPlayerSelector, stopPlayer } from './actions.js';
import { enterConsole, leaveConsole } from '../common/auth/actions.js';

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9,
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    height: '100vh',
    overflow: 'auto',
  },
  chartContainer: {
    marginLeft: -22,
  },
  tableContainer: {
    height: 320,
  },
  h5: {
    marginBottom: theme.spacing.unit * 2,
  },
  appBarMobile: {
    top: 'auto',
    bottom: 0,
  },
  playerSelectorMobile: {
    top: 'auto',
    bottom: 30,
  },
  contentMobile: {
    flexGrow: 1,
    padding: theme.spacing.unit,
    height: '100%',
    paddingBottom: 50,
  },
  fab: {
    marginLeft: theme.spacing.unit
  },
  fabContainerTop: {
    margin: 0,
    top: 15,
    right: 20,
    bottom: 'auto',
    left: 'auto',
    position: 'fixed',
  },
  fabContainerBottom: {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 70,
    left: 'auto',
    position: 'fixed',
  },
  fabContainerBottomDesktop: {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
  },
});

class KitchenApp extends React.Component {

  state = {
    open: true,
  };

  componentDidMount() {
    this.props.enterConsole();
  }

  componentWillUnmount() {
    this.props.leaveConsole();
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  handleTimeClick = (event) => {
    this.props.history.push('/example/kitchen/time')
  }

  handleAmbientClick = (event) => {
    this.props.history.push('/example/kitchen/ambient')
  }

  handleRecipesClick = (event) => {
    this.props.history.push('/example/kitchen/recipes')
  }

  handleMessagesClick = (event) => {
    this.props.history.push('/example/kitchen/messages')
  }

  stopPlayback = () => {
    this.props.stopPlayer(this.props.selectedPlayer);
  }

  render() {
    if (this.props.authState !== 'signedIn') {
      return null;
    } else {
      return (
        <Media query="(max-width: 599px)">
          {matches =>
          matches ? (
            this.renderMobile()
          ) :
            this.renderDesktop()
          }
        </Media>
      );
    }
  }

  renderMobile = () => {
    const { classes } = this.props
    return (
      <div className={classes.root}>
        <MuiThemeProvider theme={theme}>
          <main className={classes.contentMobile}>
            {this.renderMain()}
          </main>
          <div className={classes.fabContainerTop}>
            <IconButton size="small" aria-label="Settings" className={classes.fab}  onClick={this.props.openPlayerSelector}>
              <SettingsIcon />
            </IconButton>
          </div>
          <div className={classes.fabContainerBottom}>
            <Fab color="secondary" aria-label="Stop" className={classes.fab}  onClick={this.stopPlayback}>
              <StopIcon fontSize="large"/>
            </Fab>
          </div>
          <AppBar position="fixed" color="primary" className={classes.appBarMobile}>
            <BottomNavigation
              showLabels
            >
              <BottomNavigationAction label="Ambient" icon={<MoodIcon />} onClick={this.handleAmbientClick} />
              {/*<BottomNavigationAction label="Recipes" icon={<ImportContactsIcon />} onClick={this.handleRecipesClick} />*/}
              <BottomNavigationAction label="Messages" icon={<SendIcon />} onClick={this.handleMessagesClick} />
              <BottomNavigationAction label="Time" icon={<AccessTimeIcon />} onClick={this.handleTimeClick} />
            </BottomNavigation>
          </AppBar>
        </MuiThemeProvider>
      </div>
    )
  }

  renderDesktop = () => {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <AppBar
          position="absolute"
          className={classNames(classes.appBar, this.state.open && classes.appBarShift)}
        >
          <Toolbar disableGutters={!this.state.open} className={classes.toolbar}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerOpen}
              className={classNames(
                classes.menuButton,
                this.state.open && classes.menuButtonHidden,
              )}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              Kitchen App
            </Typography>
            <ProfileOptions {...this.props} />
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
          }}
          open={this.state.open}
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={this.handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
            <div>
              <ListItem button onClick={this.handleAmbientClick}>
                <ListItemIcon>
                  <MoodIcon />
                </ListItemIcon>
                <ListItemText primary="Ambient" />
              </ListItem>
              {/*<ListItem button onClick={this.handleRecipesClick}>
                <ListItemIcon>
                  <ImportContactsIcon />
                </ListItemIcon>
                <ListItemText primary="Recipes" />
              </ListItem>*/}
              <ListItem button onClick={this.handleMessagesClick}>
                <ListItemIcon>
                  <SendIcon />
                </ListItemIcon>
                <ListItemText primary="Messages" />
              </ListItem>
              <ListItem button onClick={this.handleTimeClick}>
                <ListItemIcon>
                  <AccessTimeIcon />
                </ListItemIcon>
                <ListItemText primary="Time" />
              </ListItem>
            </div>
          <Divider />
          <div>
            <ListItem button onClick={this.props.openPlayerSelector}>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Change Player" />
            </ListItem>
          </div>
        </Drawer>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          {this.renderMain()}
          <div className={classes.fabContainerBottomDesktop}>
            <Fab color="secondary" aria-label="Stop" className={classes.fab}  onClick={this.stopPlayback}>
              <StopIcon fontSize="large"/>
            </Fab>
          </div>
        </main>
        </MuiThemeProvider>
      </div>
    )
  }

  renderMain = () => {
    return (
      <div>
          <PlayerSelector />
          <Route exact path="/example/kitchen" component={Ambient}/>
          <Route exact path="/example/kitchen/time" component={Time}/>
          <Route exact path="/example/kitchen/ambient" component={Ambient}/>
          <Route exact path="/example/kitchen/recipes" component={Recipes}/>
          <Route exact path="/example/kitchen/messages" component={Messages}/>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  selectedPlayer: state.kitchen.selectedPlayer,
})

const mapDispatchToProps = dispatch => ({
  openPlayerSelector: () => dispatch(openPlayerSelector()),
  stopPlayer: (player) => dispatch(stopPlayer(player)),
  enterConsole: () => dispatch(enterConsole()),
  leaveConsole: () => dispatch(leaveConsole()),
})

export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(KitchenApp)));
