import React from 'react';
import { withRouter } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { Auth } from 'aws-amplify';
import ProfileOptions from '../../common/auth/components/ProfileOptions';
import {connect} from "react-redux";
import Media from "react-media";
import { signInOpen, signUpOpen } from '../../common/auth/actions';
import easyvisLogoLong from '../images/logoLongDark.svg';
import easyvisLogoShort from '../images/logoShortDark.svg';


const styles = theme => ({
  root: {
    width: 'auto',
    [theme.breakpoints.up('md')]: {
      width: theme.main.widthDesktop,
      marginLeft: 'auto',
      marginRight: 'auto',
    }
  },
  appBar: {
    boxShadow: 'none',
    background: 'transparent',
  },
  toolbar: {
    padding: "8px 24px 8px 24px"
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
    marginLeft: 5
  },
  button: {
    margin: theme.spacing.unit,
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('xs')]: {
      display: 'flex',
    },
  },
});

class MainAppBar extends React.Component {
  state = {
    anchorElMain: null
  };

  handleProfileMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleProfileMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  handleMainMenuOpen = event => {
    this.setState({ anchorElMain: event.currentTarget });
  };

  handleMainMenuClose = () => {
    this.setState({ anchorElMain: null });
  };


  handleLogoutClick = (event) => {
    Auth.signOut()
      .then(data => console.log(data))
      .catch(err => console.log(err));
    this.props.history.push('/')
    window.location.reload(); // reload necessary to get to the login screen
  }

  handleDeveloperClick = (event) => {
    window.open("http://developer.easyvis.io")
    this.handleMainMenuClose()
  }

  handleConsoleClick = (event) => {
    this.props.history.push('/console')
    this.handleMainMenuClose()
  }

  handleSignInOpen = () => {
    this.props.signInOpen()
  }

  handleSignUpOpen = () => {
    this.props.signUpOpen();
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <AppBar
          position="sticky"
          className={classes.appBar}
        >
          <Toolbar className={classes.toolbar}>
              <Media query="(max-width: 599px)">
                {matches =>
                matches ? (
                  <img src={easyvisLogoShort} alt="easyvis logo" height="30"/>
                ) :
                  <img src={easyvisLogoLong} alt="easyvis logo" height="30"/>
                }
              </Media>
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              <Media query="(max-width: 599px)">
                {matches =>
                matches ? (
                  null
                ) :
                  /*<Button onClick={this.handleDeveloperClick}>Developer</Button>*/
                  null
                }
              </Media>
              { this.props.authState === 'signedIn' ?
                <Button onClick={this.handleConsoleClick}>Console</Button>
                : null
              }
              <ProfileOptions {...this.props}/>
            </div>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  authState: state.authentication.authState.state,
})

const mapDispatchToProps = dispatch => ({
  signInOpen: () => dispatch(signInOpen()),
  signUpOpen: () => dispatch(signUpOpen())
})

export default withStyles(styles)(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(MainAppBar)));
