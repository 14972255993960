import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import Main from './main/components/Main.js';
import Console from './console/Console.js';
import KitchenApp from './example-kitchen/KitchenApp.js';
import Watches from './example-shelves/Watches.js'
import MainTermsOfUse from './main/components/MainTermsOfUse.js';
import MainPrivacy from './main/components/MainPrivacy.js';
import AboutUs from './main/components/MainAboutUs.js';


/**
 * Central app component which defines the header, the content and the router.
 * Note that we pass all props along to the routes because the auth props are required down there
 */
class AppContent extends Component {

  render() {
    return (
      <div>
        <Route exact path="/" render={(props) => <Main {...this.props} />} />
        <Route path="/console" render={(props) => <Console {...this.props} />} />
        <Route path="/example/kitchen" render={(props) => <KitchenApp {...this.props} />} />
        <Route path="/example/watches" render={(props) => <Watches {...this.props} />} />
        <Route path="/terms" render={(props) => <MainTermsOfUse {...this.props} />} />
        <Route path="/privacy" render={(props) => <MainPrivacy {...this.props} />} />
        <Route path="/about" render={(props) => <AboutUs {...this.props} />} />
      </div>
    );
  }
}

export default AppContent;
