import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Paper from '@material-ui/core/Paper';
import PlayIcon from '../../../common/icons/PlayIcon';
import RefreshIcon from '@material-ui/icons/Refresh';


const styles = theme => ({
    list: {
        display: 'flex',
        '& > *': {
          margin: theme.spacing(1),
        },
      },
    avatarSize: {
        width: theme.spacing(8),
        height: theme.spacing(8),
      },
    paper: {
        padding: theme.spacing(2)
    }
});

class MediaList extends Component {

    constructor() {
        super();
        this.state = {
            mode: "",
            filteredItems: [],
            confirmOpen: false,
            mediaToDelete: null
        };
    }

    handleDeleteClick = (item) => {
        this.setState({
            confirmOpen: true,
            mediaToDelete: item
        })
    }

    deleteMedia = () => {
        this.props.deleteCallback(this.state.mediaToDelete)
        this.setState({mediaToDelete: null})
    }

    handlePlayClick = (item) => {
        this.props.playCallback(item)
    }

    createMediaItem(item, deleteEnabledInput, playEnabledInput) {
        const { classes } = this.props;
        var name = item.name;
        if (typeof name === 'undefined') {
            name = item.id
        }
        var subheading = item.type
        if (typeof item.uri !== 'undefined') {
            subheading += ' | ' + item.uri
        }
        if (typeof item.duration !== 'undefined') {
            subheading += ' | ' + item.duration + 's'
        }

        var deleteEnabled = false
        if (typeof deleteEnabledInput !== 'undefined') {
            deleteEnabled = true
        }
        var playEnabled = false
        if (typeof playEnabledInput !== 'undefined') {
            playEnabled = true
        }
        return(
            <ListItem key={name} button className={classes.list}> 
                <ListItemAvatar>
                <Avatar
                    src={item.thumbnailUrl}
                    variant="square"
                    className={classes.avatarSize}
                />
                </ListItemAvatar>
                <ListItemText id={name} primary={name} secondary={subheading} />
                <ListItemSecondaryAction>
                {deleteEnabled ? 
                    <IconButton aria-label="delete" onClick={() => {this.handleDeleteClick(item)}}>
                        <DeleteIcon />
                    </IconButton>
                : null }
                {playEnabled ? 
                    <IconButton aria-label="delete" onClick={() => {this.handlePlayClick(item)}}>
                        <PlayIcon size="small" />
                    </IconButton>
                : null }
                </ListItemSecondaryAction>
            </ListItem>
        );
    }

    handleChange = (mode) => {
        if (mode === this.state.mode) {
            this.setState({mode: ""});
        } else {
            this.setState({mode: mode});
        }
    }

    filterItems = (input, type) => {
        if (type !== "") {
            return this.props.media.filter(m => m.type === type.toUpperCase());
        } else {
            return input;
        }

    }

    setConfirmOpen = (open) => {
        this.setState({confirmOpen: open});
    }
    
	render () {        
        const { classes } = this.props;
        const mode = this.state.mode;
        var items = this.filterItems(this.props.media, this.state.mode);
        const deleteEnabled = this.props.deleteEnabled;
        const playEnabled = this.props.playEnabled;
        const nofilter = this.props.nofilter;
        const refresh = this.props.refresh;
		return (
            <Paper className={classes.paper}>
                <Grid
                container
                spacing={1}
                alignContent="center"
                alignItems="center"
                >
                    <Grid item xs={12} align="left" alignContent="center">
                        <Grid
                            container
                            spacing={1}
                            justify="space-between"
                            alignItems="center"
                        >
                            <Grid item>
                                {nofilter ? null :
                                    <ButtonGroup color="primary" size="small">
                                        <Button 
                                            variant={mode === "video" ? "contained" : "outlined"} 
                                            onClick={() => this.handleChange("video")}
                                        >
                                            Videos
                                        </Button>
                                        <Button 
                                            variant={mode === "image" ? "contained" : "outlined"} 
                                            onClick={() => this.handleChange("image")}
                                        >
                                            Images
                                        </Button>
                                    </ButtonGroup>
                                }
                            </Grid>
                            <Grid item>
                                {refresh ?
                                    <IconButton color="primary" aria-label="refresh" onClick={this.props.refreshCallback}>
                                        <RefreshIcon />
                                    </IconButton>
                                    : null
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} align="center" alignContent="center">
                        <List dense className={classes.root}>
                            { items.map(item => this.createMediaItem(item, deleteEnabled, playEnabled)) }
                        </List>
                    </Grid>
                    <ConfirmDialog
                        open={this.state.confirmOpen}
                        setOpen={this.setConfirmOpen}
                        onConfirm={this.deleteMedia}
                    />
                </Grid>
            </Paper>
    );
	}
}


export default withStyles(styles)((MediaList))

const ConfirmDialog = ({ open, setOpen, onConfirm }) => {
    return (
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="confirm-dialog"
      >
        <DialogTitle id="confirm-dialog">Delete media</DialogTitle>
        <DialogContent>
            Are you sure to delete the media?
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpen(false)}
            color="primary"
          >
            No
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setOpen(false);
              onConfirm();
            }}
            color="primary" 
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  };