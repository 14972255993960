import React from "react";
import { format } from "redux-form-validators";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";

const renderField = ({
  label,
  input,
  className,
  meta: { touched, invalid, error },
  ...custom
}) => (
  <TextField
    label={label}
    className={className}
    placeholder={label}
    error={touched && invalid}
    helperText={touched && error}
    {...input}
    {...custom}
  />
);

export { renderField };

// JSON Field for react redux field
export class JSONField extends React.Component {
  inputChanged = (value) => {
    // Updates the value of the react redux field component
    this.props.input.onChange(value.jsObject);
  };

  render() {
    const {
      input: { value },
    } = this.props;
    return (
      <JSONInput
        placeholder={value} // data to display
        theme="light_mitsuketa_tribute"
        onChange={this.inputChanged}
        locale={locale}
      />
    );
  }
}

const ipregex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
const ipValidator = format({
  with: ipregex,
  message: "Only allows ip address format",
});

export { ipValidator };

// Regular expression and validator for the device label
const labelRegex = /^([a-zA-Z0-9_-]{4,25})$/;
const labelalidator = format({
  with: labelRegex,
  message:
    "Label must contain a-z, A-Z, 0-9, _ and -. Size musst be between 4-25",
});

export { labelRegex };
export { labelalidator };

export const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "Invalid email address"
    : undefined;

const renderSelectField = ({
  input,
  label,
  name,
  className,
  meta: { touched, error },
  children,
  ...custom
}) => (
  <FormControl error={touched && error}>
    <InputLabel htmlFor={name}>{label}</InputLabel>
    <Select
      floatinglabeltext={label}
      className={className}
      {...input}
      {...custom}
      inputProps={{
        name: name,
        id: name,
      }}
    >
      {children}
    </Select>
    {renderFromHelper({ touched, error })}
  </FormControl>
);

const renderFromHelper = ({ touched, error }) => {
  if (!(touched && error)) {
    return;
  } else {
    return <FormHelperText>{touched && error}</FormHelperText>;
  }
};

export { renderSelectField };

// Doing a fetch with a timeout
export function fetchWithTimeout(url, options, timeout = 7000) {
  return Promise.race([
    fetch(url, options),
    new Promise((_, reject) =>
      setTimeout(() => reject(new Error("timeout")), timeout)
    ),
  ]);
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
