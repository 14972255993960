import {
  GET_PLAYER_REQUEST,
  GET_PLAYER_FAILURE,
  GET_PLAYER_SUCCESS,
  PLAYER_PLAY_REQUEST,
  PLAYER_PLAY_FAILURE,
  PLAYER_PLAY_SUCCESS,
  PLAYER_STOP_REQUEST,
  PLAYER_STOP_FAILURE,
  PLAYER_STOP_SUCCESS,
  PLAYER_SET,
  PLAYER_SELECTOR_OPEN,
  PLAYER_SELECTOR_CLOSE,
} from './actionTypes';


const initialStateKitchen = {
  loading: false, // marks that the players are currently loading
  items: [],
  selectedPlayer: '',
  playerSelectorOpened: false
}

export const kitchen = (state = initialStateKitchen, action) => {
  switch (action.type) {
    case GET_PLAYER_REQUEST:
      return Object.assign({}, state, {loading: true})
    case GET_PLAYER_FAILURE:
      return Object.assign({}, state, {loading: false})
    case GET_PLAYER_SUCCESS:
      var players = action.response.data;
      var selectedPlayer = '';
      if (players.length > 0) {
        selectedPlayer = players[0].playerName;
      }
      return Object.assign({}, state, {
        items: players.map(player =>
          Object.assign({}, {}, {
            playerName: player.playerName
          })),
        selectedPlayer: selectedPlayer,
        loading: false
        })
      case PLAYER_PLAY_REQUEST:
        return Object.assign({}, state, {loading: true})
      case PLAYER_PLAY_FAILURE:
        return Object.assign({}, state, {loading: false})
      case PLAYER_PLAY_SUCCESS:
        return Object.assign({}, state, {loading: false})
      case PLAYER_STOP_REQUEST:
        return Object.assign({}, state, {loading: true})
      case PLAYER_STOP_FAILURE:
        return Object.assign({}, state, {loading: false})
      case PLAYER_STOP_SUCCESS:
        return Object.assign({}, state, {loading: false})
      case PLAYER_SET:
        return Object.assign({}, state, {selectedPlayer: action.player})
      case PLAYER_SELECTOR_OPEN:
        return Object.assign({}, state, {playerSelectorOpened: true})
      case PLAYER_SELECTOR_CLOSE:
        return Object.assign({}, state, {playerSelectorOpened: false})
    default:
      return state
  }
}
