import * as t from './actionTypes';

export const removeNotification = (key) => ({
    type: t.REMOVE_NOTIFICATION,
    key: key
})

export const addNotification = (message, variant) => ({
    type: t.ADD_NOTIFICATION,
    message: message,
    variant: variant
})
