import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { getUserInformation } from "../actions.js";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import EmailIcon from '@material-ui/icons/Email';



const styles = theme => ({
});

/**
 * Test page to test the player API
 */
class UserInformation extends Component {

  constructor(props) {
    super();
  }

  componentDidMount() {
    this.props.reloadUserInformation();
	}

	render () {
		const { classes } = this.props;
    console.log(this.props.userInformation);
    var appClients = "";
    if (typeof this.props.userInformation.apps !== 'undefined') {
      appClients = this.props.userInformation.apps.map((app) => this.createAppClient(app));
    }
		return (
      <div className={classes.root}>
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <Typography variant="h4">
              User Information
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">
              App Clients
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {appClients}
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
                control={
                    <a target="_top"
                        rel="noopener noreferrer"
                        href="mailto:info@smartdings.com">
                        <IconButton color="primary">
                            <EmailIcon />
                        </IconButton>
                    </a>
                }
                label={"Request an easyvis.io app client"}
                labelPlacement="end"
            />
          </Grid>
        </Grid>
      </div>
    );
	}

  createAppClient(app) {
  	const { classes } = this.props;
    return (
      <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableBody>
            <TableRow>
              <TableCell variant="head">Client Id</TableCell>
              <TableCell >{app.clientId}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Client Secret</TableCell>
              <TableCell >{app.clientSecret}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Callback/Redirect URLs</TableCell>
              <TableCell >{app.callbackURLs}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Signout URLs</TableCell>
              <TableCell >{app.signOutURLs}</TableCell>
            </TableRow>
        </TableBody>
      </Table>
    </Paper>
    )
  }
}

const mapStateToProps = state => ({
  userInformation: state.user.information
})

const mapDispatchToProps = dispatch => ({
  reloadUserInformation: () => dispatch(getUserInformation())
})

export default withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps
)(UserInformation))
