import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk'
import api from './common/middleware/api'
import { logger } from './common/middleware/logger'
import caviApp from './reducer';
import { SnackbarProvider } from 'notistack';

// load fonts:
require('typeface-muli')

const store = createStore(caviApp, applyMiddleware(thunk, api, logger));

ReactDOM.render(
  <Provider store={store}>
    <SnackbarProvider>
      <App />
    </SnackbarProvider>
  </Provider>,
  document.getElementById('root'));
registerServiceWorker();
