import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import EasyvisColorPicker from '../../../../common/EasyvisColorPicker';


const styles = theme => ({
  root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formControl: {
      margin: theme.spacing.unit,
      minWidth: 220,
    },
});


class ProgressApp extends Component {

  // the state represents the config of the widget
  state = {
    startingSide: 'bottom',
    progressDirection: 'grow',
    progressStart: 0.0
  }

  componentDidMount() {
    // report initial state
    this.setStateCallback();
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value }, this.setStateCallback);
  }

  handleColorChange = (color) => {
    this.setState({ color: color }, this.setStateCallback)
  }

  handleBGColorChange = (color) => {
    this.setState({ bgColor: color }, this.setStateCallback)
  }

  setStateCallback = () => {
    this.props.onConfigChange(this.state)
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <form className={classes.root} autoComplete="off">
          <FormControl className={classes.formControl}>
            <TextField
              id="progressStart"
              name="progressStart"
              label="Progress starts at (0.0 - 1.0)"
              value={this.state.progressStart}
              onChange={this.handleChange}
              fullWidth
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="startingSide">Starting Side</InputLabel>
            <Select
              value={this.state.startingSide}
              onChange={this.handleChange}
              inputProps={{
                name: 'startingSide',
                id: 'startingSide',
              }}
            >
              <MenuItem value={"bottom"}>Bottom</MenuItem>
              <MenuItem value={"top"}>Top</MenuItem>
              <MenuItem value={"left"}>Left</MenuItem>
              <MenuItem value={"right"}>Right</MenuItem>
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="progressDirection">Progress Direction</InputLabel>
            <Select
              value={this.state.progressDirection}
              onChange={this.handleChange}
              inputProps={{
                name: 'progressDirection',
                id: 'progressDirection',
              }}
            >
              <MenuItem value={"grow"}>Grow</MenuItem>
              <MenuItem value={"shrink"}>Shrink</MenuItem>
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              id="time"
              name="time"
              label="Timer of progress bar (s)"
              value={this.state.time}
              onChange={this.handleChange}
              fullWidth
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <EasyvisColorPicker
              value={this.state.color}
              onChange={this.handleColorChange}
              convert='hex'
              id="color"
              name="color"
              label="Color"
              fullWidth
              defaultValue='#FFFFFF'
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <EasyvisColorPicker
              value={this.state.bgColor}
              onChange={this.handleBGColorChange}
              convert='hex'
              id="bgColor"
              name="bgColor"
              label="Background Color"
              fullWidth
              defaultValue='#000000'
            />
          </FormControl>
        </form>
      </div>
    )
  }
}

export default withStyles(styles)(ProgressApp);
