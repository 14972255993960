import * as t from "./actionTypes";
import { CALL_API } from "../../common/middleware/api";

export const getMediaCommonAPI = () => ({
  [CALL_API]: {
    types: [
      t.GET_MEDIA_COMMON_REQUEST,
      t.GET_MEDIA_COMMON_SUCCESS,
      t.GET_MEDIA_COMMON_FAILURE,
    ],
    apiName: "MediaAPI",
    path: "/media/common",
    method: "get",
    myInit: {
      headers: {},
      response: true,
    },
  },
});

export const getMediaLocalAPI = (player) => ({
  [CALL_API]: {
    types: [
      t.GET_MEDIA_LOCAL_REQUEST,
      t.GET_MEDIA_LOCAL_SUCCESS,
      t.GET_MEDIA_LOCAL_FAILURE,
    ],
    apiName: "MediaAPI",
    path: "/media/local/" + player,
    method: "get",
    myInit: {
      headers: {},
      response: true,
    },
  },
});

export const postMediaLocalAPI = (player, name, uri, type) => ({
  [CALL_API]: {
    types: [
      t.POST_MEDIA_LOCAL_REQUEST,
      t.POST_MEDIA_LOCAL_SUCCESS,
      t.POST_MEDIA_LOCAL_FAILURE,
    ],
    apiName: "MediaAPI",
    path: "/media/local/" + player,
    method: "post",
    myInit: {
      headers: {},
      body: {
        id: name,
        uri: uri,
        type: type,
      },
      response: true,
    },
  },
});

export const deleteMediaLocalAPI = (player, name) => ({
  [CALL_API]: {
    types: [
      t.DELETE_MEDIA_LOCAL_REQUEST,
      t.DELETE_MEDIA_LOCAL_SUCCESS,
      t.DELETE_MEDIA_LOCAL_FAILURE,
    ],
    apiName: "MediaAPI",
    path: "/media/local/" + player + "/" + name,
    method: "delete",
    myInit: {
      headers: {},
      response: true,
    },
  },
});

export const getPlaylistsAPI = () => ({
  [CALL_API]: {
    types: [
      t.GET_PLAYLISTS_REQUEST,
      t.GET_PLAYLISTS_SUCCESS,
      t.GET_PLAYLISTS_FAILURE,
    ],
    apiName: "MediaAPI",
    path: "/playlist",
    method: "get",
    myInit: {
      headers: {},
      response: true,
    },
  },
});

export const updatePlaylistAPI = (playlistId, playlistItem) => ({
  [CALL_API]: {
    types: [
      t.UPDATE_PLAYLIST_REQUEST,
      t.UPDATE_PLAYLIST_SUCCESS,
      t.UPDATE_PLAYLIST_FAILURE,
    ],
    apiName: "MediaAPI",
    path: "/playlist/" + playlistId,
    method: "post",
    myInit: {
      headers: {},
      body: playlistItem,
      response: true,
    },
  },
});

export const createPlaylistAPI = (playlistItem) => ({
  [CALL_API]: {
    types: [
      t.CREATE_PLAYLIST_REQUEST,
      t.CREATE_PLAYLIST_SUCCESS,
      t.CREATE_PLAYLIST_FAILURE,
    ],
    apiName: "MediaAPI",
    path: "/playlist",
    method: "post",
    myInit: {
      headers: {},
      body: playlistItem,
      response: true,
    },
  },
});

export const deletePlaylistAPI = (playlistId) => ({
  [CALL_API]: {
    types: [
      t.DELETE_PLAYLIST_REQUEST,
      t.DELETE_PLAYLIST_SUCCESS,
      t.DELETE_PLAYLIST_FAILURE,
    ],
    apiName: "MediaAPI",
    path: "/playlist/" + playlistId,
    method: "delete",
    myInit: {
      headers: {},
      response: true,
    },
  },
});
