import React from "react";
import { withStyles } from "@material-ui/core/styles";
import LayoutBody from "./MainLayoutBody";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import interactiveEvents from "../images/interactiveEvents.png";

const styles = (theme) => ({
  root: {
    display: "flex",
    overflow: "hidden",
    backgroundColor: theme.main.backgroundDark,
    backgroundImage:
      "linear-gradient(to top, rgba(14,15,31,1), rgba(16,18,36,1))",
  },
  layoutBodyLeft: {
    alignItems: "left",
    marginTop: theme.spacing.unit * 20,
    marginBottom: theme.spacing.unit * 20,
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing.unit * 8,
      marginBottom: theme.spacing.unit * 8,
    },
  },
  grid: {
    flexGrow: 1,
  },
  title: {
    marginBottom: theme.spacing.unit * 2,
  },
  subtitle: {
    marginBottom: theme.spacing.unit * 4,
  },
  subtext: {
    marginBottom: theme.spacing.unit * 2,
  },
  link: {
    color: theme.palette.text.primary,
    width: "100%",
  },
  linkContainer: {
    marginTop: theme.spacing.unit * 6,
  },
  preTitle: {
    color: theme.palette.primary.main,
  },
  mobileContainerFix: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: 24,
    },
  },
  img: {
    width: "80%",
    align: "center",
  },
  bodySpacing: {
    paddingTop: "4px !important",
  },
});

class MainInteractive extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <section className={classes.root}>
        <LayoutBody className={classes.layoutBodyLeft}>
          <Grid container spacing={3} alignContent="center" alignItems="center">
            <Grid item xs={12}>
              <Typography
                variant="subtitle2"
                align="left"
                className={classes.preTitle}
              >
                Be creative
              </Typography>
              <Typography variant="h2" align="left" className={classes.title}>
                Make your digital signage interactive
              </Typography>
              <Typography
                variant="body1"
                align="left"
                className={classes.subtitle}
              >
                Build any kind of interactive digital signage scenario by
                combining the different easyvis.io components and connect them
                with other devices and services via our open API. For example
                use a camera and sensors to detect people. Depending on the
                group of people standing in front of the camera products are
                highlighted on a TV or via light.
              </Typography>
            </Grid>
            <Grid item xs={12} align="center">
              <img
                src={interactiveEvents}
                alt="Code snippet on how to integrate easyvis.io"
                className={classes.img}
              />
            </Grid>
          </Grid>
        </LayoutBody>
      </section>
    );
  }
}

export default withStyles(styles)(MainInteractive);
