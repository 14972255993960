import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { playPlayer } from '../../actions';
import { getMediaCommonAPI } from '../../../media/actions';
import MediaList from '../../../media/components/MediaList';

const styles = theme => ({
});

/**
 * Testing streams. Defines the list of media with the actions for each media item
 */
class TestStreams extends Component {

	componentDidMount() {
   		this.props.reloadMedia();
	}

	play = (media) => {
		const player = this.props.selectedPlayer;
		const playerConfig = this.props.playerConfig;
		if (media.type === "VIDEO") {
			const config = { "uri" : media.streamUrl };
			this.props.playPlayer(player, "stream", config, playerConfig);
		} else {
			const config = { "uri" : media.url };
			this.props.playPlayer(player, "image", config, playerConfig);
		}
	} 

	render () {
		const items = this.props.media
		return (
			<MediaList
				media={items}
				playEnabled={true}
				playCallback={this.play}
			/>
    );
	}
}

const mapStateToProps = state => ({
	selectedPlayer: state.players.selectedPlayer,
	playerConfig: state.players.playerConfig,
  	media: state.media.mediaCommon
})

const mapDispatchToProps = dispatch => ({
  reloadMedia: () => dispatch(getMediaCommonAPI()),
  playPlayer: (player, type, config, playConfig) => dispatch(playPlayer(player, type, config, playConfig))
})

export default withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps
)(TestStreams))
