import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import ButtonBase from "@material-ui/core/ButtonBase";
import { withRouter } from "react-router-dom";

const styles = (theme) => ({
  cardTitle: {
    width: "100%",
  },
  cardAction: {
    width: "100%",
  },
  actions: {
    display: "flex",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  playIcon: {
    height: 38,
    width: 38,
  },
});

export const apps = [
  {
    id: "stream",
    name: "Video Stream",
    description: "Play your video stream",
    link: "/console/app/stream",
    infoLink: "http://developer.easyvis.io/docs/visualization-apps/stream/",
  },
  {
    id: "image",
    name: "Image",
    description: "Show your image",
    link: "/console/app/image",
    infoLink: "http://developer.easyvis.io/docs/visualization-apps/image/",
  },
  {
    id: "teststreams",
    name: "Public Media",
    description: "Play public videos and images",
    link: "/console/app/teststreams",
    infoLink: "http://developer.easyvis.io/",
  },
  {
    id: "playlists",
    name: "Playlists",
    description: "Playlists for images and videos",
    link: "/console/app/playlists",
    infoLink: "http://developer.easyvis.io/docs/visualization-apps/playlist/",
  },
  {
    id: "website",
    name: "Website",
    description: "Show a website",
    link: "/console/app/website",
    infoLink: "http://developer.easyvis.io/",
  },
  {
    id: "text",
    name: "Text",
    description: "Show a text",
    link: "/console/app/text",
    infoLink: "http://developer.easyvis.io/docs/visualization-apps/text/",
  },
  {
    id: "progress",
    name: "Progress",
    description: "Show a progress bar",
    link: "/console/app/progress",
    infoLink: "http://developer.easyvis.io/docs/visualization-apps/progress/",
  },
  {
    id: "blink",
    name: "Blink",
    description: "Let your screen blink",
    link: "/console/app/blink",
    infoLink: "http://developer.easyvis.io/docs/visualization-apps/blink/",
  },
  {
    id: "pulse",
    name: "Pulse",
    description: "Pulsating color",
    link: "/console/app/pulse",
    infoLink: "http://developer.easyvis.io/docs/visualization-apps/pulse/",
  },
  {
    id: "twinkle",
    name: "Twinkle",
    description: "Twinkling effect",
    link: "/console/app/twinkle",
    infoLink: "http://developer.easyvis.io/docs/visualization-apps/twinkle/",
  },
  {
    id: "color",
    name: "Color",
    description: "Set a static color",
    link: "/console/app/color",
    infoLink: "http://developer.easyvis.io/docs/visualization-apps/color/",
  },
  {
    id: "dimmer",
    name: "Dimmer",
    description: "Dim visualization devices",
    link: "/console/app/dimmer",
    infoLink: "http://developer.easyvis.io/",
  },
  {
    id: "shapes",
    name: "Shapes",
    description: "Create color shapes on your screen",
    link: "/console/app/shapes",
    infoLink: "http://developer.easyvis.io/docs/visualization-apps/shapes/",
  },
  {
    id: "clock",
    name: "Clock",
    description: "Style and visualize your individual clock",
    link: "/console/app/clock",
    infoLink: "http://developer.easyvis.io/docs/visualization-apps/clock/",
  },
  {
    id: "manual",
    name: "Manual",
    description: "Test the API manually",
    link: "/console/app/manual",
    infoLink: "http://developer.easyvis.io/",
  },
];

export const getAppInfo = (id) => {
  return apps.find((app) => app.id === id);
};

class AppList extends Component {
  handleExpandClick = () => {
    this.setState((state) => ({ expanded: !state.expanded }));
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleLinkClick = (event, link) => {
    console.log(link);
    this.props.history.push(link);
  };

  renderCard = (id, title, subtitle, link) => {
    const { classes } = this.props;
    return (
      <Grid item xs={12} sm={4} key={id}>
        <Card className={classes.card}>
          <ButtonBase
            className={classes.cardAction}
            onClick={(e) => this.handleLinkClick(e, link)}
          >
            <CardHeader
              title={title}
              subheader={subtitle}
              className={classes.cardTitle}
            />
          </ButtonBase>
        </Card>
      </Grid>
    );
  };

  render() {
    const { classes } = this.props;
    const appList = apps.map((app) =>
      this.renderCard(app.id, app.name, app.description, app.link)
    );
    return (
      <div className={classes.root}>
        <Grid container spacing={3}>
          {appList}
        </Grid>
      </div>
    );
  }
}

//{this.renderCard(apps['stream'].name, apps['stream'].description, apps['stream'].link)}
export default withStyles(styles)(withRouter(AppList));
