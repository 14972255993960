import * as t from './actionTypes';


const initialStateMedia = {
  loading: false, 
  mediaCommon: [],
  mediaLocal: [],
  playlists: []
}

export const media = (state = initialStateMedia, action) => {
  switch (action.type) {
      case t.GET_MEDIA_COMMON_REQUEST:
        return Object.assign({}, state, {loading: true})
      case t.GET_MEDIA_COMMON_FAILURE:
        return Object.assign({}, state, {loading: false})
      case t.GET_MEDIA_COMMON_SUCCESS:
        var media = action.response.data
        return Object.assign({}, state, {
          mediaCommon: media,
          loading: false
          })
      case t.GET_MEDIA_LOCAL_REQUEST:
        return Object.assign({}, state, {loading: true})
      case t.GET_MEDIA_LOCAL_FAILURE:
        return Object.assign({}, state, {loading: false})
      case t.GET_MEDIA_LOCAL_SUCCESS:
        var mediaLocal = action.response.data
        return Object.assign({}, state, {
          mediaLocal: mediaLocal,
          loading: false
          })
      case t.POST_MEDIA_LOCAL_REQUEST:
        return Object.assign({}, state, {loading: true})
      case t.POST_MEDIA_LOCAL_FAILURE:
        return Object.assign({}, state, {loading: false})
      case t.POST_MEDIA_LOCAL_SUCCESS:
        return Object.assign({}, state, {loading: false})
      case t.DELETE_MEDIA_LOCAL_REQUEST:
        return Object.assign({}, state, {loading: true})
      case t.DELETE_MEDIA_LOCAL_FAILURE:
        return Object.assign({}, state, {loading: false})
      case t.DELETE_MEDIA_LOCAL_SUCCESS:
        return Object.assign({}, state, {loading: false})
      case t.GET_PLAYLISTS_REQUEST:
        return Object.assign({}, state, {loading: true})
      case t.GET_PLAYLISTS_FAILURE:
        return Object.assign({}, state, {loading: false})
      case t.GET_PLAYLISTS_SUCCESS:
        var playlists = action.response.data
        return Object.assign({}, state, {
          playlists: playlists,
          loading: false
          })
      case t.UPDATE_PLAYLIST_REQUEST:
        return Object.assign({}, state, {loading: true})
      case t.UPDATE_PLAYLIST_FAILURE:
        return Object.assign({}, state, {loading: false})
      case t.UPDATE_PLAYLIST_SUCCESS:
        return Object.assign({}, state, {loading: false})
      case t.DELETE_PLAYLIST_REQUEST:
        return Object.assign({}, state, {loading: true})
      case t.DELETE_PLAYLIST_FAILURE:
        return Object.assign({}, state, {loading: false})
      case t.DELETE_PLAYLIST_SUCCESS:
        return Object.assign({}, state, {loading: false})
      case t.CREATE_PLAYLIST_REQUEST:
        return Object.assign({}, state, {loading: true})
      case t.CREATE_PLAYLIST_FAILURE:
        return Object.assign({}, state, {loading: false})
      case t.CREATE_PLAYLIST_SUCCESS:
        return Object.assign({}, state, {loading: false})
    default:
      return state
  }
}
