import { API, Auth } from "aws-amplify";
import Amplify from "aws-amplify";

// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = "Call API";

// Provided API names
export const PLAYER_API = "PlayerAPI";
export const MEDIA_API = "MediaAPI";
export const USER_API = "UserAPI";

const PLAYER_API_KEY_DEV = "06KZVxSlNsoB5qsPtPPn5PR56iph3w54KoBA9hOd";
const PLAYER_API_KEY_PROD = "W2BNAmAmJMaSqu0bY3M1j6zGnp9fZV6Y5ceRWgo8";

export const ENDPOINT_PROD_PLAYER = {
  name: PLAYER_API,
  endpoint: "https://njy5162k2b.execute-api.eu-central-1.amazonaws.com/prod",
  region: "eu-central-1",
  custom_header: async () => {
    return {
      Authorization: (await Auth.currentSession()).accessToken.jwtToken,
      "x-api-key": PLAYER_API_KEY_PROD,
    };
  },
};
export const ENDPOINT_PROD_MEDIA = {
  name: MEDIA_API,
  endpoint: "https://23rai7xhqf.execute-api.eu-central-1.amazonaws.com/prod",
  region: "eu-central-1",
  custom_header: async () => {
    return {
      Authorization: (await Auth.currentSession()).accessToken.jwtToken,
    };
  },
};
export const ENDPOINT_PROD_USER = {
  name: USER_API,
  endpoint: "https://64gisd6of4.execute-api.eu-central-1.amazonaws.com/prod",
  region: "eu-central-1",
  custom_header: async () => {
    return {
      Authorization: (await Auth.currentSession()).accessToken.jwtToken,
    };
  },
};
export const ENDPOINT_DEV_PLAYER = {
  name: PLAYER_API,
  endpoint: "https://6s83otxs5e.execute-api.eu-central-1.amazonaws.com/dev",
  region: "eu-central-1",
  custom_header: async () => {
    return {
      Authorization: (await Auth.currentSession()).accessToken.jwtToken,
      "x-api-key": PLAYER_API_KEY_DEV,
    };
  },
};
export const ENDPOINT_DEV_MEDIA = {
  name: MEDIA_API,
  endpoint: "https://biccjz3l5j.execute-api.eu-central-1.amazonaws.com/dev",
  region: "eu-central-1",
  custom_header: async () => {
    return {
      Authorization: (await Auth.currentSession()).accessToken.jwtToken,
    };
  },
};
export const ENDPOINT_DEV_USER = {
  name: USER_API,
  endpoint: "https://pjzfs3g8x3.execute-api.eu-central-1.amazonaws.com/dev",
  region: "eu-central-1",
  custom_header: async () => {
    return {
      Authorization: (await Auth.currentSession()).accessToken.jwtToken,
    };
  },
};

if (process.env.REACT_APP_BUILD_ENV === "production") {
  Amplify.configure({
    Auth: {
      identityPoolId: "eu-central-1:702d73a0-8a4b-4553-8fcf-4470c301c5b4",
      region: "eu-central-1",
      userPoolId: "eu-central-1_VM8tpA0QO",
      userPoolWebClientId: "3j1985h2boacr1iqep2b660b72",
      mandatorySignIn: true,
    },
    API: {
      endpoints: [
        ENDPOINT_PROD_PLAYER,
        ENDPOINT_PROD_MEDIA,
        ENDPOINT_PROD_USER,
      ],
    },
  });
} else {
  Amplify.configure({
    Auth: {
      identityPoolId: "eu-central-1:08d7cea5-9445-4524-84cb-e40c88c607d5",
      region: "eu-central-1",
      userPoolId: "eu-central-1_VDvPrzrn5",
      userPoolWebClientId: "351jjp5cfticnu0bg0mqivvu32",
      mandatorySignIn: true,
    },
    API: {
      endpoints: [ENDPOINT_DEV_PLAYER, ENDPOINT_DEV_MEDIA, ENDPOINT_DEV_USER],
    },
  });
}

// A Redux middleware that interprets actions with CALL_API info specified.
// Performs the call and promises when such actions are dispatched.
// Note that the "context" is used to pass data through the API state changes
// in order to use it at a later stage to react to the API response
export default (store) => (next) => (action) => {
  const callAPI = action[CALL_API];
  if (typeof callAPI === "undefined") {
    return next(action);
  }

  const { apiName, path, method, types, myInit, context } = callAPI;

  if (typeof apiName !== "string") {
    throw new Error("Specify a string API name.");
  }
  if (typeof path !== "string") {
    throw new Error("Specify a string path.");
  }
  if (typeof method !== "string") {
    throw new Error("Specify a method.");
  }
  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error("Expected an array of three action types.");
  }
  if (!types.every((type) => typeof type === "string")) {
    throw new Error("Expected action types to be strings.");
  }

  const actionWith = (data) => {
    const finalAction = Object.assign({}, action, data);
    delete finalAction[CALL_API];
    return finalAction;
  };

  const [requestType, successType, failureType] = types;
  next(actionWith({ type: requestType, context: context }));

  apiCall(method, apiName, path, myInit).then(
    (response) =>
      next(
        actionWith({
          response,
          type: successType,
          context: context,
        })
      ),
    (error) =>
      next(
        actionWith({
          type: failureType,
          error: "Something bad happened in API call",
        })
      )
  );
};

// Default API call method (should be used everywhere)
export function apiCall(method, apiName, path, myInit) {
  switch (method) {
    case "get":
      return API.get(apiName, path, myInit);
    case "post":
      return API.post(apiName, path, myInit);
    case "put":
      return API.put(apiName, path, myInit);
    case "delete":
      return API.del(apiName, path, myInit);
    default:
      throw new Error(
        "API method needs to be either get, put, post or delete."
      );
  }
}
