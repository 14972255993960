import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux'
import { playPlayer } from '../../actions'
import { addNotification } from '../../../../common/notifications/actions'
import JSONInput from 'react-json-editor-ajrm';
import locale    from 'react-json-editor-ajrm/locale/en';


const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  textField: {
    margin: theme.spacing.unit
  },
});

const sampleData = [
		{
			type: "box",
			centerX: 0.25,
			centerY: 0.5,
			width: 0.5,
			height: 1.0,
			color: "#000000"
		},
		{
			type: "box",
			centerX: 0.75,
			centerY: 0.5,
			width: 0.5,
			height: 1.0,
			color: "#FF0055"
		}
	];

/**
 * Test page to test the player API
 */
class ShapesApp extends Component {

  state = {
    shapes: sampleData
  }

  componentDidMount() {
    // report initial state
    this.setStateCallback();
  }

  inputChanged = (event) => {
    this.setState({ shapes: event.jsObject }, this.setStateCallback)
  }

  setStateCallback = () => {
    this.props.onConfigChange(this.state)
  }

	render () {
		const { classes } = this.props;
		return (
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <JSONInput
              placeholder={this.state.shapes} // initial data to display
              theme="light_mitsuketa_tribute"
              onChange={this.inputChanged}
              locale={locale}
              height = '400px'
              width='100%'
            />
          </Grid>
        </Grid>
      </div>
    );
	}
}

const mapStateToProps = state => ({
  selectedPlayer: state.players.selectedPlayer,
  playerConfig: state.players.playerConfig
})

const mapDispatchToProps = dispatch => ({
  playPlayer: (player, type, config, playConfig) => dispatch(playPlayer(player, type, config, playConfig)),
  addNotification: (notification, level) => dispatch(addNotification(notification, level))
})

export default withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps
)(ShapesApp))
