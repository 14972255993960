import React from "react";
import { SignUp } from "aws-amplify-react";
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import ErrorSnack from '../../notifications/components/ErrorSnack';
import defaultSignUpFields from './default-signup-fields.js';
import {connect} from "react-redux";
import { signUpClose, signInOpen } from '../actions'


const styles = theme => ({
  formControl: {
    margin: theme.spacing.unit,
  },
  button: {
    margin: theme.spacing.unit,
    align: 'center'
  }
});

class SignUpCavi extends SignUp {

  state = {
    openError: false,
    error: ''
  }

  constructor(props) {
    super(props);
    this.defaultSignUpFields = defaultSignUpFields;
    this._validAuthStates = ["signUp"];
  }

  handleClickToSignIn = () => {
    console.log('bla');
    super.changeState("signIn");
    this.props.signUpClose();
    this.props.signInOpen();
  }

  error(err) {
    this.setState({openError: true, error: err.message});
  }

  handleClickCloseError = () => {
    this.setState({openError: false});
  }

  handleClickSignUpClose = () => {
    this.setState({openError: false});
    this.props.signUpClose();
  }

  onFormSubmit = e => {
    e.preventDefault();
    this.signUp();
  }

  showComponent(theme) {
    const { classes, signUpOpen } = this.props;
    const { openError, error } = this.state;
    if (this.checkCustomSignUpFields()) {
            this.signUpFields = this.props.signUpConfig.signUpFields;
        }
    this.sortFields();
    return (
      <Dialog
          open={signUpOpen}
          onClose={this.handleClickSignUpClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Sign up</DialogTitle>
          <DialogContent>
            <form onSubmit={this.onFormSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormControl className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="username">Email</InputLabel>
                    <Input id="username" key="username" name="username" onChange={this.handleInputChange} />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="password">Password</InputLabel>
                    <Input id="password" key="password" name="password" type="password" onChange={this.handleInputChange} />
                  </FormControl>
                </Grid>
                { openError ?
                  <Grid item xs={12}>
                    <ErrorSnack open={openError} message={error} handleClose={this.handleClickCloseError} />
                  </Grid>
                : null }
                <Grid item xs={12}>
                  <Button variant="contained" color="primary" className={classes.button} type="submit">
                    Sign up
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    You have already an account? {" "} <Link onClick={this.handleClickToSignIn}>Sign in</Link>
                  </Typography>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  signUpOpen: state.authentication.signUpOpen,
})

const mapDispatchToProps = dispatch => ({
  signUpClose: () => dispatch(signUpClose()),
  signInOpen: () => dispatch(signInOpen())
})

export default withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps
)(SignUpCavi));
