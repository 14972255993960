import React, { Component } from 'react';
import { connect } from 'react-redux';
import { authStateChange } from './common/auth/actions';
import { Authenticator, Greetings, SignIn, SignUp, ConfirmSignUp, ForgotPassword, RequireNewPassword } from 'aws-amplify-react';
import {
  BrowserRouter as Router,
} from 'react-router-dom';
import SignInCavi from './common/auth/components/SignInCavi';
import SignUpCavi from './common/auth/components/SignUpCavi';
import Notifier from './common/notifications/components/Notifier';
import ConfirmSignUpCavi from './common/auth/components/ConfirmSignUpCavi';
import ForgotPasswordCavi from './common/auth/components/ForgotPasswordCavi';
import RequireNewPasswordCavi from './common/auth/components/RequireNewPasswordCavi';
import AppContent from './AppContent';

/**
 * Central app component which defines the header, the content and the router.
 */
class App extends Component {

  handleAuthStateChange = (state) => {
    this.props.authStateChange(state)
  }

  render() {
    return (
      <Router>
          <Authenticator hide={[Greetings, SignIn, SignUp, ConfirmSignUp, ForgotPassword, RequireNewPassword]} onStateChange={this.handleAuthStateChange}>
            <SignInCavi/>
            <SignUpCavi/>
            <ConfirmSignUpCavi/>
            <ForgotPasswordCavi/>
            <RequireNewPasswordCavi/>
            <Notifier />
            <AppContent />
          </Authenticator>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  authState: state.authentication.authState.state
})

const mapDispatchToProps = dispatch => ({
  authStateChange: (state) => dispatch(authStateChange(state))
})

export default connect(mapStateToProps, mapDispatchToProps) (App);
