import React, { Component } from "react";
import { API } from "aws-amplify";
import { PLAYER_API } from "../../../common/middleware/api.js";
import { connect } from "react-redux";
import { registerDevice, loadDevices } from "../actions";
import { addNotification } from "../../../common/notifications/actions";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import DialogContentText from "@material-ui/core/DialogContentText";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { labelRegex } from "../../../common/Utilities";

const styles = (theme) => ({
  root: {},
  button: {
    marginTop: theme.spacing.unit * 4,
  },
});

class DeviceRegistration extends Component {
  state = {};

  componentDidMount() {
    this.stateReset();
  }

  componentWillUnmount() {}

  stateReset = () => {
    this.setState({
      deviceId: "",
      deviceLabel: "",
      alreadyRegistered: false,
    });
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  checkIfDeviceIsAlreadyRegistered = (receivedDeviceId) => {
    this.props.devices.map((device) => {
      if (device.id === receivedDeviceId) {
        this.setState({
          alreadyRegistered: true,
          deviceLabel: device.deviceOriginal.label,
        });
      }
      return true;
    });
  };

  registerDevice = () => {
    if (this.verfiyDeviceLabel(this.state.deviceLabel)) {
      API.post(PLAYER_API, "/devices", {
        headers: {},
        body: {
          id: this.state.deviceId,
          label: this.state.deviceLabel,
        },
        response: true,
      })
        .then((response) => {
          console.log("Device register successful");
          this.props.addNotification("Device registration successful", "info");
          this.props.history.push("/console/device/");
        })
        .catch((error) => {
          console.log("Unable to register device: " + error.response.data);
          this.props.addNotification(error.response.data, "error");
        });
    }
  };

  verfiyDeviceLabel = (label) => {
    if (label.length < 4 || label.length > 25) {
      this.props.addNotification(
        "Device name must have 4-25 characters",
        "error"
      );
      return false;
    } else if (!labelRegex.test(label)) {
      this.props.addNotification(
        "Device name can just contain the following characters: a-z, A-Z, 0-9, -, _",
        "error"
      );
      return false;
    }
    return true;
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4">Register Device</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <DialogContentText>
              Register your device and define a device label. Note that you need
              to setup your device (Raspberry Pi) first and connect it to the
              Internet. Once you have done this you will be provided with a
              device id. Note down this id and use it here to register your
              device.
            </DialogContentText>
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              id="deviceId"
              name="deviceId"
              label="Device Id"
              placeholder="Device Id"
              className={classes.textField}
              margin="normal"
              onChange={this.handleInputChange}
              value={this.state.deviceId}
              fullWidth
            />
          </Grid>
          <Grid item md={8} xs={0}></Grid>
          <Grid item md={4} xs={12}>
            <TextField
              id="deviceLabel"
              name="deviceLabel"
              label="Device Label"
              placeholder="Device name"
              className={classes.textField}
              margin="normal"
              onChange={this.handleInputChange}
              value={this.state.deviceLabel}
              fullWidth
            />
          </Grid>
          <Grid item md={8} xs={0}></Grid>
          <Grid item xs={12}>
            <Button
              className={classes.button}
              color="primary"
              variant="contained"
              onClick={this.registerDevice}
            >
              Register Device
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  registerSuccess: state.devices.registerSuccess,
  devices: state.devices.items,
});

const mapDispatchToProps = (dispatch) => ({
  registerDevice: (deviceId, deviceLabel) =>
    dispatch(registerDevice(deviceId, deviceLabel)),
  addNotification: (message, variant) =>
    dispatch(addNotification(message, variant)),
  loadDevices: () => dispatch(loadDevices()),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(DeviceRegistration)
);
