export const GET_PLAYER_REQUEST = 'GET_PLAYER_REQUEST'
export const GET_PLAYER_SUCCESS = 'GET_PLAYER_SUCCESS'
export const GET_PLAYER_FAILURE = 'GET_PLAYER_FAILURE'
export const PLAYER_PLAY_REQUEST = 'PLAYER_PLAY_REQUEST'
export const PLAYER_PLAY_SUCCESS = 'PLAYER_PLAY_SUCCESS'
export const PLAYER_PLAY_FAILURE = 'PLAYER_PLAY_FAILURE'
export const PLAYER_STOP_REQUEST = 'PLAYER_STOP_REQUEST'
export const PLAYER_STOP_SUCCESS = 'PLAYER_STOP_SUCCESS'
export const PLAYER_STOP_FAILURE = 'PLAYER_STOP_FAILURE'
export const PLAYER_SET = 'PLAYER_SET'
export const PLAYER_CONFIG_SET = 'PLAYER_CONFIG_SET'
