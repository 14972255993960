import * as t from './actionTypes';

export const signInOpen = () => ({
    type: t.SIGN_IN_OPEN
})

export const signInClose = () => ({
    type: t.SIGN_IN_CLOSE
})

export const signUpOpen = () => ({
    type: t.SIGN_UP_OPEN
})

export const signUpClose = () => ({
    type: t.SIGN_UP_CLOSE
})

export const enterConsole = () => ({
    type: t.ENTER_CONSOLE
})

export const leaveConsole = () => ({
    type: t.LEAVE_CONSOLE
})

export function authStateChange(state) {
  return { type: t.AUTH_STATE_CHANGE, state }
}
