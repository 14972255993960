import React from "react";
import { ConfirmSignUp } from "aws-amplify-react";
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import ErrorSnack from '../../notifications/components/ErrorSnack';

const styles = theme => ({
  formControl: {
    margin: theme.spacing.unit,
  },
  button: {
    margin: theme.spacing.unit,
    align: 'center'
  }
});

class ConfirmSignUpCavi extends ConfirmSignUp {

  state = {
    open: true,
    openError: false,
    error: ''
  }

  constructor(props) {
    super(props);
    this._validAuthStates = ["confirmSignUp"];
  }

  handleClickToSignIn = () => {
    super.changeState("signIn");
    this.props.signUpClose();
    this.props.signInOpen();
  }

  error(err) {
    this.setState({openError: true, error: err.message});
  }

  handleClickCloseError = () => {
    this.setState({openError: false});
  }

  handleClickConfirmSignUpClose = () => {
    this.setState({open: false, openError: false});
  }


  showComponent(theme) {
    const { classes, authState } = this.props;
    const { openError, error } = this.state;
    const username = this.usernameFromAuthData();
    const open = (authState === 'confirmSignUp') ? true : false;
    return (
      <Dialog
          open={open}
          onClose={() => this.changeState('signIn')}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Confirm Sign up</DialogTitle>
          <DialogContent>
            <Grid container spacing={3}>

              <Grid item xs={12}>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel htmlFor="username">Username</InputLabel>
                  <Input
                    id="username"
                    key="username"
                    name="username"
                    onChange={this.handleInputChange}
                    disabled={username}
                    value={username ? username : ""} />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel htmlFor="code">Confirmation Code</InputLabel>
                  <Input id="code" key="code" name="code" type="code" onChange={this.handleInputChange} autoComplete="off" />
                </FormControl>
              </Grid>
              { openError ?
                <Grid item xs={12}>
                  <ErrorSnack open={openError} message={error} handleClose={this.handleClickCloseError} />
                </Grid>
              : null }
              <Grid item xs={12}>
                <Button variant="contained" color="primary" className={classes.button} onClick={this.confirm}>
                  Confirm
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  Lost your code?{" "} <Link onClick={this.resend}>Resend Code</Link>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  Back to {" "} <Link onClick={() => this.changeState('signIn')}>Sign In</Link>
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(styles)(ConfirmSignUpCavi);
