import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import Typography from '@material-ui/core/Typography';
import { theme } from '../AppThemeMain.js';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import CssBaseline from "@material-ui/core/CssBaseline";
import Media from "react-media";

import { playPlayer } from './actions'

import clock1 from './images/clock1.jpg';
import clock2 from './images/clock2.jpg';

const styles = () => ({
  root: {
    width: 'auto',
    [theme.breakpoints.up('md')]: {
      width: theme.main.widthDesktop,
      marginLeft: 'auto',
      marginRight: 'auto',
    }
  },
  typo: {
    marginBottom: '30px'
  },
  grid: {
    padding: '30px'
  }
});


const watches = [
  {
    img: clock1,
    title: 'Pinbo Simulation Reloje Quarz',
    text: 'Casual, Wooden Color Leather Strap',
    shapes: [
      {
        type: "box",
        centerX: 0.125,
        centerY: 0.5,
        width: 0.25,
        height: 1.0,
        color: "#FF0055"
      }
    ]
  },
  {
    img: clock2,
    title: 'New Trend Unisex Watch',
    text: 'Analogue Display, Quartz, Faux Leather Strap, Chronograph Watch with Pin Buckle',
    shapes: [
      {
        type: "box",
        centerX: 0.375,
        centerY: 0.5,
        width: 0.25,
        height: 1.0,
        color: "#FF0055"
      }
    ]
  }
];



class Watches extends Component {

  state = {
  };

  play = (shapes) => {
    //const player = this.props.selectedPlayer;
    const player = "LisaBalkon"
    const config = {
      shapes: shapes
    };
    this.props.playPlayer(player, "shape", config);
  }

  renderTiles = (cols) => {
    return (
      <GridList cellHeight={350} spacing={3} cols={cols}>
        {watches.map(watch => (
          <GridListTile
            key={watch.img}
            onClick={() => this.play(watch.shapes)}
          >
            <img src={watch.img} alt={watch.title} onClick={() => this.play(watch.shapes)}/>
            <GridListTileBar
              title={watch.title}
              subtitle={watch.text}
            />
          </GridListTile>
        ))}
      </GridList>
    );
  }

	render () {
		const { classes } = this.props;
		return (
      <MuiThemeProvider theme={theme}>
				<CssBaseline />
          <div className={classes.root}>
            <Grid container spacing={3} className={classes.grid}>
              <Grid item xs={12}>
                <Typography variant="h4" color="primary" className={classes.typo}>
                  Interactive Watch Shelve
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Media query="(max-width: 599px)">
                  {matches =>
                  matches ? (
                    this.renderTiles(1)
                  ) :
                    this.renderTiles(2)
                  }
                </Media>
              </Grid>
            </Grid>
          </div>
      </MuiThemeProvider>
    );
	}
}

const mapStateToProps = state => ({
    selectedPlayer: state.watches.selectedPlayer,
})

const mapDispatchToProps = dispatch => ({
    playPlayer: (player, type, config) => dispatch(playPlayer(player, type, config)),
})

export default withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps
)(Watches))
