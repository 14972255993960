import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  enterDeviceUpdateMode,
  leaveDeviceUpdateMode,
  updateDevice,
  loadDevices,
  invalidateDevices,
} from "../actions";
import DeviceItem from "./DeviceItem";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import RefreshIcon from "@material-ui/icons/Refresh";

const styles = (theme) => ({
  button: {
    marginTop: theme.spacing.unit,
  },
  header: {
    display: "flex",
  },
  reload: {
    color: theme.palette.primary.light,
    marginLeft: "auto",
    float: "right",
    marginTop: theme.spacing.unit,
  },
});

class DeviceList extends Component {
  componentDidMount() {
    this.props.reloadDevices();
  }

  openDeviceSetup = () => {
    this.props.history.push("/console/device/register");
  };

  render() {
    const { classes } = this.props;
    // it is possible that no devices have been loaded yet, this case needs to be handled
    const deviceListItems =
      Object.keys(this.props.devices).length === 0 &&
      this.props.devices.constructor === Object
        ? ""
        : this.props.devices.map((device) => (
            <DeviceItem
              key={device.id}
              {...device.deviceOriginal}
              onClickEnterUpdateMode={() =>
                this.props.enterDeviceUpdateMode(device)
              }
            />
          ));
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4">Manage Devices</Typography>
        </Grid>
        <Grid item xs={12} className={classes.header}>
          <Button
            className={classes.button}
            variant="outlined"
            color="primary"
            onClick={this.openDeviceSetup}
          >
            Add new device
          </Button>
          <IconButton
            onClick={this.props.reloadDevices}
            className={classes.reload}
          >
            <RefreshIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <List>{deviceListItems}</List>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  devices: state.devices.items,
  invalid: state.devices.invalid,
  register: state.devices.register,
});

const mapDispatchToProps = (dispatch) => ({
  enterDeviceUpdateMode: (device) => dispatch(enterDeviceUpdateMode(device)),
  leaveDeviceUpdateMode: (device) => dispatch(leaveDeviceUpdateMode(device)),
  updateDevice: (id, label, updatedDeviceConfig) =>
    dispatch(updateDevice(id, label, updatedDeviceConfig)),
  reloadDevices: () => dispatch(loadDevices()),
  invalidateDevices: () => dispatch(invalidateDevices()),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(DeviceList)
);
