export const REGISTER_DEVICE_REQUEST = "REGISTER_DEVICE_REQUEST";
export const REGISTER_DEVICE_SUCCESS = "REGISTER_DEVICE_SUCCESS";
export const REGISTER_DEVICE_FAILURE = "REGISTER_DEVICE_FAILURE";
export const ADD_DEVICE = "ADD_DEVICE";
export const UPDATE_DEVICE_REQUEST = "UPDATE_DEVICE_REQUEST";
export const UPDATE_DEVICE_SUCCESS = "UPDATE_DEVICE_SUCCESS";
export const UPDATE_DEVICE_FAILURE = "UPDATE_DEVICE_FAILURE";
export const ENTER_DEVICE_UPDATE_MODE = "ENTER_DEVICE_UPDATE_MODE";
export const LEAVE_DEVICE_UPDATE_MODE = "LEAVE_DEVICE_UPDATE_MODE";
export const INVALIDATE_DEVICES = "INVALIDATE_DEVICES";
export const GET_DEVICE_REQUEST = "GET_DEVICE_REQUEST";
export const GET_DEVICE_SUCCESS = "GET_DEVICE_SUCCESS";
export const GET_DEVICE_FAILURE = "GET_DEVICE_FAILURE";
export const DELETE_DEVICE_REQUEST = "DELETE_DEVICE_REQUEST";
export const DELETE_DEVICE_SUCCESS = "DELETE_DEVICE_SUCCESS";
export const DELETE_DEVICE_FAILURE = "DELETE_DEVICE_FAILURE";
export const SET_SELECTED_DEVICE = "SET_SELECTED_DEVICE";
export const SET_DEVICE_IN_CONFIGURATION = "SET_DEVICE_IN_CONFIGURATION";
export const DELETE_OUTPUT_IN_DEVICE_CONFIGURATION =
  "DELETE_OUTPUT_IN_DEVICE_CONFIGURATION";
export const UPDATE_OUTPUT_IN_DEVICE_CONFIGURATION =
  "UPDATE_OUTPUT_IN_DEVICE_CONFIGURATION";
export const ADD_OUTPUT_IN_DEVICE_CONFIGURATION =
  "ADD_OUTPUT_IN_DEVICE_CONFIGURATION";
export const SET_OUTPUT_IN_CONFIGURATION = "SET_OUTPUT_IN_CONFIGURATION";
