import React, { Component } from "react";
import AppList from "./AppList";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import { Route } from "react-router-dom";
import App from "./apps/App.js";
import Grid from "@material-ui/core/Grid";

const styles = (theme) => ({});

/**
 * Central page for controlling visualization apps
 */
class Apps extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">Visualization Apps</Typography>
          </Grid>
          <Grid item xs={12}>
            <Route exact path="/console/app" render={(props) => <AppList />} />
            <Route
              exact
              path="/console/app/blink"
              render={(props) => <App type="blink" />}
            />
            <Route
              exact
              path="/console/app/pulse"
              render={(props) => <App type="pulse" />}
            />
            <Route
              exact
              path="/console/app/twinkle"
              render={(props) => <App type="twinkle" />}
            />
            <Route
              exact
              path="/console/app/color"
              render={(props) => <App type="color" />}
            />
            <Route
              exact
              path="/console/app/stream"
              render={(props) => <App type="stream" />}
            />
            <Route
              exact
              path="/console/app/image"
              render={(props) => <App type="image" />}
            />
            <Route
              exact
              path="/console/app/text"
              render={(props) => <App type="text" />}
            />
            <Route
              exact
              path="/console/app/clock"
              render={(props) => <App type="clock" />}
            />
            <Route
              exact
              path="/console/app/progress"
              render={(props) => <App type="progress" />}
            />
            <Route
              exact
              path="/console/app/manual"
              render={(props) => <App type="manual" />}
            />
            <Route
              exact
              path="/console/app/teststreams"
              render={(props) => <App type="teststreams" />}
            />
            <Route
              exact
              path="/console/app/shapes"
              render={(props) => <App type="shapes" />}
            />
            <Route
              exact
              path="/console/app/playlists"
              render={(props) => <App type="playlists" />}
            />
            <Route
              exact
              path="/console/app/dimmer"
              render={(props) => <App type="dimmer" />}
            />
            <Route
              exact
              path="/console/app/website"
              render={(props) => <App type="website" />}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(Apps)
);
