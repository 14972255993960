import React from "react";
import { withStyles } from "@material-ui/core/styles";
import LayoutBody from "./MainLayoutBody";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import MainCheck from "./MainCheck";

const styles = (theme) => ({
  root: {
    display: "flex",
    overflow: "hidden",
    backgroundColor: theme.main.backgroundDark,
    backgroundImage:
      "linear-gradient(to top, rgba(14,15,31,1), rgba(16,18,36,1))",
  },
  layoutBodyLeft: {
    alignItems: "left",
    marginTop: theme.spacing.unit * 20,
    marginBottom: theme.spacing.unit * 20,
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing.unit * 8,
      marginBottom: theme.spacing.unit * 8,
    },
  },
  grid: {
    flexGrow: 1,
  },
  preTitle: {
    marginBottom: 0,
  },
  title: {
    marginBottom: theme.spacing.unit * 4,
  },
  subtitle: {
    marginBottom: theme.spacing.unit,
  },
  subtext: {
    marginBottom: theme.spacing.unit * 2,
  },
  maxSpacing: {
    maxWidth: 350,
  },
  icon: {
    marginBottom: theme.spacing.unit * 1,
    marginRight: theme.spacing.unit * 1,
  },
  link: {
    marginTop: theme.spacing.unit * 4,
    marginBottom: theme.spacing.unit * 4,
    color: theme.palette.text.primary,
    width: "100%",
  },
  linkContainer: {
    align: "right",
  },
});

class MainInputOutput extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <section className={classes.root}>
        <LayoutBody className={classes.layoutBodyLeft}>
          <Grid container spacing={4} alignContent="center">
            <Grid item xs={12}>
              <Typography
                variant="subtitle2"
                align="left"
                className={classes.preTitle}
              >
                <font color="3D5AFE">Versatile input, </font>{" "}
                <font color="00E676">flexible output</font>
              </Typography>
              <Typography variant="h2" align="left" className={classes.title}>
                An easier way to build digital signage solutions
              </Typography>
            </Grid>
            <Grid item xs={12} md={5}>
              <Grid item xs={12}>
                <Typography
                  variant="h4"
                  align="left"
                  className={classes.subtitle}
                >
                  Play your digital content
                </Typography>
                <Typography
                  style={{ marginBottom: 20 }}
                  variant="body1"
                  align="left"
                >
                  Select from a growing variety of configurable visualization
                  apps to bring your digital content to your devices.
                </Typography>
                <Grid
                  container
                  spacing={1}
                  alignContent="center"
                  className={classes.checkboxes}
                >
                  <Grid item xs={12} md={6}>
                    <MainCheck text="Video" color="secondary" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MainCheck text="Images" color="secondary" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MainCheck text="Websites" color="secondary" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MainCheck text="Animations" color="secondary" />
                  </Grid>
                </Grid>
                <Typography align="left" className={classes.link}>
                  <Link
                    href="http://developer.easyvis.io/docs/visualization-apps/"
                    className={classes.link}
                    underline="always"
                  >
                    More about visualization apps
                  </Link>
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} md={2}></Grid>
            <Grid item xs={12} md={5}>
              <Grid item xs={12}>
                <Typography
                  variant="h4"
                  align="left"
                  className={classes.subtitle}
                >
                  Connect various devices
                </Typography>
                <Typography
                  variant="body1"
                  align="left"
                  style={{ marginBottom: 20 }}
                >
                  Choose from a growing number of supported outputs and get your
                  digital content converted to your visualization device.
                </Typography>
                <Grid
                  container
                  spacing={1}
                  alignContent="center"
                  alignItems="center"
                  className={classes.checkboxes}
                >
                  <Grid item xs={12} md={6}>
                    <MainCheck text="Android TV" color="primary" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MainCheck text="Android Tablet" color="primary" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MainCheck text="Raspberry Pi - Artnet" color="primary" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MainCheck text="Raspberry Pi - SPI" color="primary" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    &nbsp;
                  </Grid>
                </Grid>
                <Typography align="left" className={classes.link}>
                  <Link
                    href="http://developer.easyvis.io/docs/visualization-devices/"
                    className={classes.link}
                    underline="always"
                  >
                    More about visualization devices
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </LayoutBody>
      </section>
    );
  }
}

export default withStyles(styles)(MainInputOutput);
