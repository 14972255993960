import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ColorPicker from 'material-ui-color-picker';


const styles = theme => ({
});

/**
 * Easyvis.io color picker
 */
class EasyvisColorPicker extends Component {

  render () {
    return (
      <ColorPicker
        {... this.props}
      />
    )
  }
}

export default withStyles(styles)(EasyvisColorPicker);
