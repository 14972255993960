import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import Typography from '@material-ui/core/Typography';

import { playPlayer } from '../actions'

import loveImage from '../images/messages/love.jpg';
import kidsImage from '../images/messages/kids.jpg';
import cleanupImage from '../images/messages/cleanup.jpg';
import dogHungryImage from '../images/messages/dogHungry.jpg';
import thanksImage from '../images/messages/thanks.jpg';


const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  title: {
  },
});


const messagesData = [
   {
     img: loveImage,
     title: 'I love you',
     text: 'I love you',
     color: 'Red'
   },
   {
     img: kidsImage,
     title: 'Do not forget the kids',
     text: 'Do not forget the kids',
     color: 'Green'
   },
   {
     img: cleanupImage,
     title: 'Please, clean up',
     text: 'Please, clean up',
     color: 'Blue'
   },
   {
     img: dogHungryImage,
     title: 'Our pet is hungry',
     text: 'Our pet is hungry',
     color: 'Red'
   },
   {
     img: thanksImage,
     title: 'Thank you',
     text: 'Thank you',
     color: 'Blue'
   },
];



class Messages extends Component {

  state = {
  };

  startMessagePlayback = (text, color) => {
    const player = this.props.selectedPlayer;
    const config = {
      input: text,
      color: color,
      type: 'scroll',
      horizontalAlignment: 'right',
      verticalAlignment: 'middle',
      speed: 'medium'
    };
    this.props.playPlayer(player, "text", config);
  }

	render () {
		const { classes } = this.props;
		return (
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">
              Messages
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <GridList cellHeight={120} className={classes.gridList} cols={1}>
              {messagesData.map(message => (
                <GridListTile
                  key={message.img}
                  cols={1}
                  onClick={() => this.startMessagePlayback(message.text, message.color)}
                >
                  <img src={message.img} alt={message.title} onClick={() => this.startMessagePlayback(message.text, message.color)}/>
                  <GridListTileBar
                    title={message.title}
                    classes={{
                      title: classes.title,
                    }}
                  />
                </GridListTile>
              ))}
            </GridList>
          </Grid>
        </Grid>
      </div>
    );
	}
}

const mapStateToProps = state => ({
    selectedPlayer: state.kitchen.selectedPlayer,
})

const mapDispatchToProps = dispatch => ({
    playPlayer: (player, type, config) => dispatch(playPlayer(player, type, config)),
})

export default withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps
)(Messages))
