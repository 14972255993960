import { createMuiTheme } from "@material-ui/core/styles";
import red from "@material-ui/core/colors/red";

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#26e676",
    },
    secondary: {
      main: "#3d5afe",
    },
    thirdly: {
      main: "#fb173d",
    },
    text: {
      primary: "#FFFFFF",
      secondary: "#26e676",
    },
    error: red,
    background: {
      default: "#0E0F1F",
    },
  },
  overrides: { MuiButton: { root: { fontWeight: 700 } } },
  typography: {
    useNextVariants: true,
    // note that special fonts need to be added to the package.json (check https://github.com/KyleAMathews/typefaces/tree/master/packages)
    // and added to the index.js for import
    fontFamily: '"Muli", Arial, Helvetica, sans-serif',
    htmlFontSize: 10,
    fontWeightMedium: 500,
    h1: {
      fontSize: "8rem",
      letterSpacing: -0.8,
      lineHeight: 1.15,
      fontWeight: 800,
      '@media (max-width:600px)': {
        fontSize: "4.8rem",
        fontWeight: 700,
        marginBottom: 0,
      },
    },
    h2: {
      fontSize: "4.2rem",
      letterSpacing: -0.4,
      lineHeight: 1.2,
      fontWeight: 700,
      '@media (max-width:600px)': {
        fontSize: "3.6rem",
        fontWeight: 700,
        marginBottom: 0,
      },
    },
    h3: {
      fontSize: "3.2rem",
      letterSpacing: -0.2,
      lineHeight: 1.2,
      fontWeight: 700,
    },
    h4: {
      fontSize: "2rem",
      letterSpacing: -0.2,
      lineHeight: 1.3,
      fontWeight: 700,
    },
    h6: {
      fontSize: "1.7rem",
      letterSpacing: -0.1,
      lineHeight: 1.5,
      fontWeight: 700,
    },
    subtitle1: {
      fontSize: "2rem",
      letterSpacing: -0.1,
      lineHeight: 1.5,
    },
    subtitle2: {
      fontSize: "1.7rem",
      letterSpacing: -0.1,
      lineHeight: 1.5,
    },
    body1: {
      fontSize: "1.7rem",
      letterSpacing: -0.1,
      lineHeight: 1.5,
      color: "#919BAB",
    },
  },
  main: {
    widthDesktop: 1000,
    backgroundDark: "#0E0F1F",
    backgroundLight: "#141529",
  },
});
