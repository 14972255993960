import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import EasyvisColorPicker from "../../../../common/EasyvisColorPicker";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 220,
  },
});

class ColorApp extends Component {
  // the state represents the config of the widget
  state = {
    color: "#000000",
  };

  componentDidMount() {
    // report initial state
    this.setStateCallback();
  }

  handleChange = (event) => {
    this.setState(
      { [event.target.name]: event.target.value },
      this.setStateCallback
    );
  };

  handleColorChange = (color) => {
    this.setState({ color: color }, this.setStateCallback);
  };

  setStateCallback = () => {
    this.props.onConfigChange(this.state);
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <form className={classes.root} autoComplete="off">
          <FormControl className={classes.formControl}>
            <EasyvisColorPicker
              value={this.state.color}
              onChange={this.handleColorChange}
              convert="hex"
              id="color"
              name="color"
              label="Color"
              fullWidth
              defaultValue="#FFFFFF"
            />
          </FormControl>
        </form>
      </div>
    );
  }
}

export default withStyles(styles)(ColorApp);
