import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import { Field, reduxForm, formValueSelector} from 'redux-form';
import { required } from 'redux-form-validators'
import { renderField } from '../../../../../common/Utilities'
import FormControl from '@material-ui/core/FormControl';
import MediaList from '../../../../media/components/MediaList';
import { updatePlaylistAPI, getPlaylistsAPI, deletePlaylistAPI, createPlaylistAPI } from '../../../../media/actions'; 
import { connect } from 'react-redux';
import PlaylistItemAdd from './PlaylistItemAdd';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { v1 as uuidv1 } from 'uuid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';


const styles = theme => ({
    list: {
        display: 'flex',
        '& > *': {
          margin: theme.spacing(2),
        },
      },
    paper: {
        padding: theme.spacing(2)
    },
    submitButtons: {
        marginTop: theme.spacing(2)
    }
});

class PlaylistEdit extends Component {

    constructor() {
        super();
        this.state = {
            openAddItemDialog: false,
            confirmDeleteOpen: false,
            playlistToDelete: null
        };
    }

    verifyName = (value) => {
        return value && !/^[0-9a-zA-Z_-]{1,20}$/i.test(value) ? 'Invalid playlist name' : undefined
    }

    submit = values => {
        if (this.props.editNewItem) {
            this.props.createAndReloadPlaylist(values);
        } else {
            this.props.updateAndReloadPlaylist(values.id, values);
        }
        this.props.leaveEditMode();
    }

    deletePlaylistItem = (playlistItem) => {
        var items = this.props.formPlaylistItems;
        var itemsNew = items.filter(function(value, index, arr){ 
            return value.id !== playlistItem.id;
        });
        this.props.change("items", itemsNew);
    }

    addPlaylistItem = (name, uri, type, duration) => {
        const newItem = {
            id: uuidv1(),
            name: name,
            type: type,
            uri: uri
        };
        if (duration !== null && !isNaN(duration)) {
            newItem.duration = duration;
        }
        var items = this.props.formPlaylistItems;
        var itemsNew = items.concat([newItem]);    
        this.props.change("items", itemsNew);
    }

    openAddItemDialog = () => {
        this.setState({openAddItemDialog: true});
    }

    closeAddItemDialog = () => {
        this.setState({openAddItemDialog: false});
    }

    setConfirmDeleteOpen = (value) => {
        this.setState({
            confirmDeleteOpen: value
        })
    }

    deletePlaylist = () => {
        const playlistId = this.props.initialValues.id;
        this.props.deleteAndReloadPlaylist(playlistId);
        this.setConfirmDeleteOpen(false);
        this.props.leaveEditMode();
    }
    
	render () {        
        const { classes, handleSubmit } = this.props;
        console.log(this.props.initialValues)
        var items = this.props.initialValues.items
        if (typeof this.props.formPlaylistItems !== 'undefined') {
            items = this.props.formPlaylistItems
        }        
		return (
            <form onSubmit={handleSubmit(this.submit)}>
                <Grid
                container
                spacing={1}
                alignContent="center"
                alignItems="center"
                >
                    <Grid item xs={12} align="left" alignContent="center">
                        <FormControl className={classes.formTop}>
                            <Field className={classes.textField} name="name" component={renderField}
                                label= "Playlist Name" type="text" validate={[required(), this.verifyName]} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} align="right" alignContent="center">
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<AddBoxIcon />}
                            onClick={this.openAddItemDialog}
                        >
                            Add Media
                        </Button>
                    </Grid>
                    <Grid item xs={12} align="center" alignContent="center">
                        <MediaList
                            media={items} 
                            nofilter
                            deleteEnabled
                            deleteCallback={this.deletePlaylistItem}
                        />
                    </Grid>
                    <Grid item xs={12} align="left" alignContent="center" className={classes.submitButtons}>
                        <Button 
                            variant="contained" 
                            color="primary" 
                            onClick={handleSubmit(this.submit)}
                            type="submit"
                            autoFocus>
                            Save
                        </Button>
                        <Button 
                            color="primary" 
                            onClick={this.props.leaveEditMode}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item xs={12} align="right" alignContent="center" className={classes.submitButtons}>
                        <Button 
                            variant="outlined" 
                            color="primary" 
                            onClick={() => this.setConfirmDeleteOpen(true)}>
                            Delete Playlist
                        </Button>
                    </Grid>
                </Grid>
                <PlaylistItemAdd 
                    onClickLeave={this.closeAddItemDialog}
                    open={this.state.openAddItemDialog}
                    addMedia={this.addPlaylistItem}
                />
                <ConfirmDialog
                        open={this.state.confirmDeleteOpen}
                        setOpen={this.setConfirmDeleteOpen}
                        onConfirm={this.deletePlaylist}
                    />
            </form>
    );
	}
}

const selector = formValueSelector('playlistEdit')

const mapStateToProps = state => ({
    formPlaylistItems: selector(state, 'items'),
})
  
const mapDispatchToProps = dispatch => ({
    updateAndReloadPlaylist: (playlistId, playlistItem) => {
        dispatch(updatePlaylistAPI(playlistId, playlistItem))
            .then(() => dispatch(getPlaylistsAPI()));
    },
    createAndReloadPlaylist: (playlistItem) => {
        dispatch(createPlaylistAPI(playlistItem))
            .then(() => dispatch(getPlaylistsAPI()));
    },
    deleteAndReloadPlaylist: (playlistId) => {
        dispatch(deletePlaylistAPI(playlistId))
            .then(() => dispatch(getPlaylistsAPI()));
    }
})

PlaylistEdit = reduxForm({
    form: 'playlistEdit'
  })(PlaylistEdit)

export default withRouter(withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps
  )(PlaylistEdit)))


  const ConfirmDialog = ({ open, setOpen, onConfirm }) => {
    return (
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="confirm-dialog"
      >
        <DialogTitle id="confirm-dialog">Delete Playlist</DialogTitle>
        <DialogContent>
            Are you sure to delete the playlist?
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpen(false)}
            color="primary"
          >
            No
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setOpen(false);
              onConfirm();
            }}
            color="primary" 
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
