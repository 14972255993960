import React from "react";
import { withStyles } from "@material-ui/core/styles";
import LayoutBody from "./MainLayoutBody";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import enterpriseWorldMap from "../images/enterpriseWorldMap.png";

const styles = (theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    backgroundColor: theme.main.backgroundDark,
    backgroundImage:
      "linear-gradient(to top, rgba(14,15,31,1), rgba(16,18,36,1))",
  },
  layoutBodyLeft: {
    alignItems: "left",
    marginTop: theme.spacing.unit * 20,
    marginBottom: theme.spacing.unit * 20,
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing.unit * 8,
      marginBottom: theme.spacing.unit * 8,
    },
  },
  grid: {
    flexGrow: 1,
  },
  title: {
    marginBottom: theme.spacing.unit * 2,
  },
  subtitle: {
    marginBottom: theme.spacing.unit * 4,
  },
  subtext: {
    marginBottom: theme.spacing.unit * 4,
    maxWidth: 350,
  },
  link: {
    marginBottom: theme.spacing.unit * 4,
    color: theme.palette.text.primary,
    width: "100%",
  },
  linkContainer: {
    align: "right",
  },
  preTitle: {
    color: theme.palette.secondary.main,
  },
  img: {
    width: "85%",
  },
});

class MainEnterprises extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <section className={classes.root}>
        <LayoutBody className={classes.layoutBodyLeft}>
          <Grid container spacing={3} alignContent="center" alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography
                variant="subtitle2"
                align="left"
                className={classes.preTitle}
              >
                For enterprises
              </Typography>
              <Typography variant="h2" align="left" className={classes.title}>
                Global scale? No problem
              </Typography>
              <Typography
                variant="body1"
                align="left"
                className={classes.subtitle}
              >
                Build professional enterprise visualization solution with
                easyvis.io. You can manage and monitor your worldwide fleet of
                visualization devices and run our easyvis.io software on your
                customized hardware. Concentrate on your business, we care about
                the technical stuff.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} align="center">
              <img
                src={enterpriseWorldMap}
                alt="Enterprise World Map"
                className={classes.img}
              />
            </Grid>
          </Grid>
        </LayoutBody>
      </section>
    );
  }
}

export default withStyles(styles)(MainEnterprises);
