import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import easyvisLogoLong from "../images/logoLongDark.svg";
import { theme } from "../../AppThemeMain.js";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";

const styles = (theme) => ({
  root: {
    margin: theme.spacing.unit * 7,
    arginBottom: theme.spacing.unit * 12,
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    height: "100%",
    width: "auto",
  },
  grid: {
    width: "100%",
  },
  text: {
    margin: theme.spacing.unit * 3,
  },
});

class AboutUs extends Component {
  render() {
    const { classes } = this.props;
    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <div className={classes.root}>
          <Grid
            container
            spacing={3}
            alignContent="center"
            alignItems="center"
            className={classes.grid}
          >
            <Grid item xs={12} align="left">
              <img src={easyvisLogoLong} alt="easyvis logo" height="40" />
            </Grid>
            <Grid item xs={12} align="cenrter">
              <Typography align="center" variant="h4">
                About us
              </Typography>
            </Grid>
            <Grid item xs={12} align="cenrter">
              <Typography variant="body1" className={classes.text}>
                easyvis.io is a{" "}
                <Link href="http://www.smartdings.com">smartdings</Link>{" "}
                product. We are a young startup from Stuttgart, Germany and we
                love to build great IT solutions. Making things (German: Ding)
                smarter is our mission.
              </Typography>
              <Typography variant="body1" className={classes.text}>
                smartdings GmbH <br />
                Weilimdorfer Straße 45 <br />
                70825 Korntal
                <br />
                <br />
                Geschäftsführer: Florian Klein und Jens Dahm
                <br />
                <br />
                Email:{" "}
                <Link href="mailto:info@smartdings.com">
                  info@smartdings.com
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </div>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(AboutUs);
