import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux'
import { playPlayer } from '../../actions'
import { addNotification } from '../../../../common/notifications/actions'

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  textField: {
    margin: theme.spacing.unit
  },
});

/**
 * Test page to test the player API
 */
class ManualAPITester extends Component {

  state = {
    type: "stream",
    config: '{"uri": "http://teststream"}'
  }

  componentDidMount() {
    // report initial state
    this.setStateCallback();
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value }, this.setStateCallback);
  }

  setStateCallback = () => {
    this.props.onConfigChange(this.state)
  }

	render () {
		const { classes } = this.props;
		return (
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              id="type"
              label="type"
              name="type"
              value={this.state.type}
              onChange={this.handleChange}
              className={classes.textField}
              margin="normal"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="config"
              label="config"
              name="config"
              multiline
              rowsMax="8"
              value={this.state.config}
              onChange={this.handleChange}
              className={classes.textField}
              margin="normal"
              variant="outlined"
            />
          </Grid>
        </Grid>
      </div>
    );
	}
}

const mapStateToProps = state => ({
  selectedPlayer: state.players.selectedPlayer,
  playerConfig: state.players.playerConfig
})

const mapDispatchToProps = dispatch => ({
  playPlayer: (player, type, config, playConfig) => dispatch(playPlayer(player, type, config, playConfig)),
  addNotification: (notification, level) => dispatch(addNotification(notification, level))
})

export default withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps
)(ManualAPITester))
