export const GET_MEDIA_COMMON_REQUEST = 'GET_MEDIA_COMMON_REQUEST'
export const GET_MEDIA_COMMON_SUCCESS = 'GET_MEDIA_COMMON_SUCCESS'
export const GET_MEDIA_COMMON_FAILURE = 'GET_MEDIA_COMMON_FAILURE'
export const GET_MEDIA_LOCAL_REQUEST = 'GET_MEDIA_LOCAL_REQUEST'
export const GET_MEDIA_LOCAL_SUCCESS = 'GET_MEDIA_LOCAL_SUCCESS'
export const GET_MEDIA_LOCAL_FAILURE = 'GET_MEDIA_LOCAL_FAILURE'
export const POST_MEDIA_LOCAL_REQUEST = 'POST_MEDIA_LOCAL_REQUEST'
export const POST_MEDIA_LOCAL_SUCCESS = 'POST_MEDIA_LOCAL_SUCCESS'
export const POST_MEDIA_LOCAL_FAILURE = 'POST_MEDIA_LOCAL_FAILURE'
export const DELETE_MEDIA_LOCAL_REQUEST = 'DELETE_MEDIA_LOCAL_REQUEST'
export const DELETE_MEDIA_LOCAL_SUCCESS = 'DELETE_MEDIA_LOCAL_SUCCESS'
export const DELETE_MEDIA_LOCAL_FAILURE = 'DELETE_MEDIA_LOCAL_FAILURE'

export const GET_PLAYLISTS_REQUEST = 'GET_PLAYLISTS_REQUEST'
export const GET_PLAYLISTS_SUCCESS = 'GET_PLAYLISTS_SUCCESS'
export const GET_PLAYLISTS_FAILURE = 'GET_PLAYLISTS_FAILURE'
export const UPDATE_PLAYLIST_REQUEST = 'UPDATE_PLAYLIST_REQUEST'
export const UPDATE_PLAYLIST_SUCCESS = 'UPDATE_PLAYLIST_SUCCESS'
export const UPDATE_PLAYLIST_FAILURE = 'UPDATE_PLAYLIST_FAILURE'
export const DELETE_PLAYLIST_REQUEST = 'DELETE_PLAYLIST_REQUEST'
export const DELETE_PLAYLIST_SUCCESS = 'DELETE_PLAYLIST_SUCCESS'
export const DELETE_PLAYLIST_FAILURE = 'DELETE_PLAYLIST_FAILURE'
export const CREATE_PLAYLIST_REQUEST = 'CREATE_PLAYLIST_REQUEST'
export const CREATE_PLAYLIST_SUCCESS = 'CREATE_PLAYLIST_SUCCESS'
export const CREATE_PLAYLIST_FAILURE = 'CREATE_PLAYLIST_FAILURE'

