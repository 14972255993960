import React from "react";
import { withStyles } from "@material-ui/core/styles";
import LayoutBody from "./MainLayoutBody";
import RequestAccessEntry from "./RequestAccessEntry";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Media from "react-media";

const styles = (theme) => ({
  root: {
    display: "flex",
    overflow: "hidden",
    backgroundColor: theme.main.backgroundLight,
    backgroundImage:
      "linear-gradient(to top, rgba(20,21,41,1), rgba(28, 29, 49, 1))",
  },
  layoutBodyLeft: {
    marginTop: theme.spacing.unit * 20,
    marginBottom: theme.spacing.unit * 20,
    alignItems: "left",
  },
  grid: {
    flexGrow: 1,
  },
  title: {
    marginBottom: theme.spacing.unit * 2,
  },
  subtitle: {
    marginBottom: theme.spacing.unit * 2,
  },
  link: {
    marginBottom: theme.spacing.unit * 4,
    color: theme.palette.text.primary,
    width: "100%",
  },
  linkContainer: {
    align: "right",
  },
  preTitle: {
    marginBottom: theme.spacing.unit * 2,
    color: theme.palette.thirdly.main,
  },
});

class MainContact extends React.Component {
  syntaxMobile = () => {
    return (
      <Grid container spacing={3} alignContent="center" alignItems="top">
        <Grid item xs={12} align="center">
          <Typography
            variant="h3"
            align="center"
            className={this.props.classes.title}
          >
            Ready to build your own digital signage solution?
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          align="left"
          className={this.props.classes.requestEntry}
        >
          <RequestAccessEntry />
        </Grid>
        <Grid item xs={12} align="center">
          <Typography
            variant="subtitle1"
            align="center"
            className={this.props.classes.title}
          >
            <font color="919BAB">Any questions?{" "}</font>
            <a href="mailto:info@easyvis.io" underline="always">
              <font color="00E676">info@easyvis.io</font>
            </a>
          </Typography>
        </Grid>
      </Grid>
    );
  };

  syntaxDesktop = () => {
    return (
      <div>
        <Grid container spacing={3} alignContent="center" alignItems="top">
          <Grid item xs={12} align="center">
            <Typography
              variant="h2"
              align="center"
              className={this.props.classes.title}
            >
              Ready to build something?
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={3} alignContent="center" alignItems="center">
          <Grid item xs={12} align="left">
            <RequestAccessEntry />
          </Grid>
          <Grid item xs={12} align="center">
            <Typography
              variant="subtitle1"
              align="center"
              
            >
              <font color="919BAB">Any questions?{" "}</font>
              <a href="mailto:info@easyvis.io" underline="always">
                <font color="3D5AFE">info@easyvis.io</font>
              </a>
            </Typography>
          </Grid>
        </Grid>
      </div>
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <section className={classes.root}>
        <LayoutBody className={classes.layoutBodyLeft}>
          <Media query="(max-width: 599px)">
            {(matches) =>
              matches ? this.syntaxMobile() : this.syntaxDesktop()
            }
          </Media>
        </LayoutBody>
      </section>
    );
  }
}

export default withStyles(styles)(MainContact);
