import React, { Component } from "react";
import { withRouter } from "react-router";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";

const styles = (theme) => ({
  chip: {
    margin: theme.spacing.unit,
  },
  margin: {
    margin: theme.spacing.unit,
  },
});

class DeviceItem extends Component {
  render() {
    const { id, label, status } = this.props;
    return (
      <div>
        <ListItem
          key={label}
          onClick={() => {
            this.props.history.push("/console/device/" + id);
          }}
          button
        >
          <ListItemText primary={label} secondary={id} />
          {status.connected ? (
            <Tooltip title="Connected">
              <RadioButtonCheckedIcon
                fontSize="small"
                style={{ color: "#76ff03" }}
              />
            </Tooltip>
          ) : (
            <Tooltip title="Disconnected">
              <RadioButtonCheckedIcon
                fontSize="small"
                style={{ color: "#f50057" }}
              />
            </Tooltip>
          )}
        </ListItem>
        <Divider />
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(DeviceItem));
