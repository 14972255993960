import * as t from './actionTypes';
import { CALL_API } from '../common/middleware/api'
import { addNotification } from '../common/notifications/actions'

const getPlayer = () => ({
  [CALL_API]: {
    types: [ t.GET_PLAYER_REQUEST, t.GET_PLAYER_SUCCESS, t.GET_PLAYER_FAILURE ],
    apiName: 'PlayerAPI',
		path: '/players',
    method: 'get',
		myInit: {
			headers: {},
			response: true
		}
  }
})

// Fetches a all players
export const loadPlayers = () => (dispatch, getState) => {
  return dispatch(getPlayer())
}

const playPlayerAPI = (player, type, config) => ({
  [CALL_API]: {
    types: [ t.PLAYER_PLAY_REQUEST, t.PLAYER_PLAY_SUCCESS, t.PLAYER_PLAY_FAILURE ],
    apiName: 'PlayerAPI',
    path: '/players/' + player + '/play',
    method: 'post',
    myInit: {
     headers: {},
     body: {
         type: type,
         config: config
      },
      response: true
   }
  }
})

export const playPlayer = (player, type, config) => (dispatch, getState) => {
  if (player === '') {
    return dispatch(addNotification("Please select a player", "info"))
  } else {
    return dispatch(playPlayerAPI(player, type, config))
  }
}

const stopPlayerAPI = (player) => ({
  [CALL_API]: {
    types: [ t.PLAYER_STOP_REQUEST, t.PLAYER_STOP_SUCCESS, t.PLAYER_STOP_FAILURE ],
    apiName: 'PlayerAPI',
    path: '/players/' + player + '/stop',
    method: 'post',
    myInit: {
     headers: {},
     response: true
   }
  }
})

export const stopPlayer = (player) => (dispatch, getState) => {
  return dispatch(stopPlayerAPI(player))
}

export const setPlayer = (player) => {
  return { type: t.PLAYER_SET, player }
}

export const openPlayerSelector = () => {
  return { type: t.PLAYER_SELECTOR_OPEN }
}

export const closePlayerSelector = () => {
  return { type: t.PLAYER_SELECTOR_CLOSE }
}
