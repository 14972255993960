import React from "react";
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import ErrorIcon from '@material-ui/icons/Error';
import CloseIcon from '@material-ui/icons/Close';

const styles = theme => ({
  snackbar: {
    margin: theme.spacing.unit,
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

class ErrorSnack extends React.Component {

  render() {
    const { classes, open, message, handleClose } = this.props;
    return (
      <SnackbarContent
        open={open}
        className={classes.error}
        message={
          <span className={classes.message}>
            <ErrorIcon className={classNames(classes.icon, classes.iconVariant)}/>
            {message}
          </span>
        }
        action={
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={handleClose}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>
        }
      />
    );
  }
}

export default withStyles(styles)(ErrorSnack);
