import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import { getMediaCommonAPI } from '../actions';
import MediaList from './MediaList';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";


const styles = theme => ({

});

class MediaCommon extends Component {

    componentDidMount() {
        this.props.loadMedia();
    }
    
	render () {
        const items = this.props.media
		return (
            <Grid
                container
                spacing={3}
                alignContent="center"
                alignItems="center"
            >
                <Grid item xs={12} align="center" alignContent="center">
                    <Typography variant="h4" align="left">
                        Public media 
                    </Typography>
                    <Typography variant="body2" align="left">
                        Freely available for your usage 
                    </Typography>
                </Grid>
                <Grid item xs={12} align="center" alignContent="center">
                    <MediaList
                        media={items}
                    />
                </Grid>
            </Grid>
        );
	}
}


const mapStateToProps = state => ({
    media: state.media.mediaCommon
  })
  
  const mapDispatchToProps = dispatch => ({
    loadMedia: () => dispatch(getMediaCommonAPI())
  })

export default withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps
  )(MediaCommon))
