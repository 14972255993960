import * as t from './actionTypes';
import { CALL_API } from '../common/middleware/api'

const requestAccessAPI = () => ({
  [CALL_API]: {
    types: [ t.REQUEST_ACCOUNT_REQUEST, t.REQUEST_ACCOUNT_SUCCESS, t.REQUEST_ACCOUNT_FAILURE ],
    apiName: 'PlayerAPI',
		path: '/players',
    method: 'get',
		myInit: {
			headers: {},
			response: true
		}
  }
})

// Fetches a all players
export const requestAccess = () => (dispatch, getState) => {
  return dispatch(requestAccessAPI())
}

export function openRequestAccount(email) {
 return { type: t.OPEN_REQUEST_ACCOUNT, email }
}

export function closeRequestAccount() {
 return { type: t.CLOSE_REQUEST_ACCOUNT }
}
