import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import EasyvisColorPicker from '../../../../common/EasyvisColorPicker';


const styles = theme => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formControl: {
      margin: theme.spacing.unit,
      minWidth: 220,
    },
});


class TextApp extends Component {

  // the state represents the config of the widget
  state = {
    input: '',
    type: 'scroll',
    horizontalAlignment: 'right',
    verticalAlignment: 'middle',
    speed: 'medium'
  }

  componentDidMount() {
    // report initial state
    this.setStateCallback();
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value }, this.setStateCallback);
  }

  handleColorChange = (color) => {
    this.setState({ color: color }, this.setStateCallback)
  }

  handleBGColorChange = (color) => {
    this.setState({ bgColor: color }, this.setStateCallback)
  }

  setStateCallback = () => {
    this.props.onConfigChange(this.state)
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <form className={classes.root} autoComplete="off">
          <FormControl className={classes.formControl}>
            <TextField
              id="input"
              name="input"
              label="Text"
              value={this.state.input}
              onChange={this.handleChange}
              fullWidth
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="type">Type</InputLabel>
            <Select
              value={this.state.type}
              onChange={this.handleChange}
              inputProps={{
                name: 'type',
                id: 'type',
              }}
            >
              <MenuItem value={"scroll"}>Scroll</MenuItem>
              <MenuItem value={"fixed"}>Fixed</MenuItem>
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="horizontalAlignment">Alignment (horizontal)</InputLabel>
            <Select
              value={this.state.horizontalAlignment}
              onChange={this.handleChange}
              inputProps={{
                name: 'horizontalAlignment',
                id: 'horizontalAlignment',
              }}
            >
              <MenuItem value={"left"}>Left</MenuItem>
              <MenuItem value={"middle"}>Middle</MenuItem>
              <MenuItem value={"right"}>Right</MenuItem>
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="verticalAlignment">Alignment (vertical)</InputLabel>
            <Select
              value={this.state.verticalAlignment}
              onChange={this.handleChange}
              inputProps={{
                name: 'verticalAlignment',
                id: 'verticalAlignment',
              }}
            >
            <MenuItem value={"top"}>Top</MenuItem>
            <MenuItem value={"middle"}>Middle</MenuItem>
            <MenuItem value={"bottom"}>Bottom</MenuItem>
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="speed">Speed</InputLabel>
            <Select
              value={this.state.speed}
              onChange={this.handleChange}
              inputProps={{
                name: 'speed',
                id: 'speed',
              }}
            >
            <MenuItem value={"slow"}>Slow</MenuItem>
            <MenuItem value={"medium"}>Medium</MenuItem>
            <MenuItem value={"fast"}>Fast</MenuItem>
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <EasyvisColorPicker
              value={this.state.color}
              onChange={this.handleColorChange}
              convert='hex'
              id="color"
              name="color"
              label="Color"
              fullWidth
              defaultValue='#FFFFFF'
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <EasyvisColorPicker
              value={this.state.bgColor}
              onChange={this.handleBGColorChange}
              convert='hex'
              id="bgColor"
              name="bgColor"
              label="Background Color"
              fullWidth
              defaultValue='#000000'
            />
          </FormControl>
        </form>
      </div>
    )
  }
}

export default withStyles(styles)(TextApp);
