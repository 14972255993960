import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import EasyvisColorPicker from "../../../../common/EasyvisColorPicker";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 220,
  },
});

class PulseApp extends Component {
  // the state represents the config of the widget
  state = {
    transitionTime: 4.0,
  };

  componentDidMount() {
    // report initial state
    this.setStateCallback();
  }

  handleChange = (event) => {
    this.setState(
      { [event.target.name]: event.target.value },
      this.setStateCallback
    );
  };

  handleColorStartChange = (color) => {
    this.setState({ colorStart: color }, this.setStateCallback);
  };

  handleColorEndChange = (color) => {
    this.setState({ colorEnd: color }, this.setStateCallback);
  };

  setStateCallback = () => {
    this.props.onConfigChange(this.state);
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <form className={classes.root} autoComplete="off">
          <FormControl className={classes.formControl}>
            <TextField
              id="transitionTime"
              name="transitionTime"
              label="Transition time (s) between the two colors"
              value={this.state.transitionTime}
              onChange={this.handleChange}
              fullWidth
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <EasyvisColorPicker
              value={this.state.colorStart}
              onChange={this.handleColorStartChange}
              convert="hex"
              id="colorStart"
              name="colorStart"
              label="Start Color"
              fullWidth
              defaultValue="#FFFFFF"
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <EasyvisColorPicker
              value={this.state.colorEnd}
              onChange={this.handleColorEndChange}
              convert="hex"
              id="colorEnd"
              name="colorEnd"
              label="End Color"
              fullWidth
              defaultValue="#000000"
            />
          </FormControl>
        </form>
      </div>
    );
  }
}

export default withStyles(styles)(PulseApp);
