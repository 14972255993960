import React from 'react';
import { withRouter } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { Auth } from 'aws-amplify';
import {connect} from "react-redux";
import { signInOpen, signUpOpen } from '../actions';
import { openRequestAccount } from '../../../main/actions';


const styles = theme => ({
  root: {
    width: '100%',
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    }
  },
  button: {
    margin: theme.spacing.unit,
  },
});

/*
 * Note that it is important that the component gets the auth props passed along
 */
class ProfileOptions extends React.Component {
  state = {
    anchorEl: null
  };

  handleProfileMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleProfileMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  handleLogoutClick = (event) => {
    Auth.signOut()
      .then(data => console.log(data))
      .catch(err => console.log(err));
    this.props.history.push('/')
    window.location.reload(); // reload necessary to get to the login screen
  }

  handleProfileOpenClick = (event) => {
    this.props.history.push('/console/user')
    this.handleProfileMenuClose();
  }

  handleSignInOpen = () => {
    // Important to set the new auth state first, otherwise the signin won't open
    this.props.onStateChange('signIn');
    this.props.onAuthEvent(this.props.authState, {
            type: 'stateChange',
            data: 'signIn'
          });
    this.props.signInOpen()
  }

  handleSignUpOpen = () => {
    // Important to set the new auth state first, otherwise the signup won't open
    this.props.onStateChange('signUp');
    this.props.onAuthEvent(this.props.authState, {
            type: 'stateChange',
            data: 'signUp'
          });
    this.props.signUpOpen();
  }

  clickRequestAccount = () => {
    this.props.openRequestAccount('');
  }

  render() {
    const { anchorEl } = this.state;
    const { classes, authState } = this.props;
    const isMenuOpen = Boolean(anchorEl);

    return (
      authState === 'signedIn' ?
        <div>
          <IconButton
            aria-owns={isMenuOpen ? 'material-appbar' : undefined}
            aria-haspopup="true"
            onClick={this.handleProfileMenuOpen}
          >
            <AccountCircle />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={this.handleProfileMenuClose}
          >
            <MenuItem onClick={this.handleProfileOpenClick}>Profile</MenuItem>
            <MenuItem onClick={this.handleLogoutClick}>Logout</MenuItem>
          </Menu>
        </div>
        :
        <div>
          <Button className={classes.button} onClick={this.handleSignInOpen}>
          Log In
          </Button>
          {/*<Button color="primary" variant="contained" className={classes.button} onClick={this.handleSignUpOpen}>
          Sign Up
          </Button>*/}
          <Button color="primary" className={classes.button} onClick={this.clickRequestAccount}>
          Request Access
          </Button>
        </div>
    );
  }
}

const mapStateToProps = state => ({
  authState: state.authentication.authState.state,
})

const mapDispatchToProps = dispatch => ({
  signInOpen: () => dispatch(signInOpen()),
  signUpOpen: () => dispatch(signUpOpen()),
  openRequestAccount: (email) => dispatch(openRequestAccount(email))
})

export default withRouter(withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileOptions)));
