import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { Field, reduxForm } from 'redux-form'
import { required, numericality } from 'redux-form-validators'
import { renderField, renderSelectField } from '../../../../../common/Utilities'

const styles = theme => ({
    formTop: {
        marginTop: theme.spacing(4)
      },
});

class PlaylistItemAdd extends Component {

    ALLOWED_VIDEO_FORMATS = [".mp4", ".mov", ".avi", ".flv"];
    ALLOWED_IMAGE_FORMATS = [".jpeg", ".jpg", ".gif", ".png"];

    verifyName = (value) => {
        return value && !/^[0-9a-zA-Z_-]{1,20}$/i.test(value) ? 'Invalid media name' : undefined
    }

    verifyUrl = (value, allValues) => {
        const url = value;
        const type = allValues.type;
        var check = false;
        if (url.startsWith("file://")) {
            check = true;
        } else {
            if (type === "VIDEO") {
                check = this.ALLOWED_VIDEO_FORMATS.map(f => url.includes(f)).includes(true);
            } else if (type === "IMAGE") {
                check = this.ALLOWED_IMAGE_FORMATS.map(f => url.includes(f)).includes(true);
            } else {
                check = true
            }
        }
        return check ? undefined : 'Invalid Url. Check supported formats.'
    }

    submit = values => {
        var duration = null
        if (values.duration !== "") {
            duration = parseInt(values.duration);
        }
        this.props.addMedia(values.name, values.url, values.type, duration);
        this.props.onClickLeave();
    }

	render () {
        const { classes, handleSubmit, submitting, invalid, pristine } = this.props;
		return (
            <div>
                <form onSubmit={handleSubmit(this.submit)}>
                    <Dialog
                        open={this.props.open}
                        onClose={this.props.onClickLeave}
                        aria-labelledby="form-dialog-title"
                        maxWidth="sm"
                    >
                        <DialogTitle id="form-dialog-title">
                            Add media to playlist
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText variant="caption">
                                Select a new media item to be added to your playlist. 
                                You can either use a public url or you reference to a media which you stored on your local device before (compare format below).
                                <br />
                                Supported media formats are: {this.ALLOWED_IMAGE_FORMATS.join(",")} and {this.ALLOWED_VIDEO_FORMATS.join(", ")}
                                <br /> <br />
                                Public URLs: <br />
                                https://live.staticflickr.com/7364/27653319031_d64f8beeb8_c_d.jpg <br />
                                https://dl.dropboxusercontent.com/s/1gxdgxvj49zajft/admins%20blog.png <br />
                                <br />
                                Local Device URLs: <br />
                                file://yourLocalMediaName
                            </DialogContentText>
                            <FormControl fullWidth className={classes.formTop}>
                                <Field
                                    name="type"
                                    component={renderSelectField}
                                    label="Type"
                                    className={classes.selectField}
                                >
                                    <MenuItem key="VIDEO" value="VIDEO">Video</MenuItem>
                                    <MenuItem key="IMAGE" value="IMAGE">Image</MenuItem>
                                </Field>
                            </FormControl>
                            <FormControl fullWidth className={classes.formTop}>
                                <Field className={classes.textField} name="name" component={renderField}
                                    label= "Name of Playlist Item" type="text" validate={[required(), this.verifyName]} />
                            </FormControl>
                            <FormControl fullWidth className={classes.formTop}>
                                <Field className={classes.textField} name="duration" component={renderField}
                                    label= "Duration in seconds (optional)" type="text" validate={[numericality({ '>': 0 , allowBlank: true})]} />
                            </FormControl>
                            <FormControl fullWidth className={classes.formTop}>
                                <Field className={classes.textField} name="url" component={renderField}
                                    label= "Url" type="text" validate={[required(), this.verifyUrl]} />
                            </FormControl>
                        </DialogContent>
                        <DialogActions>
                            <Button 
                                color="primary" 
                                onClick={this.props.onClickLeave}
                            >
                                Cancel
                            </Button>
                            <Button 
                                variant="contained" 
                                color="primary" 
                                disabled={invalid|| submitting || pristine}
                                onClick={handleSubmit(this.submit)}
                                type="submit"
                                autoFocus>
                                Add
                            </Button>
                        </DialogActions>
                    </Dialog>
                </form>
            </div>
        );
	}
}

const mapStateToProps = state => ({
})
  
const mapDispatchToProps = dispatch => ({
})

PlaylistItemAdd = reduxForm({
    form: 'playlistItemAdd'
  })(PlaylistItemAdd)

export default withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps
  )(PlaylistItemAdd))
