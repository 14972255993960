import React from "react";
import { withStyles } from "@material-ui/core/styles";
import LayoutBody from "./MainLayoutBody";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";

const styles = (theme) => ({
  root: {
    display: "flex",
    overflow: "hidden",
    backgroundColor: theme.main.backgroundDark,
    backgroundImage:
      "linear-gradient(to top, rgba(14,15,31,1), rgba(16,18,36,1))",
  },
  layoutBodyLeft: {
    alignItems: "left",
    marginTop: theme.spacing.unit * 20,
    marginBottom: theme.spacing.unit * 20,
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing.unit * 8,
      marginBottom: theme.spacing.unit * 8,
    },
  },
  grid: {
    flexGrow: 1,
  },
  title: {
    marginBottom: theme.spacing.unit * 2,
  },
  subtitle: {
    marginBottom: theme.spacing.unit * 4,
  },
  subtext: {
    marginBottom: theme.spacing.unit * 2,
  },
  link: {
    color: theme.palette.text.primary,
    width: "100%",
  },
  linkContainer: {
    marginTop: theme.spacing.unit * 6,
  },
  preTitle: {
    color: theme.palette.primary.main,
  },
  mobileContainerFix: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: 24,
    },
  },
  card: {
    backgroundColor: theme.main.backgroundLight,
  },
  bodySpacing: {
    paddingTop: "4px !important",
  },
});

class MainPricing extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <section className={classes.root}>
        <LayoutBody className={classes.layoutBodyLeft}>
          <Grid container spacing={3} alignContent="center" alignItems="center">
            <Grid item xs={12}>
              <Typography
                variant="subtitle2"
                align="left"
                className={classes.preTitle}
              >
                Start for free
              </Typography>
              <Typography variant="h2" align="left" className={classes.title}>
                Start building
              </Typography>
              <Typography
                variant="body1"
                align="left"
                className={classes.subtitle}
              >
                We believe that our basic service should be free for everyone.
                You can reward us in sharing your creative solutions with us. If
                you or your business want to scale your solution, you just pay
                for the physical visualization devices you actually use. We want
                to keep things simple and transparent for you and us.
              </Typography>
            </Grid>
            <Grid item xs={12} align="center">
              <div>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={6}>
                    <Card className={classes.card}>
                      <CardHeader
                        title="Developer"
                        subheader="Up to 2 devices"
                        titleTypographyProps={{ align: "center" }}
                        subheaderTypographyProps={{ align: "center" }}
                        className={classes.cardHeader}
                      />
                      <CardContent>
                        <div className={classes.cardPricing}>
                          <Typography
                            component="h3"
                            variant="h3"
                            color="textPrimary"
                          >
                            FREE
                          </Typography>
                          <Typography variant="h6" color="textSecondary">
                            {" "}
                            &nbsp;
                          </Typography>
                        </div>
                        <Typography variant="subtitle1" align="center">
                          Control TVs and Tablets
                        </Typography>
                        <Typography variant="subtitle1" align="center">
                          Play videos, images and websites
                        </Typography>
                        <Typography variant="subtitle1" align="center">
                          Restricted number of playbacks pre day
                        </Typography>
                      </CardContent>
                      <CardActions className={classes.cardActions}>
                        <Button
                          href="#request_access"
                          fullWidth
                          variant="contained"
                          color="primary"
                        >
                          REQUEST ACCESS
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Card className={classes.card}>
                      <CardHeader
                        title="Business"
                        subheader="2+ devices"
                        titleTypographyProps={{ align: "center" }}
                        subheaderTypographyProps={{ align: "center" }}
                        className={classes.cardHeader}
                      />
                      <CardContent>
                        <div className={classes.cardPricing}>
                          <Typography
                            component="h3"
                            variant="h3"
                            color="textPrimary"
                          >
                            Request pricing
                          </Typography>
                          <Typography variant="h6" color="textSecondary">
                            {" "}
                            &nbsp;
                          </Typography>
                        </div>
                        <Typography variant="subtitle1" align="center">
                          Control TVs, tablets and lights
                        </Typography>
                        <Typography variant="subtitle1" align="center">
                          Play all digital content and animations
                        </Typography>
                        <Typography variant="subtitle1" align="center">
                          No restrictions
                        </Typography>
                      </CardContent>
                      <CardActions className={classes.cardActions}>
                        <Button
                          href="#request_access"
                          fullWidth
                          variant="contained"
                          color="primary"
                        >
                          Contact us
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </LayoutBody>
      </section>
    );
  }
}

export default withStyles(styles)(MainPricing);
