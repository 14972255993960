import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from "@material-ui/core/Grid";
import PlayIcon from '../../../../../common/icons/PlayIcon';
import PlaylistEdit from './PlaylistEdit';
import Button from '@material-ui/core/Button';
import AddBoxIcon from '@material-ui/icons/AddBox';


const styles = theme => ({
    listItem: {
        display: 'flex',
        '& > *': {
          margin: theme.spacing(2),
        }
      },
    list: {
        backgroundColor: theme.palette.background.paper,
      },
    paper: {
        padding: theme.spacing(2)
    }
});

class PlaylistList extends Component {

    constructor() {
        super();
        this.state = {
            editMode: false,
            editItem: null,
            editNewItem: false
        };
    }

    handleDeleteClick = (item) => {
        this.setState({
            confirmOpen: true,
            mediaToDelete: item
        })
    }

    deleteMedia = () => {
        this.props.deleteCallback(this.state.mediaToDelete)
        this.setState({mediaToDelete: null})
    }

    handlePlayClick = (item) => {
        this.props.playCallback(item.items)
    }

    openEditPlaylist = (item) => {
        this.setState({
            editMode: true,
            editItem: item,
            editNewItem: false
        })
    }

    handleLeaveEditMode = () => {
        this.setState({
            editMode: false,
            editItem: null
        })
    }

    openAddNewPlaylist = () => {
        const newPlaylistItem = {
            name: "",
            items: []
        }
        this.setState({
            editMode: true,
            editItem: newPlaylistItem,
            editNewItem: true
        })
    }

    createPlaylistItem(item, playEnabledInput) {
        const { classes } = this.props;
        const name = item.name;
        const id = item.id;
        var playEnabled = false;
        if (typeof playEnabledInput !== 'undefined') {
            playEnabled = true;
        }
        return(
            <ListItem key={id} button className={classes.listItem} onClick={() => {this.openEditPlaylist(item)}}> 
                <ListItemText id={id} primary={name} />
                <ListItemSecondaryAction>
                {playEnabled ? 
                    <PlayIcon size="small" onClick={() => {this.handlePlayClick(item)}}/>
                : null }
                </ListItemSecondaryAction>
            </ListItem>
        );
    }
    
	render () {        
        const { classes } = this.props;
        var items = this.props.playlists;
        const playEnabled = this.props.playEnabled;
        const editMode = this.state.editMode;
        const editItem = this.state.editItem;
        const editNewItem = this.state.editNewItem;
		return (
            <div>
                    {editMode ? 
                        <PlaylistEdit 
                            initialValues={editItem}
                            leaveEditMode={this.handleLeaveEditMode}
                            editNewItem={editNewItem}
                        /> 
                    : 
                        <Grid
                            container
                            spacing={1}
                            alignContent="center"
                            alignItems="center"
                        >
                            <Grid item xs={12} align="right">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<AddBoxIcon />}
                                    onClick={this.openAddNewPlaylist}
                                >
                                    Add Playlist
                                </Button>
                            </Grid>
                            <Grid item xs={12} align="center">
                                <List dense className={classes.list}>
                                    { items.map(item => this.createPlaylistItem(item, playEnabled)) }
                                </List>
                            </Grid>
                        </Grid>
                    }
            </div>
    );
	}
}

export default withStyles(styles)((PlaylistList))
