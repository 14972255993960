import React from "react";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Apps from "./player/components/Apps.js";
import DeviceList from "./device/components/DeviceList.js";
import DeviceConfiguration from "./device/components/DeviceConfiguration.js";
import { Route, Switch, Redirect } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InfoIcon from "@material-ui/icons/Info";
import ProfileOptions from "../common/auth/components/ProfileOptions";
import { withRouter } from "react-router-dom";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import SettingsIcon from "@material-ui/icons/Settings";
import ListIcon from "@material-ui/icons/ViewList";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import { theme } from "../AppTheme.js";
import { MuiThemeProvider } from "@material-ui/core/styles";
import Media from "react-media";
import { connect } from "react-redux";
import { enterConsole, leaveConsole } from "../common/auth/actions.js";
import Loading from "../common/Loading.js";
import UserInformation from "./user/components/UserInformation";
import MediaOverview from "./media/components/Media.js";
import DeviceRegistration from "./device/components/DeviceRegistration.js";

const drawerWidth = 240;

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing.unit * 9,
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    height: "100vh",
    overflow: "auto",
  },
  chartContainer: {
    marginLeft: -22,
  },
  tableContainer: {
    height: 320,
  },
  h5: {
    marginBottom: theme.spacing.unit * 2,
  },
  appBarMobile: {
    top: "auto",
    bottom: 0,
  },
  contentMobile: {
    flexGrow: 1,
    padding: theme.spacing.unit,
    height: "100%",
    paddingBottom: 50,
  },
});

class Console extends React.Component {
  state = {
    open: true,
  };

  componentDidMount() {
    this.props.enterConsole();
  }

  componentWillUnmount() {
    this.props.leaveConsole();
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  handleAppsClick = (event) => {
    this.props.history.push("/console/app");
  };

  handleMediaClick = (event) => {
    this.props.history.push("/console/media");
  };

  handleDeviceManagementClick = (event) => {
    this.props.history.push("/console/device");
  };

  handleAPIReferenceClick = (event) => {
    window.open("http://developer.easyvis.io/");
  };

  handleDeviceAddClick = () => {
    this.props.history.push("/console/device/register");
  };

  render() {
    if (this.props.authState !== "signedIn") {
      return null;
    } else {
      return (
        <Media query="(max-width: 599px)">
          {(matches) => (matches ? this.renderMobile() : this.renderDesktop())}
        </Media>
      );
    }
  }

  renderMobile = () => {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <MuiThemeProvider theme={theme}>
          <main className={classes.contentMobile}>{this.renderMain()}</main>
          <AppBar
            position="fixed"
            color="primary"
            className={classes.appBarMobile}
          >
            <BottomNavigation showLabels>
              <BottomNavigationAction
                label="Apps"
                icon={<PlayCircleFilledIcon />}
                onClick={this.handleAppsClick}
              />
              <BottomNavigationAction
                label="Media"
                icon={<ListIcon />}
                onClick={this.handleMediaClick}
              />
              <BottomNavigationAction
                label="Devices"
                icon={<SettingsIcon />}
                onClick={this.handleDeviceManagementClick}
              />
              <BottomNavigationAction
                label="New Device"
                icon={<AddCircleIcon />}
                onClick={this.handleDeviceAddClick}
              />
            </BottomNavigation>
          </AppBar>
        </MuiThemeProvider>
      </div>
    );
  };

  renderDesktop = () => {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <AppBar
            position="absolute"
            className={classNames(
              classes.appBar,
              this.state.open && classes.appBarShift
            )}
          >
            <Toolbar
              disableGutters={!this.state.open}
              className={classes.toolbar}
            >
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={this.handleDrawerOpen}
                className={classNames(
                  classes.menuButton,
                  this.state.open && classes.menuButtonHidden
                )}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                className={classes.title}
              >
                easyvis Console
              </Typography>
              <ProfileOptions {...this.props} />
            </Toolbar>
          </AppBar>
          <Drawer
            variant="permanent"
            classes={{
              paper: classNames(
                classes.drawerPaper,
                !this.state.open && classes.drawerPaperClose
              ),
            }}
            open={this.state.open}
          >
            <div className={classes.toolbarIcon}>
              <IconButton onClick={this.handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <Divider />
            <div>
              <ListItem button onClick={this.handleAppsClick}>
                <ListItemIcon>
                  <PlayCircleFilledIcon />
                </ListItemIcon>
                <ListItemText primary="Apps" />
              </ListItem>
              <ListItem button onClick={this.handleMediaClick}>
                <ListItemIcon>
                  <ListIcon />
                </ListItemIcon>
                <ListItemText primary="Media" />
              </ListItem>
              <ListItem button onClick={this.handleDeviceManagementClick}>
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Manage Devices" />
              </ListItem>
              <ListItem button onClick={this.handleDeviceAddClick}>
                <ListItemIcon>
                  <AddCircleIcon />
                </ListItemIcon>
                <ListItemText primary="Add New Device" />
              </ListItem>
            </div>
            <Divider />
            <List>
              <div>
                <ListItem button onClick={this.handleAPIReferenceClick}>
                  <ListItemIcon>
                    <InfoIcon />
                  </ListItemIcon>
                  <ListItemText primary="Help" />
                </ListItem>
              </div>
            </List>
          </Drawer>
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            {this.renderMain()}
          </main>
        </MuiThemeProvider>
      </div>
    );
  };

  renderMain = () => {
    return (
      <div>
        <Loading
          visible={
            this.props.playersLoading ||
            this.props.devicesLoading ||
            this.props.userLoading ||
            this.props.mediaLoading
          }
        />
        <Switch>
          <Route
            exact
            strict
            path="/console/device/register"
            render={(props) => <DeviceRegistration {...this.props} />}
          />
          <Route
            exact
            path="/console/device/:id"
            component={DeviceConfiguration}
          />
          <Route
            exact
            path="/console/device"
            render={(props) => <DeviceList {...this.props} />}
          />
        </Switch>
        <Route
          exact
          path="/console/user"
          render={(props) => <UserInformation {...this.props} />}
        />
        <Route
          exact
          strict
          path="/console"
          render={({ location }) => {
            if (location.pathname === window.location.pathname) {
              return <Redirect to="/console/app" />;
            }
            return null;
          }}
        />
        <Route
          path="/console/app"
          render={(props) => <Apps {...this.props} />}
        />
        <Route
          path="/console/media"
          render={(props) => <MediaOverview {...this.props} />}
        />
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  playersLoading: state.players.loading,
  devicesLoading: state.devices.loading,
  userLoading: state.user.loading,
  mediaLoading: state.media.loading,
});

const mapDispatchToProps = (dispatch) => ({
  enterConsole: () => dispatch(enterConsole()),
  leaveConsole: () => dispatch(leaveConsole()),
});

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Console))
);
