import React from "react";
import { SignIn } from "aws-amplify-react";
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import ErrorSnack from '../../notifications/components/ErrorSnack';
import {connect} from "react-redux";
import { signInClose, signUpOpen } from '../actions'


const styles = theme => ({
  formControl: {
    margin: theme.spacing.unit,
  },
  button: {
    margin: theme.spacing.unit,
    align: 'center'
  },
});

class SignInCavi extends SignIn {

  state = {
    openError: false,
    error: ''
  }

  constructor(props) {
    super(props);
    this._validAuthStates = ["signIn", "signedOut", "signedUp"];
  }

  handleClickCloseError = () => {
    this.setState({openError: false});
  }

  handleClickSignUp = () => {
    super.changeState("signUp")
    this.setState({openError: false});
    this.props.signInClose();
    this.props.signUpOpen();
  }

  handleClickSignInClose = () => {
    this.setState({openError: false});
    this.props.signInClose();
  }

  error(err) {
    this.setState({openError: true, error: err.message});
  }

  onFormSubmit = e => {
    e.preventDefault();
    this.signIn();
  }

  showComponent(theme) {
    const { classes, signInOpen, authState } = this.props;
    const { openError, error } = this.state;
    const open = signInOpen || (authState === 'signedUp') || (this.props.console && authState === 'signIn')
    return (
      <Dialog
          open={open}
          onClose={this.handleClickSignInClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Sign in</DialogTitle>
          <DialogContent>
            <form onSubmit={this.onFormSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormControl className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="username">Email</InputLabel>
                    <Input id="username" key="username" name="username" onChange={this.handleInputChange} />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="password">Password</InputLabel>
                    <Input id="password" key="password" name="password" type="password" onChange={this.handleInputChange} />
                  </FormControl>
                </Grid>
                { openError ?
                  <Grid item xs={12}>
                    <ErrorSnack open={openError} message={error} handleClose={this.handleClickCloseError} />
                  </Grid>
                : null }
                <Grid item xs={12}>
                  <Button variant="contained" color="primary" className={classes.button} type="submit">
                    Sign in
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                  Forgot your password?{" "} <Link onClick={() => super.changeState("forgotPassword")}>Reset Password</Link>
                  </Typography>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  signInOpen: state.authentication.signInOpen,
  console: state.authentication.console
})

const mapDispatchToProps = dispatch => ({
  signInClose: () => dispatch(signInClose()),
  signUpOpen: () => dispatch(signUpOpen())
})

export default withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps
)(SignInCavi));
