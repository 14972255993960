import {
  SIGN_IN_OPEN,
  SIGN_IN_CLOSE,
  SIGN_UP_OPEN,
  SIGN_UP_CLOSE,
  AUTH_STATE_CHANGE,
  ENTER_CONSOLE,
  LEAVE_CONSOLE
} from './actionTypes';

const initialStateAuth = {
  signInOpen: false, // signIn open mode
  signUpOpen: false, // signIn open mode
  console: false,  // defines if user has console opened
  authState: ''
}

export default (state = initialStateAuth, action) => {
  switch (action.type) {
    case SIGN_IN_OPEN:
      return Object.assign({}, state, {signInOpen: true})
    case SIGN_IN_CLOSE:
      return Object.assign({}, state, {signInOpen: false})
    case SIGN_UP_OPEN:
      return Object.assign({}, state, {signUpOpen: true})
    case SIGN_UP_CLOSE:
      return Object.assign({}, state, {signUpOpen: false})
    case ENTER_CONSOLE:
      return Object.assign({}, state, {console: true})
    case LEAVE_CONSOLE:
      return Object.assign({}, state, {console: false})
    case AUTH_STATE_CHANGE:
      return Object.assign({}, state, {authState: action})
      default:
        return state
    }
}
