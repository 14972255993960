import React, { Component } from "react";
import { connect } from "react-redux";
import { loadPlayers, setPlayerConfig } from "../actions";
import { withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";

const styles = (theme) => ({
  formControl: {
    margin: theme.spacing.unit,
    marginRight: theme.spacing.unit * 3,
    minWidth: 300,
  },
});

class PlayerSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      player: this.props.selectedPlayer,
      loopPlayback: false,
    };
  }

  handlePlayerChange = (e) => {
    const player = e.target.value;
    this.props.onPlayerChange(player);
    this.setState({ player: player });
  };

  handleLoopPlayback = (e) => {
    const loopPlayback = e.target.checked;
    var numberOfLoops = 0;
    if (loopPlayback) {
      numberOfLoops = -1;
    }
    this.props.setPlayerConfig({
      loop: numberOfLoops,
    });
    this.setState({ loopPlayback: loopPlayback });
  };

  componentDidMount() {
    this.props.reloadPlayers();
  }

  render() {
    const { classes, noLoop } = this.props;
    const playerListItems = this.props.players.map((player) => (
      <MenuItem key={player.playerName} value={player.playerName}>
        {player.playerName}
      </MenuItem>
    ));
    return (
      <form autoComplete="off">
        <FormGroup row>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="age-simple">Select player</InputLabel>
            <Select
              value={this.state.player}
              onChange={this.handlePlayerChange}
              inputProps={{
                name: "player-select",
                id: "player-select",
              }}
            >
              {playerListItems}
            </Select>
          </FormControl>
          {!noLoop ? (
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.loopPlayback}
                  onChange={this.handleLoopPlayback}
                  value="loopPlayback"
                />
              }
              label="Loop playback"
            />
          ) : null}
        </FormGroup>
      </form>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedPlayer: state.players.selectedPlayer,
  players: state.players.items,
});

const mapDispatchToProps = (dispatch) => ({
  reloadPlayers: () => dispatch(loadPlayers()),
  setPlayerConfig: (playerConfig) => dispatch(setPlayerConfig(playerConfig)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(PlayerSelector)
);
